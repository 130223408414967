import { type NextRouter, useRouter } from "next/router";
import { useCallback } from "react";

import {
	type UseNavigateToCanvasProps,
	VALID_QUERY_PARAMS,
	canvasUrlPath,
} from "~/utils/helpers/url";

type TransitionOptions = Parameters<NextRouter["push"]>["2"];

export type UseCanvasTransitionType = () => (
	p: UseNavigateToCanvasProps,
	options?: TransitionOptions
) => void;

type TransitionCanvasProps = {
	props: UseNavigateToCanvasProps;
	options?: TransitionOptions;
	behavior: "push" | "replace";
	router: NextRouter;
};

const transitionCanvas = ({ props, options, behavior, router }: TransitionCanvasProps) => {
	const navigateFunc = router[behavior];
	const queryParams = {};

	if (props.version) {
		queryParams[VALID_QUERY_PARAMS.CANVAS.VERSION] = props.version;
	}

	if (props.draftAuthorId) {
		queryParams[VALID_QUERY_PARAMS.CANVAS.DRAFT_AUTHOR_ID] = props.draftAuthorId;
	}

	void navigateFunc(
		{
			pathname: canvasUrlPath(props),
			query: queryParams,
		},
		undefined,
		options
	);
};

/**
 * useGetRouteType
 * hook to identify the Canvas Route Type
 */
export const useGetRouteType = () => {
	const router = useRouter();

	if (router.query["canvasParams"]) {
		return "CanvasById";
	}
	if (router.query["namespace"] && router.query["sharedNameParams"]) {
		return "CanvasBySharedName";
	}
};

/**
 * useNavigateToCanvas
 * hook to create a function that navigates to a canvas url
 */
export const useNavigateToCanvas: UseCanvasTransitionType = () => {
	const router = useRouter();

	const navigateToCanvas = useCallback(
		(props: UseNavigateToCanvasProps, options?: TransitionOptions) => {
			transitionCanvas({
				props,
				options,
				behavior: "push",
				router,
			});
		},
		[router]
	);

	return navigateToCanvas;
};

/**
 * useReplaceCanvasPath
 * hook to create a function that navigates to a canvas url
 */
export const useReplaceCanvasRoute: UseCanvasTransitionType = () => {
	const router = useRouter();

	const replaceCanvasRoute = useCallback(
		(props: UseNavigateToCanvasProps, options?: TransitionOptions) => {
			transitionCanvas({
				props,
				options,
				behavior: "replace",
				router,
			});
		},
		[router]
	);

	return replaceCanvasRoute;
};

/**
 * useNavigateToHome
 */
export const useNavigateToHome = () => {
	const router = useRouter();
	const navigateToHome = useCallback(() => {
		void router.push("/");
	}, [router]);

	return navigateToHome;
};

/**
 * useNavigateToCanvases
 */
export const useNavigateToCanvases = () => {
	const router = useRouter();
	const navigateToCanvases = useCallback(() => {
		void router.push("/docs");
	}, [router]);

	return navigateToCanvases;
};

/**
 * useNavigateToArchive
 */
export const useNavigateToArchive = () => {
	const router = useRouter();
	const navigateToArchive = useCallback(() => {
		void router.push("/archive");
	}, [router]);

	return navigateToArchive;
};

/**
 * useNavigateToSettings
 */
export const useNavigateToSettings = () => {
	const router = useRouter();
	const navigateToSettings = useCallback(() => {
		void router.push("/settings");
	}, [router]);

	return navigateToSettings;
};
