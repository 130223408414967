import { isEmpty } from "lodash";
import { useRouter } from "next/router";
import { useMemo } from "react";

import { useDocumentsListQuery } from "../documents";

export const useRouteParams = () => {
	const router = useRouter();

	const { data: documents } = useDocumentsListQuery();

	return useMemo(() => {
		const workspaceName = router.query["workspaceName"];
		const documentId = router.query["documentId"];
		const pageSlug = router.query["pageSlug"];
		const path = router.asPath;

		return {
			documentPath: documents?.find((document) => document.id === documentId)?.path ?? "",
			workspaceName: isEmpty(workspaceName) ? "" : `${workspaceName}`,
			documentId: isEmpty(documentId) ? "" : `${documentId}`,
			pageSlug: isEmpty(pageSlug) ? "" : `${pageSlug}`,
			path,
		};
	}, [router.asPath, router.query, documents]);
};
