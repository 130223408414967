import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useRouter } from "next/router";

import type { DocumentMetadata } from "@moment/api-collab/api-types";

import { useLogger } from "~/components/canvas/hooks/useLogger";

import { useUpdateDocumentMutation } from "../documents";
import { gitQueryKeys } from "../git";

export const useReorderPagesMutation = ({
	workspaceName,
	documentId,
	currentPageId,
}: {
	workspaceName: string;
	documentId: string;
	currentPageId: string;
}) => {
	const logger = useLogger("useReorderPagesMutation");

	const router = useRouter();

	const queryClient = useQueryClient();
	const { updateAsync: updateDocument } = useUpdateDocumentMutation();

	const mutation = useMutation({
		mutationFn: async (args: {
			workspaceName: string;
			documentId: string;
			reorderInfo: { pageId: string; parentPageID: string | null }[];
		}) => {
			const { workspaceName, documentId, reorderInfo } = args;

			return updateDocument(
				{
					workspaceName,
					documentId,
				},
				(document) => {
					if (!document) {
						logger.error("Document not found", args);
						return document;
					}

					const sortedPages = reorderInfo.reduce<DocumentMetadata["pagesMetadata"]>(
						(acc, { pageId, parentPageID }, index) => {
							const page = document.pagesMetadata.find((page) => page.id === pageId);
							if (page) {
								const newPage = { ...page, parentPageID };
								if (index === 0) {
									newPage.slug = "index";
								} else if (page.slug === "index") {
									newPage.slug = page.id;
								}
								acc.push(newPage);
							}
							return acc;
						},
						[]
					);

					document.pagesMetadata = sortedPages;

					return document;
				}
			);
		},
		onSuccess: (result) => {
			const currentPage = result?.pagesMetadata.find((page) => page.id === currentPageId);
			if (currentPage) {
				const pageSlug = currentPage.slug === "index" ? "" : `/${currentPage.slug}`;
				void router.replace(`/d/${workspaceName}/${documentId}${pageSlug}`);
			}

			void queryClient.invalidateQueries({
				queryKey: gitQueryKeys.repo.base(documentId),
			});
		},
	});

	return mutation;
};
