import { z } from "zod";

import { pageMetadata } from "./pageMetadata";

const publishSettings = z.object({
	template: z.union([z.literal("blog"), z.literal("tech-docs-vitepress")]).optional(),
	logo: z
		.object({
			dark: z.string().optional(),
			light: z.string().optional(),
		})
		.optional(),
	favicon: z.string().optional(),
	urlPrefix: z.string().optional(),
	topbarLinks: z
		.array(
			z
				.object({
					name: z.string(),
					url: z.string(),
				})
				.nullish()
		)
		.optional(),
	topbarCtaButton: z
		.object({
			name: z.string(),
			url: z.string(),
		})
		.optional(),
	footerSocials: z
		.object({
			x: z.string().optional(),
			bluesky: z.string().optional(),
			github: z.string().optional(),
			linkedin: z.string().optional(),
		})
		.optional(),
});

export type PublishSettings = z.infer<typeof publishSettings>;

export const makeUrlPrefix = (
	workspaceName: string,
	documentId: string,
	publishSettings: PublishSettings | undefined
) => {
	const defaultUrlPrefix = `/d/${workspaceName}/${documentId}`;
	if (process.env["APP_ENV"] !== "production") {
		return defaultUrlPrefix;
	}

	const urlPrefix = publishSettings?.urlPrefix;
	if (!urlPrefix) {
		return defaultUrlPrefix;
	}

	return urlPrefix;
};

/**
 * A direct mirror of the GraphQL `CanvasMetadata` type
 */
export const DocumentMetadataSchema = z.object({
	id: z.string(),
	schemaVersion: z.number().default(0),

	title: z.string(),
	prettyName: z.string().optional(),
	created: z.coerce.date().default(new Date()),

	pagesMetadata: z.array(pageMetadata),

	publishSettings: publishSettings.optional(),
});

export const messageIdempotentInitMetadataRoomResponseAccepted = z.object({
	type: z.literal("idempotent-init-metadata-room-response-accepted"),
	version: z.number(),
	canvasMetadata: DocumentMetadataSchema,
	page: z.unknown(),
});

export type MessageInitMetadataRoomResponseAccepted = z.infer<
	typeof messageIdempotentInitMetadataRoomResponseAccepted
>;

export const messageIdempotentInitMetadataRoomResponseRejected = z.object({
	type: z.literal("idempotent-init-metadata-room-response-rejected"),
	version: z.number(),
});

export type MessageInitMetadataRoomResponseRejected = z.infer<
	typeof messageIdempotentInitMetadataRoomResponseRejected
>;

/**
 * A direct mirror of the GraphQL `CanvasMetadata` type.
 */
export type DocumentMetadata = z.infer<typeof DocumentMetadataSchema>;

export type DocumentPageMetadata = DocumentMetadata["pagesMetadata"][number];
