import { isEmpty } from "lodash";

import { Logger } from "~/utils/logging";

const logger = new Logger("docsapi");

export const getDocsApiUrl = (): string => {
	// set NEXT_PUBLIC_GITEA_API_URL env to  "localhost:8088" if you want to use local backend.
	const giteaApiUrl = process.env["NEXT_PUBLIC_GITEA_API_URL"];

	if (typeof giteaApiUrl === "string" && !isEmpty(giteaApiUrl)) {
		if (giteaApiUrl.startsWith("http")) {
			return giteaApiUrl;
		}
		return `https://${giteaApiUrl}`;
	}

	if (process.env.APP_ENV === "production") {
		return "https://git.moment.dev";
	}

	return "https://git-staging.moment.dev";
};

export const getDocsApiUrlWithoutProtocol = (): string => {
	const apiUrl = getDocsApiUrl();
	return apiUrl.replace(/^https?:\/\//, "");
};

export const getDocsWebsite = (): string => {
	const apiUrl = getDocsApiUrl();
	return apiUrl.replace(/^https:\/\/git/, "https://docs");
};

// this is used to fetch from docsapi endpoints, with prefix /internal/v1
export const createDocsApiFetch = (token: string, autothrow: boolean = true) => {
	return (path: string, init: RequestInit) => {
		const url = `${getDocsApiUrl()}/internal/v1${path}`;
		const fetch = docsApiFetch(token, autothrow);
		return fetch(url, init).then((response) => {
			return response.json();
		});
	};
};

export function docsApiFetch(token: string, autothrow: boolean = true) {
	return async (
		input: string | URL | globalThis.Request,
		init?: RequestInit
	): Promise<Response> => {
		const headers = {
			...init?.headers,
		};

		if (!isEmpty(token)) {
			headers["Authorization"] = `Bearer ${token}`;
			headers["Content-Type"] = "application/json";
		}

		const response = await fetch(input, {
			...init,
			headers,
		});

		if (!response.ok && autothrow) {
			logger.error(`Failed to fetch ${input}`, { response });
			throw new Error(response.statusText, { cause: response });
		}

		return response;
	};
}
