import type { LogoutOptions } from "@auth0/auth0-react";
import { invoke } from "@tauri-apps/api/core";
import { type NextRouter } from "next/router";

import { getAuthConfigs } from "./authconfig";
import { getAuthURLs } from "./interop/common";
import { attemptSaveRedirect, deleteRedirectEntry, getRedirectEntry } from "./redirectStore";

// We want to use the APP_ENV here as moment://-/ does not work unless you build the electron app
const { logoutRedirectUri } = getAuthURLs(process.env.APP_ENV);
const { auth0ClientId } = getAuthConfigs(process.env.AUTH_ENV);

// Logout will stash the redirectId as the query parameter "redirectId".
// logoutParams.returnTo must be a consistent, allowlisted URL, and
// we pass the intended redirect target via the redirectEntry.
const getPopupAction = (logout) => async (redirectPath?: string) => {
	const redirectUrl = `${logoutRedirectUri}`;
	await logout({
		clientId: auth0ClientId,
		logoutParams: {
			returnTo: redirectUrl,
		},
		async openUrl(url) {
			console.log(`navigating to logout in popup browser window, url=${url}`);
			try {
				// open the logout window
				await invoke("run_logout_window", { logout: url, redirectTo: redirectPath });
			} catch (error) {
				// redirect to postLogout if anything goes wrong for error reporting
				window.location.replace("/docs?postlogout=true");
				throw error;
			}
		},
	});
};

// Logout will stash the redirectId as the query parameter "redirectId".
// logoutParams.returnTo must be a consistent, allowlisted URL, and
// we pass the intended redirect target via the redirectEntry.
const getRedirectAction = (logout) => async (redirectPath?: string) => {
	console.log(`navigating to auth0 login in current browser tab`);
	const redirectEntry = attemptSaveRedirect({ redirectPath });

	// this is the URL that Auth0 will redirect to, from there we use the
	// redirectId to further redirect within the app.
	const redirectUrl = `${logoutRedirectUri}${redirectEntry ? `?redirectId=${redirectEntry.id}` : ""}`;
	await logout({
		clientId: auth0ClientId,
		logoutParams: {
			returnTo: redirectUrl,
		},
	});
};

/**
 * Logout the user
 */
export const getLogoutAction = (
	logout: (options?: LogoutOptions) => Promise<void>,
	opts: {
		isLocalApp: boolean;
	}
) => {
	const { isLocalApp } = opts;
	const performPopupLogout = getPopupAction(logout);
	const performRedirectLogout = getRedirectAction(logout);

	/* When the user logs out, remove all data cached in local storage */
	Object.keys(localStorage).forEach((key) => {
		if (key.startsWith("moment_rq:")) {
			localStorage.removeItem(key);
		}
	});

	return async (redirectPath: string) => {
		redirectPath = redirectPath ?? "/docs";
		if (isLocalApp) {
			await performPopupLogout(redirectPath);
		} else {
			await performRedirectLogout(redirectPath);
		}
	};
};

// Exported logout callback. As describe above, the onLogoutCallback
// will retrieve the redirectId from the query parameters, which can
// load the redirectEntry.
export const onLogoutCallback = (router: NextRouter) => {
	const returnId = router.query["redirectId"];
	if (returnId && typeof returnId === "string") {
		const redirectPath = getRedirectEntry(returnId)?.redirectPath;
		if (redirectPath) {
			void router.replace(redirectPath);
			deleteRedirectEntry(returnId);
			return;
		}
	}

	void router.replace("/docs");
};
