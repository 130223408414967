import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useUpdateDocumentMutation } from "../documents";
import { gitQueryKeys } from "../git";

export const useRenamePageMutation = () => {
	const queryClient = useQueryClient();
	const { updateAsync: updateDocument } = useUpdateDocumentMutation();

	const mutation = useMutation({
		mutationFn: async (args: {
			workspaceName: string;
			documentId: string;
			pageId: string;
			title: string;
		}) => {
			//
			// TODO: Targeted fix to a race condition, we will find a better (less slow) way of
			// solving it soon.
			//
			// The race generally occurs when you create a new document and then immediately reanme
			// the first page. Often the workspace or the document query will not have been updated
			// yet, and it will throw one of the errors below (usually one of the "Document not
			// found" errors). The VERY slow but probably safe way to fix this is to just refetch
			// the documentand workspace queries so the data is always up to date.
			//

			const { workspaceName, documentId, pageId, title } = args;
			let documentNeedsRenamed = false;

			await updateDocument({ workspaceName, documentId }, (document) => {
				if (!document) {
					throw new Error("Document not found");
				}

				const page = document.pagesMetadata.find((page) => page.id === pageId);
				if (!page) {
					throw new Error("Page not found");
				}

				// Check if the document has only one page
				// Used below to determine if the document title needs to be updated
				if (document.pagesMetadata.length === 1 && document.title === "") {
					documentNeedsRenamed = true;

					// If the document is untitled and there is only a single page being renamed,
					// set the document title to match the new page title
					// This is also set below on the workspace "cache" copy of the document
					document.title = title;
				}

				page.title = title;

				return document;
			});

			// If the document is untitled and the first page is renamed, set the document title to the page title
			if (!documentNeedsRenamed) {
				return;
			}

			await updateDocument({ workspaceName, documentId }, (doc) => {
				if (!doc) {
					throw new Error("Document not found");
				}

				doc.title = title;

				return doc;
			});
		},
		onSuccess: (_, { documentId }) => {
			void queryClient.invalidateQueries({
				queryKey: gitQueryKeys.repo.base(documentId),
			});
		},
	});

	return mutation;
};
