import { AsyncMutex } from "@esfx/async-mutex";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { isString } from "lodash";

import { schema } from "@moment/api-collab/prosemirror-schema";
import type { Transaction } from "@moment/api-collab/prosemirror-state";

import {
	type Cell,
	type CellInfo,
	type CellMap,
	equals,
	proseMirrorEditorStateToCells,
} from "~/models/cell";
import { emitCell } from "~/runtime/cell-compiler/emit";

import { selectEnv } from "../client/selectors";
import type { AppThunkConfig } from "../store";
import { selectCompiledCells, selectEditorState } from "./selectors";
import { transactionDispatched } from "./slice";

function aquireWriteLock(locks: Map<string, AsyncMutex>, key: string) {
	// Use a mutex to prevent multiple transactions from being sent at once
	let mutex = locks.get(key);
	if (!mutex) {
		// Create a new mutex for this document if one doesn't already exist
		mutex = new AsyncMutex();
		locks.set(key, mutex);
	}
	return mutex;
}

/**
 * This thunk is responsible for dispatching the transaction to the redux store
 * and then triggering the transaction to be sent to the backend.
 */
export const transactionDispatchedThunk = createAsyncThunk<
	void,
	{ workspaceName: string; documentId: string; pageId: string; transaction: Transaction },
	AppThunkConfig
>("editors/transactionDispatchedThunk", async ({ pageId: pageId, transaction }, { dispatch }) => {
	dispatch(transactionDispatched({ pageId, transaction, timestamp: new Date() }));

	void dispatch(cellCompilationTriggeredThunk({ pageId }));
});

async function compileCell(cell: Cell, env: MomentAppEnv): Promise<CellInfo> {
	try {
		const emitInfo = await emitCell(cell, {}, env);

		if (emitInfo.kind === "StaticError") {
			return {
				cell,
				status: "error",
				message: emitInfo.message,
			};
		}

		return {
			cell,
			emitInfo,
			status: "executable",
		};
	} catch (error) {
		return {
			cell,
			status: "error",
			message: error instanceof Error ? error.message : "Unknown error",
		};
	}
}

const compilationWriteLocks = new Map<string, AsyncMutex>();

export const cellCompilationTriggeredThunk = createAsyncThunk<
	{ pageId: string; compiledCells: CellMap } | undefined,
	{ pageId: string },
	AppThunkConfig
>("editors/cellCompilationTriggeredThunk", async (props, { getState }) => {
	const { pageId } = props;

	const lock = aquireWriteLock(compilationWriteLocks, props.pageId);
	await lock.lock();

	try {
		const state = getState();
		const env = selectEnv(state);
		const editorState = selectEditorState(pageId)(state);

		if (!editorState) {
			return;
		}

		const previousCells = selectCompiledCells(pageId)(state) || {};
		const nextCells = proseMirrorEditorStateToCells(
			editorState,
			(node) => node.type === schema.nodes.compute_cell
		);
		const nextCellIDs = new Set(nextCells.map((cell) => cell.id).filter(isString));

		//
		// Compile cells that have changed.
		//
		const cellsToCompile = nextCells.filter(
			(cell) => !!cell.id && !equals(cell, previousCells[cell.id]?.cell || {})
		);

		const anyCellsDeleted =
			nextCellIDs.difference(new Set(Object.keys(previousCells))).size > 0;

		if (!anyCellsDeleted && cellsToCompile.length === 0) {
			return;
		}

		const newCompiledCells = Object.fromEntries(
			await Promise.all(
				cellsToCompile.map(async (cell) => {
					return [cell.id, await compileCell(cell, env)] as const;
				})
			)
		);

		//
		// Merge the delete operations with the new compiled cells.
		//

		const compiledCells: CellMap = {};

		for (const id of nextCellIDs) {
			const newlyCompiledCell = newCompiledCells[id];
			if (newlyCompiledCell) {
				compiledCells[id] = newlyCompiledCell;
				continue;
			}

			const previousCell = previousCells[id];
			if (previousCell) {
				compiledCells[id] = previousCell;
				continue;
			}
		}

		//
		// Return data to update state.
		//

		return {
			pageId,
			compiledCells,
		};
	} finally {
		lock.unlock();
	}
});
