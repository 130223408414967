import { useMutation } from "@tanstack/react-query";
import { invoke } from "@tauri-apps/api/core";

import { useAuth } from "~/auth/useAuth";

import { getDocumentDirPath } from "../filesystem";

export const useAddUpstreamBranchMutation = () => {
	const { getAccessTokenSilently } = useAuth();

	const mutation = useMutation({
		mutationFn: async (args: {
			documentId: string;
			localBranch: string;
			remote: string;
			upstreamBranch: string;
		}) => {
			const { documentId, localBranch, remote, upstreamBranch: branch } = args;
			const token = await getAccessTokenSilently();
			const path = await getDocumentDirPath(documentId);

			await invoke("push", { path, remote, branch, token });
			return await invoke("add_upstream_branch", {
				path,
				localBranch,
				remote,
				upstreamBranch: branch,
			});
		},
	});

	return mutation;
};
