import { ChevronLeft, ChevronRight, Close, Search } from "@carbon/icons-react";
import { type FC, useEffect, useRef, useState } from "react";
import { useDebounce } from "react-use";

import { useScopedKeyBinding } from "~/components/common/keybindings";
import { IconButton } from "~/ui/Button";
import { Input } from "~/ui/Input";

interface FindInPageProps {
	onClose: () => void;
}

// These keys correspond to CSS rules in the design-system.
// The key names are used by the ::highlight CSS pseudo-element
// making it possible to target the highlights created by this
// component.
// https://developer.mozilla.org/en-US/docs/Web/CSS/::highlight
const currentMatchHighlightKey = "current-match";
const searchResultsHighlightKey = "search-results";

/**
 * This function was copied almost verbatim from:
 * https://developer.mozilla.org/en-US/docs/Web/API/CSS_Custom_Highlight_API#examples
 */
const getTextRanges = (searchText: string) => {
	let parentContainerEl = document.querySelector("main");
	if (!parentContainerEl) {
		parentContainerEl = document.querySelector(`div[data-component="DocsList"]`);
		if (!parentContainerEl) {
			console.error("none of the parent elements were found.");
			return;
		}
	}

	const searchTextLowerCase = searchText.toLowerCase();

	const treeWalker = document.createTreeWalker(parentContainerEl, NodeFilter.SHOW_TEXT);
	const allTextNodes: Node[] = [];
	let currentNode = treeWalker.nextNode();
	while (currentNode) {
		allTextNodes.push(currentNode);
		currentNode = treeWalker.nextNode();
	}

	const ranges = allTextNodes
		.map((el) => {
			return { el, text: el.textContent?.toLowerCase() || "" };
		})
		.map(({ text, el }) => {
			const indices: number[] = [];
			let startPos = 0;
			while (startPos < text.length) {
				const index = text.indexOf(searchTextLowerCase, startPos);
				if (index === -1) {
					break;
				}
				indices.push(index);
				startPos = index + searchTextLowerCase.length;
			}

			// Create a range object for each instance of
			// str we found in the text node.
			return indices.map((index) => {
				const range = new Range();
				range.setStart(el, index);
				range.setEnd(el, index + searchTextLowerCase.length);
				return range;
			});
		});

	return ranges.flat();
};

export const FindInPage: FC<FindInPageProps> = ({ onClose }) => {
	const inputBox = useRef<HTMLInputElement | null>(null);
	const [searchText, setSearchText] = useState<string>("");
	const [enableForward, setEnableForward] = useState(false);
	const [enableBackward, setEnableBackward] = useState(false);
	const [ranges, setRanges] = useState<Range[]>();
	useDebounce(
		() => {
			if (!searchText) {
				return;
			}
			setRanges(getTextRanges(searchText));
		},
		300,
		[searchText]
	);
	const [activeMatchIndex, setActiveMatchIndex] = useState<number>(-1);
	// This is used to track if the user has the Shift key pressed
	// when the Enter key is also pressed. It's used to navigate
	// between matches in the reverse direction.
	const [isShiftKeyDown, setIsShiftKeyDown] = useState(false);

	const reset = () => {
		setEnableForward(false);
		setEnableBackward(false);
		setRanges(undefined);
		setActiveMatchIndex(-1);

		CSS.highlights.clear();
	};

	useScopedKeyBinding("esc", () => {
		reset();
		onClose();
	});

	// This key-binding is only valid while this component still
	// remains rendered in the desktop app.
	// The desktop app's `main` process registers an app-wide
	// key-binding that controls the rendering of this component.
	// So this key-binding isn't even added until this component
	// is rendered.
	useScopedKeyBinding(["command+f", "ctrl+f"], () => {
		inputBox.current?.focus();
		// "extend" extends the selection from the current caret position
		// to whatever granularity is specified, in this case, the entire line.
		//
		// This mimics the same functionality users get from a browser's
		// Find feature, where the entire input is selected when
		// Command/Ctrl+F is pressed again while the Find widget is
		// open but the focus is elsewhere in the document, i.e. the user clicked
		// elsewhere in the document while the Find widget is open.
		window.getSelection()?.modify("extend", "backward", "line");
	});

	useEffect(() => {
		if (!searchText) {
			reset();
			// The small delay is to ensure that the input box
			// is rendered.
			setTimeout(() => inputBox?.current?.focus(), 50);
			return;
		}

		CSS.highlights.clear();
	}, [searchText]);

	useEffect(() => {
		if (!ranges?.length) {
			return;
		}

		setActiveMatchIndex(0);
		setEnableForward(true);
		setEnableBackward(true);

		const searchResultsHighlight = new Highlight(...ranges);

		// Register the Highlight object in the registry.
		CSS.highlights.set(searchResultsHighlightKey, searchResultsHighlight);

		const range = ranges[0];
		if (!range) {
			return;
		}

		CSS.highlights.set(currentMatchHighlightKey, new Highlight(range));
		range.startContainer.parentElement?.scrollIntoView({
			behavior: "instant",
			block: "nearest",
		});
	}, [ranges]);

	const getNextMatchIndex = (forward: boolean) => {
		if (activeMatchIndex === -1) {
			return 0;
		}

		const atHead = activeMatchIndex === 0;
		const atTail = ranges && ranges.length - 1 === activeMatchIndex;

		if (forward && atTail) {
			return 0;
		}

		if (!forward && atHead) {
			if (!ranges) {
				return 0;
			}
			return ranges.length - 1 || 0;
		}

		if (!forward) {
			return activeMatchIndex - 1;
		}

		return activeMatchIndex + 1;
	};

	const searchInDirection = (forward = true) => {
		if (!searchText || !ranges?.length) {
			return;
		}

		const nextIndex = getNextMatchIndex(forward);
		const range = ranges[nextIndex];
		if (!range) {
			return;
		}

		setActiveMatchIndex(nextIndex);
		CSS.highlights.set(currentMatchHighlightKey, new Highlight(range));
		range.startContainer.parentElement?.scrollIntoView({
			behavior: "instant",
			block: "nearest",
		});
	};

	const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		switch (e.key) {
			case "Enter":
				// Search in forward direction only if the Shift
				// key is still not held down.
				searchInDirection(!isShiftKeyDown);
				break;
			case "Shift":
				setIsShiftKeyDown(true);
				break;
		}
	};

	const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key !== "Shift") {
			return;
		}

		setIsShiftKeyDown(false);
	};

	return (
		<div
			className="w-96 h-12 inline-flex items-center px-2 border shadow-md rounded-md absolute top-10 right-10 z-50 bg-primary-raised"
			role="dialog"
		>
			<div className="relative w-full h-full flex items-center">
				<Input
					className="w-3/5"
					ref={inputBox}
					tabIndex={0}
					startAddon={<Search className="text-tertiary mr-1" />}
					placeholder="Find"
					spellCheck={false}
					onKeyDown={onKeyDown}
					onKeyUp={onKeyUp}
					onChange={(event) => {
						// Don't trim the input, not even
						// an input that's just a white-space
						// character.
						setSearchText(event.target.value);
					}}
					value={searchText}
				/>
				<div className="ml-2 flex items-center">
					{searchText && ranges && ranges.length > 0 && (
						<span className="text-xs whitespace-nowrap">
							{activeMatchIndex + 1} of {ranges.length}
						</span>
					)}
					{searchText && !ranges?.length && (
						<span className="text-xs whitespace-nowrap">No results</span>
					)}
				</div>
				<div className="flex items-center justify-end gap-1 w-2/5 h-full">
					<IconButton
						size="xs"
						preset="quaternary"
						disabled={!enableBackward}
						label="Previous Match"
						title="Previous Match (⇧+Enter)"
						borderRadius="default"
						onClick={() => searchInDirection(false)}
						icon={<ChevronLeft tabIndex={0} className="fill-icon-default" />}
					/>
					<IconButton
						size="xs"
						preset="quaternary"
						disabled={!enableForward}
						label="Next Match"
						title="Next Match (Enter)"
						borderRadius="default"
						onClick={() => searchInDirection()}
						icon={<ChevronRight tabIndex={0} className="fill-icon-default" />}
					/>
					<IconButton
						size="xs"
						preset="quaternary"
						borderRadius="default"
						label="Close"
						title="Close (Esc)"
						onClick={() => {
							reset();
							onClose();
						}}
						icon={<Close tabIndex={0} className="fill-icon-default" />}
					/>
				</div>
			</div>
		</div>
	);
};
