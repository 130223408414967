import { useMutation, useQueryClient } from "@tanstack/react-query";
import { invoke } from "@tauri-apps/api/core";

import { gitQueryKeys } from ".";
import { getDocumentDirPath } from "../filesystem";

export const useCommitToRepoMutation = () => {
	const queryClient = useQueryClient();

	const mutation = useMutation({
		mutationKey: [...gitQueryKeys.all(), "commit"],
		mutationFn: async (args: { documentId: string; message: string }) => {
			const { documentId, message } = args;

			const path = await getDocumentDirPath(documentId);

			console.log("about to commit", documentId, message);
			return await invoke("commit", { path, message });
		},
		onSuccess(data, variables, context) {
			console.log("commit success", data, variables, context);
		},
		onError(error, variables, context) {
			console.error("commit error", error, variables, context);
		},
		onSettled: async () => {
			await queryClient.invalidateQueries({ queryKey: [...gitQueryKeys.all()] });
		},
	});

	return mutation;
};
