import { useMutation } from "@tanstack/react-query";
import { useRouter } from "next/router";

import { useAuth } from "~/auth/useAuth";
import { createRepo } from "~/utils/gitea";

import { giteaQueryKeys } from ".";

export const useCreateRepoMutation = () => {
	const { getAccessTokenSilently, isAuthenticated } = useAuth();
	const router = useRouter();

	return useMutation({
		mutationKey: giteaQueryKeys.repo.create(),
		mutationFn: async ({
			documentId,
			workspaceName,
		}: {
			documentId: string;
			workspaceName: string;
		}) => {
			const token = await getAccessTokenSilently({
				promptForLogin: true,
				redirectPath: router.asPath,
			});
			if (!isAuthenticated || !token) {
				return;
			}

			return createRepo(token, workspaceName, documentId);
		},
	});
};
