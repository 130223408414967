import {
	type DehydratedState,
	HydrationBoundary,
	QueryClient,
	QueryClientProvider,
} from "@tanstack/react-query";
import { isEmpty } from "lodash";
import dynamic from "next/dynamic";
import { usePathname } from "next/navigation";
import { useCallback, useEffect } from "react";

import { isTokenError } from "~/auth/useAuth";
import { createEditorState } from "~/components/canvas/easel/editor-state/createEditorState";
import { editorLoaded } from "~/store/editors/slice";
import { cellCompilationTriggeredThunk } from "~/store/editors/thunks";
import type { StoredPage } from "~/store/editors/types";
import { useAppDispatch } from "~/store/hooks";

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			throwOnError: false,
			staleTime: Infinity,
			retry: (_, e) => !isTokenError(e),
		},
	},
});

export const QueryProvider = ({
	children,
	dehydratedState,
}: {
	children: React.ReactNode;
	dehydratedState?: DehydratedState;
}) => {
	const dispatch = useAppDispatch();
	const pathname = usePathname();

	// Hydrate the editor state for SSR'ed pages
	const hydrateState = useCallback(() => {
		if (!isEmpty(dehydratedState)) {
			dehydratedState.queries
				.filter((query) => query.queryKey[3] === "pages" && query.queryKey[5] === "content")
				.forEach((query) => {
					const workspaceName = (query.queryKey[1] as string | undefined) ?? "";
					const documentId = (query.queryKey[2] as string | undefined) ?? "";
					const pageId = (query.queryKey[4] as string | undefined) ?? "";

					if (!isEmpty(pageId)) {
						const stored = query.state.data as StoredPage;

						dispatch(
							editorLoaded({
								pageId,
								editorState: createEditorState(
									workspaceName,
									documentId,
									pageId,
									stored.doc
								),
								lastAccessed: new Date(),
							})
						);
						void dispatch(cellCompilationTriggeredThunk({ pageId }));
					}
				});
		}
	}, [dehydratedState, dispatch]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(hydrateState, [pathname]);

	return (
		<QueryClientProvider client={queryClient}>
			<HydrationBoundary state={dehydratedState}>
				{children}

				<DevTools />
			</HydrationBoundary>
		</QueryClientProvider>
	);
};

const DevTools = () => {
	if (process.env.NODE_ENV === "development") {
		const ReactQueryDevtools = dynamic(
			() => import("@tanstack/react-query-devtools").then((m) => m.ReactQueryDevtools),
			{
				ssr: false,
			}
		);

		return <ReactQueryDevtools initialIsOpen={false} />;
	}

	return null;
};
