import { invoke } from "@tauri-apps/api/core";
import * as path from "@tauri-apps/api/path";
import * as fs from "@tauri-apps/plugin-fs";
import YAML from "yaml";

import { isDesktopAppMode } from "~/auth/interop/common";

import { Logger } from "../utils/logging";

const logger = new Logger("fs");
export const appEnv = process.env.APP_ENV ?? "local";

/* -------------------------------
 *  Path Operations
 * ------------------------------- */

export const pathJoin = path.join;

/* -------------------------------
 *  File Operations
 *
 *  - We explicitly do not set write/mkdir modes and let the system handle those
 * ------------------------------- */

export async function writeTextFile(
	filePath: string,
	content: string,
	options: Parameters<typeof fs.writeTextFile>[2] = { baseDir: fs.BaseDirectory.Home }
) {
	if (!isDesktopAppMode()) {
		console.error("fs.writeTextFile called in non-desktop mode", {
			filePath,
			content,
			options,
		});
		return;
	}

	// Part of the Temporary Workaround ////////////////////////////////////////
	const encoder = new TextEncoder();
	return await invoke("write_text_file", encoder.encode(content), {
		headers: {
			path: encodeURIComponent(filePath),
			options: JSON.stringify(options),
		},
	});
	// End of the Temporary Workaround /////////////////////////////////////////

	// Pre-temporary workaround code ///////////////////////////////////////////
	// return fs.writeTextFile(filePath, content, options);
}

export async function writeJsonFile(
	filePath: string,
	content: object,
	options: Parameters<typeof writeTextFile>[2] = { baseDir: fs.BaseDirectory.Home }
) {
	return writeTextFile(filePath, JSON.stringify(content, null, "\t") + "\n", options);
}

export async function writeYamlFile(
	filePath: string,
	content: object,
	options: Parameters<typeof writeTextFile>[2] = { baseDir: fs.BaseDirectory.Home }
) {
	return writeTextFile(filePath, YAML.stringify(content), options);
}

export async function writeBinaryFile(
	filePath: string,
	content: Uint8Array<ArrayBufferLike>,
	options: Parameters<typeof fs.writeFile>[2] = { baseDir: fs.BaseDirectory.Home }
) {
	if (!isDesktopAppMode()) {
		console.error("fs.writeBinaryFile called in non-desktop mode", {
			filePath,
			content,
			options,
		});
		return;
	}

	return fs.writeFile(filePath, content, options);
}

export async function readTextFile(
	filePath: string,
	options: Parameters<typeof fs.readTextFile>[1] = { baseDir: fs.BaseDirectory.Home }
) {
	if (!isDesktopAppMode()) {
		console.error("fs.readTextFile called in non-desktop mode", {
			filePath,
			options,
		});
		return "";
	}

	return fs.readTextFile(filePath, options);
}

export async function readJsonFile<T>(
	filePath: string,
	options: Parameters<typeof readTextFile>[1] = { baseDir: fs.BaseDirectory.Home }
): Promise<T | undefined> {
	try {
		const text = await readTextFile(filePath, options);
		return JSON.parse(text) as T;
	} catch (error) {
		logger.error("Failed to read JSON file", { filePath, error });
		return undefined;
	}
}

export async function readYamlFile<T>(
	filePath: string,
	options: Parameters<typeof readTextFile>[1] = { baseDir: fs.BaseDirectory.Home }
): Promise<T | undefined> {
	try {
		const text = await readTextFile(filePath, options);
		return YAML.parse(text) as T;
	} catch (error) {
		logger.error("Failed to read YAML file", { filePath, error });
		return undefined;
	}
}

export async function readBinaryFile(
	filePath: string,
	options: Parameters<typeof fs.readFile>[1] = { baseDir: fs.BaseDirectory.Home }
) {
	if (!isDesktopAppMode()) {
		console.error("fs.readBinaryFile called in non-desktop mode", {
			filePath,
			options,
		});
		return undefined;
	}

	try {
		return fs.readFile(filePath, options);
	} catch (error) {
		logger.error("Failed to read binary file", { filePath, error });
		return undefined;
	}
}

export async function exists(
	filePath: string,
	options: Parameters<typeof fs.exists>[1] = { baseDir: fs.BaseDirectory.Home }
) {
	if (!isDesktopAppMode()) {
		console.error("fs.exists called in non-desktop mode", {
			filePath,
			options,
		});
		return false;
	}

	try {
		return fs.exists(filePath, options);
	} catch (error) {
		logger.error("Failed to check if file exists", { filePath, error });
		return false;
	}
}

export async function create(
	filePath: string,
	options: Parameters<typeof fs.create>[1] = { baseDir: fs.BaseDirectory.Home }
) {
	if (!isDesktopAppMode()) {
		console.error("fs.create called in non-desktop mode", {
			filePath,
			options,
		});
		return;
	}

	try {
		return fs.create(filePath, {
			baseDir: options.baseDir,
		});
	} catch (error) {
		logger.error("Failed to create file", { filePath, error });
		throw error;
	}
}

export async function mkdir(
	dirPath: string,
	options: Parameters<typeof fs.mkdir>[1] = { baseDir: fs.BaseDirectory.Home }
) {
	if (!isDesktopAppMode()) {
		console.error("fs.mkdir called in non-desktop mode", {
			dirPath,
			options,
		});
		return;
	}

	try {
		return fs.mkdir(dirPath, {
			baseDir: options.baseDir,
			recursive: true,
		});
	} catch (error) {
		logger.error("Failed to create directory", { dirPath, error });
		throw error;
	}
}

export async function remove(
	filePath: string,
	options: Parameters<typeof fs.remove>[1] = { baseDir: fs.BaseDirectory.Home }
) {
	if (!isDesktopAppMode()) {
		console.error("fs.remove called in non-desktop mode", {
			filePath,
			options,
		});
		return;
	}

	try {
		return fs.remove(filePath, {
			baseDir: options.baseDir,
			recursive: true,
		});
	} catch (error) {
		logger.error("Failed to remove file", { filePath, error });
		throw error;
	}
}

export async function readDir(
	dirPath: string,
	options: Parameters<typeof fs.readDir>[1] = { baseDir: fs.BaseDirectory.Home }
) {
	if (!isDesktopAppMode()) {
		console.error("fs.readDir called in non-desktop mode", {
			dirPath,
			options,
		});
		return [];
	}

	try {
		return fs.readDir(dirPath, options);
	} catch (error) {
		logger.error("Failed to read directory", { dirPath, error });
		throw error;
	}
}

/* -------------------------------
 *  Directory & File Paths
 * ------------------------------- */

const pathMap = new Map<string, string>();
async function getPath(key: string, fn: () => Promise<string>): Promise<string> {
	if (!isDesktopAppMode()) {
		console.error("path.join called in non-desktop mode", {
			key,
		});
		return "";
	}

	if (pathMap.has(key)) {
		return pathMap.get(key) as string;
	}

	const path = await fn();
	pathMap.set(key, path);
	return path;
}

// Root
const getUserHomeDirPath = async () => await getPath("userHomeDirPath", path.homeDir);
const getMomentDirPath = async () =>
	await getPath("momentDirPath", async () =>
		appEnv === "production"
			? path.join(await getUserHomeDirPath(), ".moment")
			: path.join(await getUserHomeDirPath(), `.moment-${appEnv || "local"}`)
	);

export const getMomentGitConfigPath = async () =>
	await getPath("momentGitConfigPath", async () =>
		path.join(await getMomentDirPath(), ".gitconfig")
	);

export const getMomentAtlasEnvPath = async () =>
	await getPath("momentAtlasEnvPath", async () =>
		path.join(await getMomentDirPath(), "atlas.env")
	);

// Database
export const getDbBaseDir = async () => await getMomentDirPath();
export const getMomentDbPath = async () =>
	await getPath("momentDbPath", async () => path.join(await getDbBaseDir(), "moment.db"));

// Config
export const getConfigFilePath = async () =>
	await getPath("configFilePath", async () => path.join(await getMomentDirPath(), "moment.json"));

// Workspaces

// TODO(workspace restructure) remove
export const getWorkspaceDirPath = async (workspaceName: string) =>
	await getPath(`workspaceDirPath:${workspaceName}`, async () =>
		path.join(await getMomentDirPath(), "workspaces", workspaceName)
	);

// TODO(workspace restructure) remove
export const getWorkspaceFilePath = async (workspaceName: string) =>
	await getPath(`workspaceFilePath:${workspaceName}`, async () =>
		path.join(await getWorkspaceDirPath(workspaceName), "workspace.json")
	);

// Documents

/**
 * @returns The path to the documents directory.
 */
export const getDocumentsDirPath = async () =>
	await getPath("documentsDirPath", async () => path.join(await getMomentDirPath(), "documents"));

/**
 * @returns The path to a document's directory.
 */
export const getDocumentDirPath = async (documentId: string) =>
	await getPath(`documentDirPath:${documentId}`, async () =>
		path.join(await getDocumentsDirPath(), documentId)
	);

/**
 * @returns The path to a document's moment.yml file.
 */
export const getDocumentFilePath = async (documentId: string) =>
	await getPath(`documentFilePath:${documentId}`, async () =>
		path.join(await getDocumentDirPath(documentId), "moment.yml")
	);

// Pages

/**
 * @returns The path to a page's directory.
 */
export const getPageDirPath = async (documentId: string, pageId: string) =>
	await getPath(`pageDirPath:${documentId}:${pageId}`, async () =>
		path.join(await getDocumentDirPath(documentId), "pages", pageId)
	);

/**
 * @returns The path to a page's content file.
 */
export const getPageFilePath = async (documentId: string, pageId: string) =>
	await getPath(`pageFilePath:${documentId}:${pageId}`, async () =>
		path.join(await getPageDirPath(documentId, pageId), "index.md")
	);

/**
 * @returns The path to a page's storage file.
 */
export const getPageStorageFilePath = async (documentId: string, pageId: string) =>
	await getPath(`pageStorageFilePath:${documentId}:${pageId}`, async () =>
		path.join(await getPageDirPath(documentId, pageId), "pageStorage.json")
	);

/**
 * @returns The path to a page's related files directory.
 */
export const getPageRelatedFilesDirPath = async (documentId: string, pageId: string) =>
	await getPath(`pageRelatedFilesDirPath:${documentId}:${pageId}`, async () =>
		path.join(await getPageDirPath(documentId, pageId), "related")
	);

/**
 * @returns The path to a page's related file.
 */
export const getPageRelatedFilePath = async (
	documentId: string,
	pageId: string,
	filename: string
) =>
	await getPath(`pageRelatedFilePath:${documentId}:${pageId}:${filename}`, async () =>
		path.join(await getPageRelatedFilesDirPath(documentId, pageId), filename)
	);
