import { type Maybe, type User as UserGQL } from "../api/generated/graphql";

export type UserID = string;
export interface User {
	id: UserID;
	organizationID: string;
	isMomentEmployee: boolean;
	name: string;
	giteaUserId: string;
	nickname: string;
	givenName: string;
	familyName: string;
	profilePicture: string;
	email: string;
	publicEmail: string;
	updatedAt: string;
	alternateIDs: Maybe<AlternateIDs>;
}

export type CanvasUser = Omit<User, "email" | "updatedAt">;

export type AlternateIDs = {
	google: Maybe<string>;
	github: Maybe<string>;
};

export const fromGQL = (user: UserGQL): User => {
	// This is currently just a straight mapping. Add any additional logic here.
	return {
		...user,
		giteaUserId: "",
		publicEmail: user.email,
		isMomentEmployee: false,
	};
};

export function auth0UserIdToGiteaUserId(auth0UserId: string): string {
	return auth0UserId.replace("|", "-");
}

export const canvasUserFromUser = (user?: User): CanvasUser | undefined => {
	if (user === undefined) {
		return user;
	}

	const {
		id,
		organizationID,
		name,
		nickname,
		givenName,
		familyName,
		profilePicture,
		publicEmail,
		alternateIDs,
	} = user;
	return {
		id,
		organizationID,
		name,
		nickname,
		givenName,
		familyName,
		profilePicture,
		publicEmail,
		alternateIDs: {
			google: alternateIDs?.google || null,
			github: alternateIDs?.github || null,
		},
		giteaUserId: auth0UserIdToGiteaUserId(user.id),
		isMomentEmployee: false,
	};
};
