import { useMutation, useQueryClient } from "@tanstack/react-query";
import { invoke } from "@tauri-apps/api/core";
import { format } from "date-fns";

import { makeToast } from "@moment/design-system/Toast";

import { gitQueryKeys } from ".";

const prettyTimestamp = () => {
	const now = new Date();

	return format(now, "PPppp");
};

export const useInitGitRepoMutation = () => {
	const queryClient = useQueryClient();

	const mutation = useMutation({
		mutationKey: gitQueryKeys.init(),
		mutationFn: async ({ documentPath }: { documentPath: string }) => {
			console.log("init_repo", { path: documentPath });
			await invoke("init_repo", { path: documentPath });

			console.log("committing", { path: documentPath });
			const result = await invoke("commit", {
				path: documentPath,
				message: `🚀 Initial commit at ${prettyTimestamp()}`,
			});
			console.log("committed", result);
		},
		onSuccess: () => {
			void queryClient.invalidateQueries({
				queryKey: gitQueryKeys.all(),
			});
		},
		onError: (error) => {
			console.error("useInitGitRepoMutation", error);
			makeToast({
				message: "Failed to initialize git repository",
				variant: "error",
			});
		},
	});

	return mutation;
};
