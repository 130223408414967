import { useQuery } from "@tanstack/react-query";
import { invoke } from "@tauri-apps/api/core";
import { isEmpty } from "lodash";

import { isDesktopAppMode } from "~/auth/interop/common";

import { type GitRemotes, gitQueryKeys } from ".";
import { getDocumentDirPath } from "../filesystem";

export const useGitRemotesQuery = (args: { workspaceName: string; documentId: string }) => {
	const { workspaceName, documentId } = args;

	const query = useQuery({
		enabled: isDesktopAppMode() && !isEmpty(workspaceName) && !isEmpty(documentId),
		staleTime: 1000 * 60 * 5, // 5 minutes
		queryKey: gitQueryKeys.repo.remotes.list(documentId),
		queryFn: async () => {
			const path = await getDocumentDirPath(documentId);
			console.log("requesting remotes", { path });
			const remotes = await invoke<GitRemotes>("list_remotes", { path });
			console.log("got remotes", remotes);
			return remotes;
		},
	});

	return query;
};
