import { Node } from "prosemirror-model";
import { EditorState } from "prosemirror-state";

import { schema } from "@moment/api-collab/prosemirror-schema";

import { isDesktopAppMode } from "~/auth/interop/common";
import { createPlugins } from "~/components/canvas/easel/prosemirror/plugins";

export const createEditorState = (
	workspaceName: string,
	documentId: string,
	pageId: string,
	doc: JsonObject
) => {
	const state = EditorState.create({
		doc: Node.fromJSON(schema, doc),
		plugins: createPlugins({
			workspaceName,
			documentId,
			pageId,
			schema,
			readonly: !isDesktopAppMode(),
		}),
	});

	return state;
};
