import { useMutation, useQueryClient } from "@tanstack/react-query";
import { produce } from "immer";
import sql from "sql-template-tag";

import { type DocumentListItem, DocumentListItemSchema, documentQueryKeys } from "~/data/documents";

import { getDb } from "../sql";

export const useUpsertDocumentCacheMutation = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: documentQueryKeys.cache.upsert(),
		mutationFn: async ({
			id,
			path,
			title,
			createdAt,
			updatedAt,
		}: {
			id: string;
			path?: string;
			title?: string;
			createdAt?: Date;
			updatedAt?: Date;
		}) => {
			const db = await getDb();

			let document: DocumentListItem | undefined;

			/*
			 * Optimistically update the cache
			 */
			queryClient.setQueryData<DocumentListItem[]>(documentQueryKeys.list.cache(), (old) => {
				const draft = structuredClone(old);

				document = draft?.find((doc) => doc.id === id);
				if (document) {
					document = produce(document, (draft) => {
						draft.path = path ?? draft.path;
						draft.title = title ?? draft.title;
						draft.createdAt = createdAt ?? draft.createdAt;
						draft.updatedAt = updatedAt ?? draft.updatedAt;
					});
				} else {
					document = DocumentListItemSchema.parse({
						id,
						path,
						title,
						createdAt,
						updatedAt,
					});
					draft?.push(document);
				}

				return draft;
			});

			if (!document) {
				console.error("useUpsertDocumentCacheMutation", "Something went very wrong");
				return;
			}

			const query = sql`
				INSERT INTO documents (id, path, title, createdAt, updatedAt) VALUES (${document.id}, ${document.path}, ${document.title}, ${document.createdAt}, ${document.updatedAt})
				ON CONFLICT (id) DO UPDATE SET path = ${document.path}, title = ${document.title}, createdAt = ${document.createdAt}, updatedAt = ${document.updatedAt}
			`;

			return await db?.execute(query.sql, query.values).catch((e) => {
				console.error("useUpsertDocumentCacheMutation", e);
			});
		},
	});
};
