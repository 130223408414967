import { Channel, invoke } from "@tauri-apps/api/core";
import {
	type FC,
	type PropsWithChildren,
	createContext,
	useCallback,
	useContext,
	useMemo,
	useState,
} from "react";

import { LoadingSpinner } from "@moment/design-system/LoadingSpinner";

import { selectIsAppUpdateReady } from "~/store/desktop-app/selectors";
import { useAppSelector } from "~/store/hooks";
import { Button } from "~/ui/Button";
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "~/ui/Dialog";
import { notImplementedFn } from "~/utils/context";

type ConfirmFn = () => Promise<boolean>;
export type OutdatedAppModalContextState = {
	confirm: ConfirmFn;
};

export const initialState: OutdatedAppModalContextState = {
	confirm: notImplementedFn("confirm", "OutdatedAppModalContext", Promise.resolve(true)),
};

const OutdatedAppModalContext = createContext<OutdatedAppModalContextState>(initialState);

export const useOutdatedAppModal = () => useContext(OutdatedAppModalContext);

export const OutdatedAppModalWrapper: FC<PropsWithChildren> = ({ children }) => {
	// State
	const [visible, setVisible] = useState(false);
	// We can't set the resolve function directly in state because anonymous function do not trigger
	// state updates
	const [resolve, setResolve] = useState<{
		fn: undefined | ((resolve: boolean | PromiseLike<boolean>) => void);
	}>({ fn: undefined });
	const isAppUpdateReady = useAppSelector(selectIsAppUpdateReady);

	// Methods
	const showDialog = useCallback(() => setVisible(true), [setVisible]);

	const closeDialog = useCallback(() => {
		setVisible(false);
	}, [setVisible]);

	const confirm: ConfirmFn = useCallback(() => {
		showDialog();

		return new Promise<boolean>((resolve) => {
			setResolve({ fn: resolve });
		});
	}, [showDialog, setResolve]);

	const onConfirm = useCallback(async () => {
		await invoke("finalize_update", { onEvent: new Channel<{ event: string }>() });
		resolve.fn?.(true);
		closeDialog();
	}, [resolve, closeDialog]);

	const value = useMemo(() => ({ confirm }), [confirm]);

	return (
		<OutdatedAppModalContext.Provider value={value}>
			{children}
			<Dialog open={visible}>
				<DialogContent hideCloseButton>
					<DialogHeader>
						<DialogTitle>App Update Required</DialogTitle>
					</DialogHeader>
					<DialogDescription>
						<span>
							A new version of the app is available. Please restart the app to install
							the update.
						</span>
						{!isAppUpdateReady && (
							<div className="flex flex-row items-center py-2">
								<div className="mr-2 italic text-secondary">
									Downloading update...
								</div>
								<LoadingSpinner />
							</div>
						)}
					</DialogDescription>
					<DialogFooter>
						<Button
							preset="primary"
							onClick={onConfirm}
							disabled={!isAppUpdateReady}
							aria-label="Confirm"
						>
							Restart app
						</Button>
					</DialogFooter>
				</DialogContent>
			</Dialog>
			{/* <BasicDialog
				isOpen={visible}
				closeDialog={onCancel}
				title="App Update Required"
				titleIcon={<UpdateNow />}
				description={
					<div>
						<span>
							A new version of the app is available. Please restart the app to
							automatically install the update.
						</span>
						{!isAppUpdateReady && (
							<div className="flex flex-row items-center py-2">
								<div className="mr-2">Downloading update...</div>
								<LoadingSpinner />
							</div>
						)}
					</div>
				}
				variant="normal"
				confirmText="Restart app"
				onConfirm={onConfirm}
				confirmDisabled={!isAppUpdateReady}
				hideCancel
				preventDismissal
			/> */}
		</OutdatedAppModalContext.Provider>
	);
};
