import { useQuery } from "@tanstack/react-query";
import { invoke } from "@tauri-apps/api/core";
import { isEmpty } from "lodash";

import { type GitDiff, gitQueryKeys } from ".";
import { getDocumentDirPath } from "../filesystem";
import { LOCAL_WORKSPACE_NAME } from "../workspaces";

export const useGitDiffQuery = ({
	workspaceName,
	documentId,
	commitHash = "HEAD",
}: {
	workspaceName: string;
	documentId: string;
	commitHash?: string;
}) => {
	const query = useQuery({
		queryKey: gitQueryKeys.repo.diff.base(documentId),
		queryFn: async () => {
			const dir = await getDocumentDirPath(documentId);

			console.log("fetching diff");
			console.log("spurious usage of commitHash", commitHash);
			try {
				const result = await invoke<GitDiff>("diff", { path: dir });
				console.log("got diff", result);
				return result;
			} catch (e) {
				console.error("diff", e);
				return Promise.reject(new Error("diff failed"));
			}
		},
		enabled: workspaceName === LOCAL_WORKSPACE_NAME && !isEmpty(documentId),
	});

	return query;
};
