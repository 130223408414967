import { useMutation, useQueryClient } from "@tanstack/react-query";
import { invoke } from "@tauri-apps/api/core";
import { useRouter } from "next/router";

import { makeToast } from "@moment/design-system/Toast";

import { useAuth } from "~/auth/useAuth";
import { getDocumentDirPath } from "~/data/filesystem";

import { documentQueryKeys } from "../documents";
import { pageQueryKeys } from "../pages";

export const usePullRepoMutation = () => {
	const queryClient = useQueryClient();
	const { getAccessTokenSilently } = useAuth();
	const router = useRouter();

	const mutation = useMutation({
		mutationFn: async ({
			workspaceName,
			documentId,
		}: {
			workspaceName: string;
			documentId: string;
		}) => {
			if (workspaceName === "local") {
				return;
			}

			const token = await getAccessTokenSilently({
				promptForLogin: true,
				redirectPath: router.asPath,
			});

			const dir = await getDocumentDirPath(documentId);

			console.log("pull_repo", { path: dir });
			await invoke("pull_repo", { path: dir, token });
		},
		onSuccess: (_, { workspaceName, documentId }) => {
			return Promise.all([
				queryClient.invalidateQueries({
					queryKey: documentQueryKeys.document.content(workspaceName, documentId),
				}),
				queryClient.invalidateQueries({
					queryKey: pageQueryKeys.document.list(workspaceName, documentId),
				}),
			]);
		},
		onError: (error) => {
			makeToast({
				message: `Failed to pull repo: ${error.message}`,
				variant: "error",
			});
		},
	});

	return mutation;
};
