import { useMutation, useQueryClient } from "@tanstack/react-query";
import sql from "sql-template-tag";

import { type DocumentListItem, documentQueryKeys } from "~/data/documents";

import { getDb } from "../sql";

export const useDeleteDocumentCacheMutation = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: documentQueryKeys.cache.delete(),
		mutationFn: async ({ id }: { id: string }) => {
			const db = await getDb();

			const query = sql`
				DELETE FROM documents WHERE id = ${id}
			`;

			return await db?.execute(query.sql, query.values);
		},
		onSuccess: (_, { id }) => {
			queryClient.setQueryData<DocumentListItem[]>(
				documentQueryKeys.list.cache(),
				(documents) => documents?.filter((document) => document.id !== id)
			);
		},
	});
};
