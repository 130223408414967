import { useQuery } from "@tanstack/react-query";
import { invoke } from "@tauri-apps/api/core";

import { ConfigListSummarySchema, gitQueryKeys } from ".";
import { getMomentGitConfigPath } from "../filesystem";
import { useRouteParams } from "../route";

export const useGitMomentConfigQuery = (args?: { workspaceName: string; documentId: string }) => {
	const routeParams = useRouteParams();

	const { workspaceName = routeParams.workspaceName, documentId = routeParams.documentId } =
		args ?? {};

	const query = useQuery({
		queryKey: gitQueryKeys.config.moment.get(),
		queryFn: async () => {
			if (!workspaceName || !documentId) {
				return;
			}

			const gitConfigPath = await getMomentGitConfigPath();

			try {
				console.log("fetching config", { path: gitConfigPath });
				const config = await invoke("config", { path: gitConfigPath });
				const parsed = ConfigListSummarySchema.safeParse({
					all: config,
					files: [],
					values: {},
				});

				if (!parsed.success) {
					console.log(
						"failed to parse config, falling back to default value",
						parsed.error
					);
					return { all: {}, files: [], values: {} };
				}
				console.log("got config", config);

				return parsed.data;
			} catch (e) {
				console.error("Failed to fetch git config", e);
				throw e;
			}
		},
	});

	return query;
};
