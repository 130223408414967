import { InputRule } from "prosemirror-inputrules";
import { type NodeType } from "prosemirror-model";
import { NodeSelection } from "prosemirror-state";

// Given a code block node type, returns an input rule that turns a
// textblock starting with three backticks into a code block.
export const codeBlockRule = (nodeType: NodeType): InputRule => {
	// return textblockTypeInputRule(/^```$/, nodeType);
	return new InputRule(/^```$/, (state, match, start, end) => {
		const $start = state.doc.resolve(start);
		if (!$start.node(-1).canReplaceWith($start.index(-1), $start.indexAfter(-1), nodeType)) {
			return null;
		}

		let tr = state.tr;

		tr = state.tr.delete(start, end).setBlockType(start, start, nodeType);

		tr = tr.setSelection(NodeSelection.create(tr.doc, start - 1));

		return tr;
	});
};
