import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { isDesktopAppMode } from "~/auth/interop/common";
import { getPageStorageFilePath, readJsonFile, writeJsonFile } from "~/data/filesystem";

export const pageStorageSlice = createApi({
	reducerPath: "pageStorage",
	baseQuery: fetchBaseQuery({}),
	tagTypes: ["PageStorage"],
	endpoints: (build) => ({
		fetchPageStorage: build.query<object, { workspaceName; documentId; pageId }>({
			queryFn: async ({ workspaceName, documentId, pageId }) => {
				if (!workspaceName || !documentId || !pageId) {
					throw new Error("Missing required parameters");
				}

				if (!isDesktopAppMode()) {
					const path = `${workspaceName}/${documentId}/pages/${pageId}`;
					try {
						const response = await fetch(`/api/public/${path}/pageStorage.json`);
						if (!response.ok) {
							throw new Error("Failed to fetch public page storage");
						}

						const pageStorage = await response.json();

						return { data: pageStorage };
					} catch (error) {
						return { data: {} };
					}
				}

				const path = await getPageStorageFilePath(documentId, pageId);

				// IMPORTANT: Zero value is `{}`. If `pageStorage` returns `undefined` (as it does
				// in the error case), then all React compute cells hang indefinitely. The runtime
				// interprets `undefined` as "a result has not been produced yet."
				const pageStorage = (await readJsonFile(path)) ?? {};

				return { data: pageStorage };
			},
		}),

		writePageStorage: build.mutation<
			object,
			{
				workspaceName: string;
				documentId: string;
				pageId: string;
				pageStorageContents: object;
			}
		>({
			queryFn: async ({ workspaceName, documentId, pageId, pageStorageContents }) => {
				if (workspaceName !== "local") {
					throw new Error("Only local workspace is supported");
				}

				await writeJsonFile(
					await getPageStorageFilePath(documentId, pageId),
					pageStorageContents
				);

				return { data: {} };
			},
		}),
	}),
});

export const { useFetchPageStorageQuery, useWritePageStorageMutation } = pageStorageSlice;
