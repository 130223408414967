import { useGitDiffSummaryQuery } from "./useGitDiffSummaryQuery";
import { useGitFetchMutation } from "./useGitFetchMutation";
import { useGitRepoStatusQuery } from "./useGitRepoStatusQuery";

export const PUBLISHED_BRANCH = "main";

export const useGitUnpublishedChanges = ({
	workspaceName,
	documentId,
}: {
	workspaceName: string;
	documentId: string;
}) => {
	const status = useGitRepoStatusQuery();

	const fetchMutation = useGitFetchMutation();

	const { data: diffSummary } = useGitDiffSummaryQuery({ workspaceName, documentId });
	const hasUncommittedChanges =
		diffSummary && (diffSummary.insertions > 0 || diffSummary.deletions > 0);

	const hasUnpublishedChanges =
		hasUncommittedChanges || (status.data?.isRepo && status.data.ahead > 0);

	return { hasUnpublishedChanges, status, fetchMutation };
};
