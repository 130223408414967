import { useMutation, useQueryClient } from "@tanstack/react-query";
import sql from "sql-template-tag";
import { useDebouncedCallback } from "use-debounce";

import { gitQueryKeys } from ".";
import { documentQueryKeys } from "../documents";
import { getDb } from "../sql";
import type { GitRepoStatus } from "./useGitRepoStatusQuery";

export const useGitRepoStatusCacheMutation = () => {
	const queryClient = useQueryClient();

	const invalidateCache = useDebouncedCallback(() => {
		void queryClient.invalidateQueries({ queryKey: documentQueryKeys.list.cache() });
	}, 100);

	return useMutation({
		mutationKey: gitQueryKeys.repo.status.update(),

		mutationFn: async ({
			documentId,
			status,
		}: {
			documentId: string;
			status: GitRepoStatus;
		}) => {
			if (!status.isRepo) {
				// If the document is not a repo, we don't need to cache the status
				return;
			}

			const db = await getDb();

			const query = sql`
				INSERT INTO documents_status
					(documentId, isClean, ahead, behind, branch, 'commit', staged, files)
				VALUES
					(${documentId}, ${status.isClean}, ${status.ahead}, ${status.behind}, ${status.branch}, ${status.commit}, jsonb(${JSON.stringify(status.staged)}), jsonb(${JSON.stringify(status.files)}))
				ON CONFLICT (documentId) DO UPDATE SET
					isClean = ${status.isClean},
					ahead = ${status.ahead},
					behind = ${status.behind},
					branch = ${status.branch},
					'commit' = ${status.commit},
					staged = jsonb(${JSON.stringify(status.staged)}),
					files = jsonb(${JSON.stringify(status.files)})
			`;

			await db?.execute(query.sql, query.values);
		},

		/*
		 * Invalidate the document list cache when the mutation succeeds
		 */
		onSuccess: (_, { documentId, status }) => {
			void queryClient.setQueryData(gitQueryKeys.repo.status.cache(documentId), status);
			invalidateCache();
		},
	});
};
