import { useQuery } from "@tanstack/react-query";
import { invoke } from "@tauri-apps/api/core";
import { isEmpty } from "lodash";

import { type GitRepoHistory, gitQueryKeys } from ".";
import { getDocumentDirPath } from "../filesystem";
import { useRouteParams } from "../route";
import { LOCAL_WORKSPACE_NAME } from "../workspaces";

export const useGitRepoHistoryQuery = (args?: { workspaceName: string; documentId: string }) => {
	const routeParams = useRouteParams();

	const { workspaceName = routeParams.workspaceName, documentId = routeParams.documentId } =
		args ?? {};

	const query = useQuery({
		staleTime: 1000 * 60 * 5, // 5 minutes
		enabled: workspaceName === LOCAL_WORKSPACE_NAME && !isEmpty(documentId),
		queryKey: [...gitQueryKeys.all(), "history"],
		queryFn: async () => {
			const path = await getDocumentDirPath(documentId);

			const history = await invoke<GitRepoHistory>("repo_history", { path });

			console.log("got history", history);

			return history;
		},
	});

	return query;
};
