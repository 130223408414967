import { useWorkspaceListQuery } from ".";

export const useWorkspaceQuery = ({ workspaceName }: { workspaceName: string }) => {
	const query = useWorkspaceListQuery();

	return {
		...query,
		data: query.data?.find((w) => w.name === workspaceName),
	};
};
