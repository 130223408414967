import router from "next/router";
import {
	type FC,
	type PropsWithChildren,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";

import { isDesktopAppMode } from "~/auth/interop/common";
import { useResponsive } from "~/components/canvas/hooks/useResponsive";
import { PROPERTIES_PANE_STATE_PARAM } from "~/components/navigation/constants";
import {
	type PropertiesPaneState,
	type PropertiesPaneStateValues,
	isPane,
} from "~/store/canvas/types";
import { LOCAL_STORAGE_KEYS, PANE_IDS, getItemById, setItemById } from "~/utils/storage";

import { PropertiesPaneContext, type State } from "./context";

const PULSE_DURATION = 750; // intentionally shorter than the 1s pulse animation
const DEFAULT_PANE_STATE_WRITE: PropertiesPaneState = { pane: undefined };
const DEFAULT_PANE_STATE_READ: PropertiesPaneState = { pane: undefined };

export const PropertiesPaneProvider: FC<PropsWithChildren> = ({ children }) => {
	const isDesktopApp = isDesktopAppMode();
	const hasWritePermissions = isDesktopApp;
	const [paneState, _setPaneState] = useState<PropertiesPaneState>(
		hasWritePermissions ? DEFAULT_PANE_STATE_WRITE : DEFAULT_PANE_STATE_READ
	);

	const [paneTogglesState, setPaneTogglesState] = useState<
		Record<(typeof PropertiesPaneStateValues)[number], boolean>
	>({
		components: false,
		cellDependencies: false,
		publish: false,
	});
	// Ref is needed to avoid races between the state and the setPane function
	const paneTogglesStateRef = useRef(paneTogglesState);
	paneTogglesStateRef.current = paneTogglesState;
	const [pulsedPane, setPulsedPane] = useState<PropertiesPaneState>({ pane: undefined });
	const { isSm } = useResponsive();
	const pulseTimeoutRef = useRef<ReturnType<typeof setTimeout>>();

	const paneEnabledState: Record<(typeof PropertiesPaneStateValues)[number], boolean> = useMemo(
		() => ({
			components: hasWritePermissions,
			cellDependencies: true,
			publish: true,
		}),
		[hasWritePermissions]
	);

	const paneStateRef = useRef(paneState);
	paneStateRef.current = paneState;

	const setPaneState = useCallback(
		(p: PropertiesPaneState) => {
			if (isSm) {
				setItemById(LOCAL_STORAGE_KEYS.PANES, PANE_IDS.PROPERTIES_PANE, p, 0);
			}

			//
			//
			// Set the state.
			//

			_setPaneState(p);
		},
		[isSm]
	);

	const closePane = useCallback(() => {
		setPaneState({ pane: undefined });
	}, [setPaneState]);

	const setPane = useCallback(
		(p: PropertiesPaneState) => {
			const { pane } = p;
			if (pane === paneState.pane) {
				setPulsedPane(p);
				pulseTimeoutRef.current = setTimeout(() => {
					setPulsedPane({ pane: undefined });
				}, PULSE_DURATION);
			}

			setPaneState(p);
		},
		[paneState.pane, setPaneState]
	);

	const togglePane = useCallback(
		(p: PropertiesPaneState) => {
			const { pane } = p;
			const isPaneCurrent = paneStateRef.current.pane === pane;

			setPaneState(isPaneCurrent ? { pane: undefined } : p);
		},
		[setPaneState]
	);

	const togglePaneToggle = useCallback(
		(pane: (typeof PropertiesPaneStateValues)[number]) => {
			setPaneTogglesState((prev) => {
				const newToggleState = !prev[pane];

				if (newToggleState) {
					setPane({ pane });
				} else if (paneState.pane === pane) {
					// Close if currently open and setting state to false
					closePane();
				}

				return {
					...prev,
					[pane]: newToggleState,
				};
			});
		},
		[paneState.pane, closePane, setPane]
	);

	const context: State = useMemo(
		() => ({
			paneState,
			closePane,
			setPane,
			togglePane,
			paneEnabledState,
			pulsedPane,
			paneTogglesState,
			togglePaneToggle,
		}),
		[
			paneState,
			closePane,
			setPane,
			togglePane,
			paneEnabledState,
			pulsedPane,
			togglePaneToggle,
			paneTogglesState,
		]
	);

	useEffect(() => {
		// On mount, set the default pane state for sm screens and above
		if (isSm) {
			const localStoragePaneState = getItemById<PropertiesPaneState>(
				LOCAL_STORAGE_KEYS.PANES,
				PANE_IDS.PROPERTIES_PANE
			);

			const { [PROPERTIES_PANE_STATE_PARAM]: paneToOpen } = router.query;
			// We don't want to close the pane if paneToOpen = undefined, so ignore undefined here
			if (paneToOpen && isPane(paneToOpen)) {
				setPane({ pane: paneToOpen });
			} else if (
				localStoragePaneState?.value.pane &&
				paneEnabledState[localStoragePaneState.value.pane]
			) {
				setPane(localStoragePaneState?.value);
			}
		}

		return () => {
			clearTimeout(pulseTimeoutRef.current);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<PropertiesPaneContext.Provider value={context}>{children}</PropertiesPaneContext.Provider>
	);
};
