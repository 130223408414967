export { useAddGitRemoteMutation } from "./useAddGitRemoteMutation";
export { useCloneRepoMutation } from "./useCloneRepoMutation";
export { useCommitToRepoMutation } from "./useCommitToRepoMutation";
export { useGitDiffQuery } from "./useGitDiffQuery";
export { useGitDiffSummaryQuery } from "./useGitDiffSummaryQuery";
export { useGitFetchMutation } from "./useGitFetchMutation";
export { useGitMomentConfigQuery } from "./useGitMomentConfigQuery";
export { useGitRemotesQuery } from "./useGitRemotesQuery";
export { useGitRepoDiffSummaryCacheMutation } from "./useGitRepoDiffSummaryCacheMutation";
export { useGitRepoHistoryQuery } from "./useGitRepoHistoryQuery";
export { useGitRepoStatusCacheMutation } from "./useGitRepoStatusCacheMutation";
export { useGitRepoStatusQuery } from "./useGitRepoStatusQuery";
export type { FileStatusResult } from "./useGitRepoStatusQuery";
export { useGitSetMomentConfigKeyMutation } from "./useGitSetMomentConfigKeyMutation";
export { useGitShowQuery } from "./useGitShowQuery";
export { useGitUnpublishedChanges } from "./useGitUnpublishedChanges";
export { usePullRepoMutation } from "./usePullRepoMutation";
export { usePushRepoMutation } from "./usePushRepoMutation";
export { useUpdateGitRepoIndexMutation } from "./useUpdateGitRepoIndexMutation";

export * from "./types";

export const parsePageIdFromPath = (path: string) => {
	return path.split("/").at(-2)?.replace(/page_/, "");
};

export const gitQueryKeys = {
	all: () => ["git"],

	init: () => [...gitQueryKeys.all(), "init"],
	clone: () => [...gitQueryKeys.all(), "clone"],

	config: {
		all: () => [...gitQueryKeys.all(), "config"],
		moment: {
			all: () => [...gitQueryKeys.config.all(), "moment"],
			get: () => [...gitQueryKeys.config.moment.all(), "moment", "get"],
			set: () => [...gitQueryKeys.config.moment.all(), "moment", "set"],
		},
	},

	repo: {
		all: () => [...gitQueryKeys.all(), "repo"],
		base: (documentId: string) => [...gitQueryKeys.repo.all(), documentId],

		diff: {
			all: () => [...gitQueryKeys.repo.all(), "diff"],
			base: (documentId: string) => [...gitQueryKeys.repo.diff.all(), documentId],

			summary: {
				all: () => [...gitQueryKeys.repo.diff.all(), "summary"],
				base: (documentId: string) => [...gitQueryKeys.repo.diff.summary.all(), documentId],

				cache: (documentId: string) => [
					...gitQueryKeys.repo.diff.summary.base(documentId),
					"summary",
					"cache",
				],
				disk: (documentId: string) => [
					...gitQueryKeys.repo.diff.summary.base(documentId),
					"summary",
					"disk",
				],

				update: () => [...gitQueryKeys.repo.diff.summary.all(), "update"],
			},
		},

		status: {
			all: () => [...gitQueryKeys.all(), "status"],
			base: (documentId: string) => [...gitQueryKeys.repo.base(documentId), "status"],

			cache: (documentId: string) => [...gitQueryKeys.repo.status.base(documentId), "cache"],
			disk: (documentId: string) => [...gitQueryKeys.repo.status.base(documentId), "disk"],

			update: () => [...gitQueryKeys.repo.status.all(), "update"],
		},

		remotes: {
			all: () => [...gitQueryKeys.all(), "remotes"],
			base: (documentId: string) => [...gitQueryKeys.repo.all(), documentId, "remotes"],
			list: (documentId: string) => [...gitQueryKeys.repo.remotes.base(documentId), "list"],

			add: () => [...gitQueryKeys.repo.remotes.all(), "add"],
		},

		branches: {
			all: () => [...gitQueryKeys.all(), "branches"],
			base: (documentId: string) => [...gitQueryKeys.repo.all(), documentId, "branches"],
			list: (documentId: string) => [...gitQueryKeys.repo.branches.base(documentId), "list"],
		},
	},
};
