import { useMutation } from "@tanstack/react-query";

import { getGiteaApiUrl } from "~/utils/gitea";

import { useAddGitRemoteMutation } from "./useAddGitRemoteMutation";

export const GIT_PUBLISHING_BRANCH_NAME = "main";
export const GIT_PUBLISHING_REMOTE_NAME = "moment";

export const useAddGitPublishingRemoteMutation = () => {
	const { mutateAsync: addGitRemote } = useAddGitRemoteMutation();

	const mutation = useMutation({
		mutationFn: async (args: { workspaceName: string; documentId: string }) => {
			const { workspaceName, documentId } = args;
			const remoteUrl = `${getGiteaApiUrl()}/git/${workspaceName}/${documentId}.git`;

			await addGitRemote({ documentId, remote: GIT_PUBLISHING_REMOTE_NAME, url: remoteUrl });
		},
	});

	return mutation;
};
