import { subMinutes } from "date-fns";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import { createQuery } from "react-query-kit";
import { useAsync } from "react-use";
import SuperJSON from "superjson";
import { z } from "zod";

import { useAuth } from "~/auth/useAuth";
import { getGiteaApiUrlWithoutProtocol, queryReposByWorkspace } from "~/utils/gitea";

import {
	type DocumentListItem,
	DocumentListItemSchema,
	documentQueryKeys,
	useUpsertDocumentWorkspacesCacheMutation,
} from ".";

const queryKey = (workspaceName: string) => documentQueryKeys.list.remote(workspaceName);
const dataCacheKey = (workspaceName: string) =>
	`moment_rq:${JSON.stringify(queryKey(workspaceName))}`;

export const clearRemoteDocumentsListCache = (workspaceName: string) =>
	localStorage.removeItem(dataCacheKey(workspaceName));

export const remoteDocumentsListQuery = (
	isAuthenticated: boolean,
	accessToken: string | undefined,
	workspaceName: string
) =>
	createQuery<DocumentListItem[]>({
		queryKey: queryKey(workspaceName),

		/*
		 * If the documents list is cached, we use it as the initial data
		 * We also set the initial data updated at to 5 minutes ago to immediately force a refetch
		 */
		initialData: SuperJSON.parse(localStorage.getItem(dataCacheKey(workspaceName)) ?? "[]"),
		initialDataUpdatedAt: subMinutes(new Date(), 5).getTime(),

		/*
		 * If the user is authenticated, we fetch the documents list from the remote server
		 */
		fetcher: async () => {
			if (!isAuthenticated || isEmpty(accessToken)) {
				throw new Error("missing_auth_token");
			}

			const repos = await queryReposByWorkspace(accessToken!, workspaceName);

			try {
				const documents = z.array(DocumentListItemSchema).parse(
					repos.map((repo) => ({
						id: repo.name,
						path: null,
						title: repo.moment_title,
						createdAt: repo.created_at,
						updatedAt: repo.updated_at,
						workspaces: [
							{
								id: `${getGiteaApiUrlWithoutProtocol()}/${workspaceName}`,
								name: workspaceName,
							},
						],
					}))
				);

				// Cache the documents list
				if (!isEmpty(documents)) {
					localStorage.setItem(
						dataCacheKey(workspaceName),
						SuperJSON.stringify(documents)
					);
				}

				return documents;
			} catch (error) {
				// If the documents list is invalid, we remove the data cache
				clearRemoteDocumentsListCache(workspaceName);

				throw error;
			}
		},

		/*
		 * Only fetch if the user is authenticated and the workspace name is provided
		 */
		enabled: isAuthenticated && !isEmpty(accessToken) && !isEmpty(workspaceName),

		/*
		 * Refetch every 5 minutes
		 */
		staleTime: 1000 * 60 * 5,
	});

export const useRemoteDocumentsListQuery = async (workspaceName: string) => {
	const { isAuthenticated, getAccessTokenSilently } = useAuth();

	const { mutateAsync: upsertDocumentWorkspacesCache } =
		useUpsertDocumentWorkspacesCacheMutation();

	const token = useAsync(async () => await getAccessTokenSilently());

	const query = remoteDocumentsListQuery(isAuthenticated, token.value, workspaceName)();

	useEffect(() => {
		if (query.data) {
			void Promise.all(
				query.data.map((document) => {
					const workspace = document.workspaces[0];

					if (!workspace) {
						return;
					}

					return upsertDocumentWorkspacesCache({
						documentId: document.id,
						workspaceId: workspace.id,
						action: "add",
					});
				})
			);
		}
	}, [query, workspaceName, upsertDocumentWorkspacesCache]);

	return query;
};
