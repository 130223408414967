import { z } from "zod";

import { WorkspaceSchema } from "../workspaces";

export const DocumentListItemSchema = z.object({
	id: z.string(),
	path: z.string().nullish(),
	title: z.string().default(""),
	createdAt: z.coerce.date(),
	updatedAt: z.coerce.date(),
	workspaces: z.array(WorkspaceSchema).default([]),
});
export type DocumentListItem = z.infer<typeof DocumentListItemSchema>;
