import type {
	File,
	Identifier,
	ImportDeclaration,
	ImportSpecifier,
	Node,
	StringLiteral,
} from "@babel/types";

type Opts<Obj> = Partial<{
	[Prop in keyof Obj]: Obj[Prop] extends Node
		? Node
		: Obj[Prop] extends Node[]
			? Node[]
			: Obj[Prop];
}>;

export function shallowEqual<T extends object>(actual: object, expected: T): actual is T {
	const keys = Object.keys(expected) as (keyof T)[];

	for (const key of keys) {
		if (
			// @ts-expect-error maybe we should check whether key exists first
			actual[key] !== expected[key]
		) {
			return false;
		}
	}

	return true;
}

export function isStringLiteral(
	node: Node | null | undefined,
	opts?: Opts<StringLiteral> | null
): node is StringLiteral {
	if (!node) {
		return false;
	}

	if (node.type !== "StringLiteral") {
		return false;
	}

	return opts == null || shallowEqual(node, opts);
}

export function isIdentifier(
	node: Node | null | undefined,
	opts?: Opts<Identifier> | null
): node is Identifier {
	if (!node) {
		return false;
	}

	if (node.type !== "Identifier") {
		return false;
	}

	return opts == null || shallowEqual(node, opts);
}

export function isImportDeclaration(
	node: Node | null | undefined,
	opts?: Opts<ImportDeclaration> | null
): node is ImportDeclaration {
	if (!node) {
		return false;
	}

	if (node.type !== "ImportDeclaration") {
		return false;
	}

	return opts == null || shallowEqual(node, opts);
}

export function isImportSpecifier(
	node: Node | null | undefined,
	opts?: Opts<ImportSpecifier> | null
): node is ImportSpecifier {
	if (!node) {
		return false;
	}

	if (node.type !== "ImportSpecifier") {
		return false;
	}

	return opts == null || shallowEqual(node, opts);
}

export function isFile(node: Node | null | undefined, opts?: Opts<File> | null): node is File {
	if (!node) {
		return false;
	}

	if (node.type !== "File") {
		return false;
	}

	return opts == null || shallowEqual(node, opts);
}
