import { type Attrs, type Node } from "prosemirror-model";
import { type Command, type Transaction } from "prosemirror-state";
import { findWrapping, liftTarget } from "prosemirror-transform";

import { schema } from "@moment/api-collab/prosemirror-schema";
import { DEFAULT_CELL_ID, createCellID } from "@moment/api-collab/prosemirror-utils";

import { makeTextCell } from "../helpers";

export type ConvertNodeOptions =
	| { nodeType: "blockquote" }
	| { nodeType: "paragraph"; setPlaceholderText?: boolean }
	| { nodeType: "heading"; attrs: { level: 1 | 2 | 3 } }
	| { nodeType: "code_block" }
	| { nodeType: "list_item"; attrs: { bullet: string; indent: number } }
	| { nodeType: "to_do_item"; attrs: { indent: number } };

/**
 * A partial ProseMirror `Command`, which *NEVER* calls `dispatch`. Allows for complex transactions
 * to be built up piecemeal.
 */
export type CommandHelper = (tr: Transaction) => ReturnType<Command>;

/**
 * Helper that converts one type of ProseMirror `Node` into another.
 */
export type ConvertNodeCommandHelper =
	| { conversionType: "invalid" }
	| { conversionType: "no-op" }
	| { conversionType: "valid"; addTransformToTransaction: CommandHelper };

/**
 * Creates a helper that converts one type of ProseMirror `Node` into another.
 */
export function makeConvertNodeCommandHelper(
	node: Node,
	pos: number,
	to: ConvertNodeOptions
): ConvertNodeCommandHelper {
	//
	// NO-OP: Do nothing if there is no change in the node type.
	//

	if (
		node.type.name === to.nodeType &&
		"attrs" in to &&
		to.attrs === node.attrs["level"] &&
		"setPlaceholderText" in to &&
		to.setPlaceholderText === node.attrs["placeholderText"]
	) {
		return { conversionType: "no-op" };
	}

	const cellId = node.attrs["id"] === DEFAULT_CELL_ID ? createCellID() : node.attrs["id"];

	switch (node.type) {
		case schema.nodes.doc:
		case schema.nodes.compute_cell:
		case schema.nodes.horizontal_rule:
		case schema.nodes.image:
		case schema.nodes.image_placeholder:
		case schema.nodes.hard_break:
			//
			// INVALID: Can't do a conversion if one of these nodes is in the selection.
			//

			return { conversionType: "invalid" };

		case schema.nodes.text_cell:
		case schema.nodes.text:
			//
			// NO-OP: Do nothing to these nodes specifically:
			//
			//   * `text_cell`: we're usually converting the contents of `text_cell` into something
			//     else, e.g., a `bullet_list` inside a `text_cell`.
			//   * `text`: a leaf node, can't be converted into anything.
			//

			return { conversionType: "no-op" };

		case schema.nodes.paragraph:
		case schema.nodes.heading:
		case schema.nodes.code_block:
			//
			// NODES that are "SIMPLE BLOCK" TYPES. These nodes types can be converted to each other
			// with `setBlockType`, or transformed into "WRAPPED BLOCK" types using `wrapIn`.
			//
			// These nodes all sit directly underneath `text_cell`, like so:
			//
			//     [doc]
			//       [text_cell]
			//         [paragraph | heading | code_block | etc.]
			//           "abc"<cursor>"def"
			//

			return {
				conversionType: "valid",
				addTransformToTransaction: (tr) => {
					//
					// Normalize to `paragraph`. If we do not do this, conversion from `heading` and
					// `code_block` -> `ordered_list`, `bullet_list`, and `blockquote` will fail.
					//

					tr.setBlockType(
						tr.mapping.map(pos),
						tr.mapping.map(pos + node.nodeSize),
						schema.nodes.paragraph,
						{
							id: DEFAULT_CELL_ID,
						}
					);

					//
					// Do transformation.
					//

					if (
						to.nodeType === "blockquote" ||
						to.nodeType === "list_item" ||
						to.nodeType === "to_do_item"
					) {
						// Wrap the existing block in a `blockquote`, `ordered_list`, or `bullet_list`.
						const range = tr.doc.resolve(tr.mapping.map(pos + 1)).blockRange();
						if (!range) {
							return false;
						}
						const wrapping = findWrapping(range, schema.nodes[to.nodeType], {
							id: cellId,
						});
						if (!wrapping) {
							return false;
						}

						tr.wrap(range, wrapping);
						return true;
					} else if (
						node.type === schema.nodes.paragraph &&
						to.nodeType === "paragraph"
					) {
						let newText;
						if (to.setPlaceholderText) {
							// pull out the text in the cell and set it as the placeholder
							newText = node.textContent;
						} else if (!node.textContent && node.attrs["placeholderText"]) {
							// if we are going back to normal paragraph and there is no text, set the content to the placeholder
							// TODO: This case is not currently working because we never select the right block.
							//       If you select the new line, it turns into a selection of the *next* block.
							//       But the code to theoretically do this is here.
							newText = node.attrs["placeholderText"];
						}

						// if we have new text we have to do a replace
						if (newText) {
							const params = to.setPlaceholderText
								? { id: cellId, nodeAttrs: { placeholderText: newText } }
								: { id: cellId, content: newText };
							const cells = makeTextCell(params);
							tr.replaceWith(
								tr.mapping.map(pos),
								tr.mapping.map(pos + node.nodeSize),
								cells
							);
							return true;
						}
					}

					// All other scenarios here
					tr.setBlockType(
						tr.mapping.map(pos),
						tr.mapping.map(pos + (node.nodeSize || 0)),
						schema.nodes[to?.nodeType],
						{
							placeholderText: "",
							id: cellId,
							...("attrs" in to ? to.attrs : undefined),
						}
					);
					return true;
				},
			};

		case schema.nodes.blockquote:
			return {
				conversionType: "valid",
				addTransformToTransaction: (tr) => {
					//
					// Normalize to `paragraph`. If we do not do this, conversion from `heading` and
					// `code_block` -> `ordered_list`, `bullet_list`, and `blockquote` will fail.
					//
					const nodeSize = node.nodeSize;

					const $from = tr.doc.resolve(tr.mapping.map(pos + 2));
					const $to = tr.doc.resolve(tr.mapping.map(pos + nodeSize - 2));
					const range = $from.blockRange($to);
					if (!range) {
						return false;
					}

					const target = liftTarget(range);
					if (target === null) {
						return false;
					}
					tr.lift(range, target);

					//
					// Do transformation.
					//

					if (
						to.nodeType === "blockquote" ||
						to.nodeType === "list_item" ||
						to.nodeType === "to_do_item"
					) {
						const $from = tr.doc.resolve(tr.mapping.map(pos + 1));
						const $to = tr.doc.resolve(tr.mapping.map(pos + nodeSize - 1));
						const range = $from.blockRange($to);
						const wrapping =
							range && findWrapping(range, schema.nodes[to.nodeType], { id: cellId });
						if (!wrapping) {
							return false;
						}
						tr.wrap(range, wrapping);
						return true;
					} else {
						tr.setBlockType(
							tr.mapping.map(pos),
							tr.mapping.map(pos + node.nodeSize),
							schema.nodes[to.nodeType],
							"attrs" in to ? { ...to.attrs, id: cellId } : { id: cellId }
						);
						return true;
					}
				},
			};

		case schema.nodes.list_item:
			return {
				conversionType: "valid",
				addTransformToTransaction: (tr) => {
					//
					// FIXME: This is pretty horrible, we just concat all `bullet_list` and
					// `ordered_list` into a single `paragraph` or `blockquote` or whatever. This
					// should really split each `list_item` up into its own thing, but fixing this
					// is sadly more time than we have alotted for this task.
					//

					let toInsert: Node;
					const newAttrs: Attrs = {
						id: cellId,
					};
					switch (to.nodeType) {
						case "paragraph":
							toInsert = schema.nodes.paragraph.create(
								newAttrs,
								schema.text(node.textContent)
							);
							break;

						case "blockquote":
							toInsert = schema.nodes.blockquote.create(
								newAttrs,
								schema.nodes.paragraph.create(null, schema.text(node.textContent))
							);
							break;

						case "code_block":
							toInsert = schema.nodes.code_block.create(
								newAttrs,
								schema.text(node.textContent)
							);
							break;

						case "heading":
							toInsert = schema.nodes.heading.create(
								"attrs" in to ? { ...to.attrs, ...newAttrs } : newAttrs,
								schema.text(node.textContent)
							);
							break;

						case "list_item":
							toInsert = schema.nodes.list_item.create(
								{ ...newAttrs, ...to.attrs },
								node.content
							);
							break;

						case "to_do_item":
							toInsert = schema.nodes.to_do_item.create(
								{ ...newAttrs, ...to.attrs },
								node.content
							);
					}

					tr.replaceWith(
						tr.mapping.map(pos),
						tr.mapping.map(pos + node.nodeSize),
						toInsert
					);
					return true;
				},
			};
	}

	return { conversionType: "invalid" };
}
