import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useRouter } from "next/router";

import { useUpdateDocumentMutation } from "../documents";
import { gitQueryKeys } from "../git";

export const useTogglePageArchivedMutation = ({
	workspaceName,
	documentId,
	currentPageId,
}: {
	workspaceName: string;
	documentId: string;
	currentPageId: string;
}) => {
	const router = useRouter();

	const queryClient = useQueryClient();
	const { updateAsync: updateDocument } = useUpdateDocumentMutation();

	const mutation = useMutation({
		mutationFn: async ({
			workspaceName,
			documentId,
			pageId,
			archived,
		}: {
			workspaceName: string;
			documentId: string;
			pageId: string;
			archived?: boolean;
		}) => {
			return updateDocument(
				{
					workspaceName,
					documentId,
				},
				(document) => {
					if (document?.pagesMetadata.length === 1) {
						throw new Error("Cannot archive when there is only one page");
					}

					const page = document?.pagesMetadata.find((page) => page.id === pageId);
					if (!page) {
						return document;
					}

					page.archived = archived !== undefined ? archived : !page.archived;

					// If we archived the first page, we need to set the second page to be the index
					// page.
					if (document?.pagesMetadata[0]?.id === pageId && document?.pagesMetadata[1]) {
						document.pagesMetadata[0].slug = document.pagesMetadata[0].id;
						document.pagesMetadata[1].slug = "index";
					}

					return document;
				}
			);
		},
		onSuccess: (result) => {
			// When we archive a page, we do NOT redirect to a new page, but continue to show the
			// archived page with a red banner explaining the status and offering the ability to
			// restore it. But, if we archive the first page, the page slug will change, so the
			// route needs to change too: from `index` to the `currentPageId`.
			if (result?.pagesMetadata[0]?.id === currentPageId) {
				void router.replace(`/d/${workspaceName}/${documentId}/${currentPageId}`);
			}

			void queryClient.invalidateQueries({
				queryKey: gitQueryKeys.repo.base(documentId),
			});
		},
	});

	return mutation;
};
