import { useQuery } from "@tanstack/react-query";
import { isEqual } from "lodash";
import { createQuery } from "react-query-kit";
import sql from "sql-template-tag";
import { z } from "zod";

import { isDesktopAppMode } from "~/auth/interop/common";

import { DocumentListItemSchema, documentQueryKeys, useDeleteDocumentCacheMutation } from ".";
import { DocumentMetadataSchema } from "../../../../api-collab/lib/api-types/documentMetadata";
import {
	exists,
	getDocumentDirPath,
	getDocumentFilePath,
	getDocumentsDirPath,
	readDir,
	readYamlFile,
} from "../filesystem";
import { getDb } from "../sql";
import { useUpsertDocumentCacheMutation } from "./useUpsertDocumentCacheMutation";

export const documentsListQuery = () =>
	createQuery({
		queryKey: documentQueryKeys.list.cache(),
		fetcher: async () => {
			const db = await getDb();

			const query = sql`
				SELECT documents.*, workspaces.id as workspaceId, workspaces.name as workspaceName
				FROM documents
				LEFT JOIN documents_workspaces ON documents.id = documents_workspaces.documentId
				LEFT JOIN workspaces ON documents_workspaces.workspaceId = workspaces.id
			`;

			const documents = await db?.select<
				{
					id: string;
					path: string;
					title: string;
					createdAt: Date;
					updatedAt: Date;
					workspaceId: string;
					workspaceName: string;
				}[]
			>(query.sql, query.values);

			return z.array(DocumentListItemSchema).parse(
				documents?.map((document) => ({
					id: document.id,
					path: document.path,
					title: document.title,
					createdAt: document.createdAt,
					updatedAt: document.updatedAt,
					workspaces: document.workspaceId
						? [{ id: document.workspaceId, name: document.workspaceName }]
						: [],
				}))
			);
		},
		enabled: isDesktopAppMode(),
	});

export const useDocumentsListQuery = () => {
	const { mutateAsync: upsertDocumentCache } = useUpsertDocumentCacheMutation();
	const { mutateAsync: deleteDocumentCache } = useDeleteDocumentCacheMutation();

	/*
	 * Fetch the documents list from the local filesystem
	 */
	const query = documentsListQuery()();

	/*
	 * Fetch the documents list from the remote server
	 */
	useQuery({
		queryKey: documentQueryKeys.list.disk(),
		queryFn: async () => {
			const dirPath = await getDocumentsDirPath();
			const dirList = await readDir(dirPath).then((list) =>
				list
					// We only care about directories that are not symlinks
					.filter((file) => file.isDirectory && !file.isSymlink)
					// We don't want to include hidden files
					.filter((file) => !file.name.startsWith("."))
			);

			const documentMetadatas: Record<string, z.infer<typeof DocumentMetadataSchema>> = {};

			await Promise.all(
				dirList.map(async (dir) => {
					try {
						const documentDirPath = await getDocumentDirPath(dir.name);
						const documentMetadataPath = await getDocumentFilePath(dir.name);

						// If the document metadata file doesn't exist, it's not a valid document
						if (!exists(documentMetadataPath)) {
							return;
						}

						const documentMetadata = await readYamlFile(documentMetadataPath).then(
							DocumentMetadataSchema.parse
						);

						documentMetadatas[documentDirPath] = documentMetadata;
					} catch (error) {
						console.error(
							documentQueryKeys.list.disk(),
							"unable to read or parse document metadata",
							{
								dir: dir.name,
								error,
							}
						);
					}
				})
			);

			const errors: string[] = [];
			Object.entries(documentMetadatas ?? {}).forEach(async ([documentDirPath, document]) => {
				try {
					const docOnDisk = {
						id: document.id,
						path: documentDirPath,
						title: document.title,
						createdAt: document.created,
						updatedAt: document.created,
					};

					const docInCache = query.data?.find((d) => d.id === document.id);

					// If the document is not in the cache, or if it is different from the document on disk,
					// upsert the document to the cache
					if (!docInCache || !isEqual(docInCache, docOnDisk)) {
						await upsertDocumentCache(docOnDisk);
					}
				} catch (error) {
					console.error("useDocumentsListQuery", "unable to upsert document to cache", {
						dir: documentDirPath,
						error,
					});

					errors.push(`${documentDirPath}: ${error}`);
				}
			});

			// Delete documents from the cache that are not on disk
			query.data
				?.filter((doc) => doc.path && !documentMetadatas[doc.path])
				.forEach(async (doc) => {
					await deleteDocumentCache({ id: doc.id });
				});

			if (errors.length > 0) {
				throw new Error(errors.join("\n"));
			}

			return documentMetadatas;
		},

		/*
		 * Re-scan on disk every 15 minutes
		 */
		staleTime: 1000 * 60 * 15,

		/*
		 * Only run if we were able to fetch the documents from cache, this is important
		 * because we need to compare data from the cache with data from the disk
		 */
		enabled: query.isSuccess,
	});

	return query;
};
