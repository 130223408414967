import { useQuery } from "@tanstack/react-query";
import { isEmpty } from "lodash";

import { useAuth } from "~/auth/useAuth";
import { getGiteaApiUrl } from "~/utils/gitea";

import { documentQueryKeys } from ".";
import { useRouteParams } from "../route";

export const useRemoteDocumentRepoExistsQuery = (args?: {
	workspaceName: string;
	documentId: string;
	retry?: boolean | number;
}) => {
	const routeParams = useRouteParams();
	const { getAccessTokenSilently } = useAuth();

	const {
		workspaceName = routeParams.workspaceName,
		documentId = routeParams.documentId,
		retry = true,
	} = args ?? {};

	const query = useQuery<boolean>({
		queryKey: documentQueryKeys.document.remoteRepoExists(workspaceName, documentId),

		queryFn: async () => {
			const token = await getAccessTokenSilently();
			if (!token) {
				throw new Error("Not authenticated");
			}

			let response: Response | undefined;
			try {
				response = await fetch(
					`${getGiteaApiUrl()}/api/v1/repos/${workspaceName}/${documentId}`,
					{ headers: { Authorization: "Bearer " + token } }
				);
			} catch (e) {
				if (e instanceof Error && e.message === "Not found") {
					return false;
				}
				throw e;
			}

			if (!response.ok) {
				return false;
			}

			return true;
		},
		retry,

		enabled: !isEmpty(workspaceName) && !isEmpty(documentId),
	});

	return query;
};
