import { useMutation } from "@tanstack/react-query";
import { invoke } from "@tauri-apps/api/core";

import { type DocumentMetadata, DocumentMetadataSchema } from "@moment/api-collab/api-types";
import { makeToast } from "@moment/design-system/Toast";

import { useAuth } from "~/auth/useAuth";
import { getDocumentDirPath, getDocumentFilePath, readYamlFile } from "~/data/filesystem";
import { getGiteaApiUrl, getGiteaApiUrlWithoutProtocol } from "~/utils/gitea";

import { gitQueryKeys } from ".";
import {
	useUpsertDocumentCacheMutation,
	useUpsertDocumentWorkspacesCacheMutation,
} from "../documents";
import { useUpsertWorkspaceCacheMutation } from "../workspaces";

export const useCloneRepoMutation = () => {
	const { getAccessTokenSilently } = useAuth();

	const { mutateAsync: upsertDocumentCache } = useUpsertDocumentCacheMutation();
	const { mutateAsync: upsertWorkspaceCache } = useUpsertWorkspaceCacheMutation();
	const { mutateAsync: upsertDocumentWorkspacesCache } =
		useUpsertDocumentWorkspacesCacheMutation();

	const mutation = useMutation({
		mutationKey: gitQueryKeys.clone(),
		// This mutation still uses workspaceName and documentId since that's how they're keyed on remote
		mutationFn: async (args: { workspaceName: string; documentId: string }) => {
			const { workspaceName, documentId } = args;

			// TODO(tauri): replace with middleware
			const token = await getAccessTokenSilently();

			const documentDirPath = await getDocumentDirPath(documentId);

			const remoteUrl = `${getGiteaApiUrl()}/git/${workspaceName}/${documentId}.git`;

			console.debug("useCloneRepoMutation:invoke:clone", {
				path: documentDirPath,
				remoteUrl,
				token,
			});

			const result = await invoke("clone", {
				path: documentDirPath,
				remoteUrl,
				token,
			});

			console.debug("useCloneRepoMutation:invoke:clone:result", { result });

			return {
				path: documentDirPath,
			};
		},
		onSuccess: async ({ path }, { workspaceName, documentId }) => {
			const documentFile = await readYamlFile<DocumentMetadata>(
				await getDocumentFilePath(documentId)
			);

			const document = DocumentMetadataSchema.parse(documentFile);

			if (!document) {
				throw new Error("Document not found");
			}

			await upsertDocumentCache({
				id: documentId,
				path,
				title: document.title,
				createdAt: document.created,
				updatedAt: document.created,
			});

			const { id: workspaceId } = await upsertWorkspaceCache({
				host: getGiteaApiUrlWithoutProtocol(),
				slug: workspaceName,
				name: workspaceName,
			});

			await upsertDocumentWorkspacesCache({
				documentId,
				workspaceId,
				action: "add",
			});
		},
		onError: (error) => {
			console.error("useCloneRepoMutation:invoke:clone:error", { error });
			makeToast({
				message: "Failed to clone repository",
				variant: "error",
			});
		},
	});

	return mutation;
};
