import { useAuth0 } from "@auth0/auth0-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { makeToast } from "@moment/design-system/Toast";

import { useLogger } from "~/components/canvas/hooks/useLogger";
import { createRepo, getGiteaApiUrlWithoutProtocol } from "~/utils/gitea";

import { documentQueryKeys, useUpsertDocumentWorkspacesCacheMutation } from ".";
import { gitQueryKeys } from "../git";
import { useUpsertWorkspaceCacheMutation } from "../workspaces";

export const useCreateRemoteDocumentRepoMutation = () => {
	const logger = useLogger("useCreateRemoteDocumentMutation");
	const { getAccessTokenSilently } = useAuth0();
	const queryClient = useQueryClient();

	const { mutateAsync: upsertWorkspaceCache } = useUpsertWorkspaceCacheMutation();
	const { mutateAsync: upsertDocumentWorkspacesCache } =
		useUpsertDocumentWorkspacesCacheMutation();

	const mutation = useMutation({
		mutationKey: documentQueryKeys.create(),
		mutationFn: async (args: { workspaceName: string; documentId: string }) => {
			const { workspaceName, documentId } = args;
			const token = await getAccessTokenSilently();

			return await createRepo(token, workspaceName, documentId);
		},
		async onSuccess(_, { workspaceName, documentId }) {
			void Promise.all([
				queryClient.invalidateQueries({ queryKey: gitQueryKeys.all() }),
				queryClient.invalidateQueries({
					queryKey: documentQueryKeys.document.remoteRepoExists(
						workspaceName,
						documentId
					),
				}),
				queryClient.invalidateQueries({
					queryKey: documentQueryKeys.list.remote(workspaceName),
				}),
			]);

			/*
			 * Add the document to the workspace cache
			 */
			const { id: workspaceId } = await upsertWorkspaceCache({
				host: getGiteaApiUrlWithoutProtocol(),
				slug: workspaceName,
				name: workspaceName,
			});

			await upsertDocumentWorkspacesCache({
				workspaceId,
				documentId,
				action: "add",
			});
		},
		async onError(error) {
			logger.error("Failed to create remote document", { error });
			makeToast({
				message: `Failed to create remote document${error.message ? `: ${error.message}` : ""}`,
				variant: "error",
			});
		},
	});

	return mutation;
};
