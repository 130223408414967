import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import { isEqual } from "lodash";
import sql from "sql-template-tag";
import { z } from "zod";

import { getGiteaApiUrlWithoutProtocol, getWorkspaces } from "~/utils/gitea";

import { useUpsertWorkspaceCacheMutation, workspaceQueryKeys } from ".";
import { getDb } from "../sql";

const WorkspaceSchema = z.object({
	id: z.string(),
	name: z.string(),
	host: z.string().default(getGiteaApiUrlWithoutProtocol),
	slug: z.string(),
});
type Workspace = z.infer<typeof WorkspaceSchema>;

export const useWorkspaceListQuery = () => {
	const { isAuthenticated, getAccessTokenSilently } = useAuth0();

	const { mutateAsync: upsertWorkspace } = useUpsertWorkspaceCacheMutation();

	/**
	 * Fetch the local workspaces from the database
	 */
	const localWorkspacesQuery = useQuery<Workspace[]>({
		queryKey: workspaceQueryKeys.list.cache(),
		queryFn: async () => {
			const db = await getDb();

			const query = sql`
				SELECT * FROM workspaces
			`;

			const workspaces = await db?.select(query.sql, query.values);

			return z.array(WorkspaceSchema).parse(workspaces);
		},
	});

	/**
	 * Fetch the remote workspaces from the server
	 */
	const remoteWorkspacesQuery = useQuery<Workspace[]>({
		queryKey: workspaceQueryKeys.list.remote(),
		queryFn: async () => {
			const token = await getAccessTokenSilently();

			// We cannot fetch remote workspaces without a token
			if (!isAuthenticated || token === undefined) {
				return [];
			}

			const data = await getWorkspaces(token);
			const host = getGiteaApiUrlWithoutProtocol();

			const workspaces = z.array(WorkspaceSchema).parse(
				data.map((workspace) => ({
					id: `${host}/${workspace.display_name}`,
					name: workspace.display_name,
					host,
					slug: workspace.display_name,
				}))
			);

			workspaces.forEach(async (workspace) => {
				const remoteWorkspace = {
					id: `${workspace.host}/${workspace.slug}`,
					name: workspace.name,
					host: workspace.host,
					slug: workspace.slug,
				};
				const workspaceInCache = localWorkspacesQuery.data?.find(
					(w) => w.id === workspace.id
				);

				// If the workspace is not in the cache, or if it is different from the remote workspace,
				// upsert the workspace to the cache
				if (!workspaceInCache || !isEqual(workspaceInCache, remoteWorkspace)) {
					await upsertWorkspace(remoteWorkspace);
				}
			});

			return workspaces;
		},

		/*
		 * Re-fetch the remote workspaces every 5 minutes
		 */
		staleTime: 1000 * 60 * 5,

		/*
		 * Only fetch the remote workspaces if the user is authenticated and the local workspaces have been fetched
		 *
		 * This is important because we need to ensure that the local workspaces are in the cache before
		 * we try to compare them with the remote workspaces that exist on the server
		 */
		enabled: isAuthenticated && localWorkspacesQuery.isSuccess,
	});

	return {
		/*
		 * Return data from localWorkspacesQuery because remoteWorkspaceQuery data will be populated into the cache
		 */
		...localWorkspacesQuery,

		/*
		 * Display a loading state if either local or remote workspaces are pending
		 */
		isPending: localWorkspacesQuery.isPending || remoteWorkspacesQuery.isPending,
	};
};
