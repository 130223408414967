/**
 * Tokens returned by the OAuth2 authorization server.
 */
export interface OAuthTokenPayload {
	idToken?: IdToken;
	accessToken?: string;
	refreshToken?: string;
}

/**
 * OAuth2 Connect ID token. The authorization server returns this token to provide information about
 * an authenticated user.
 */
export interface IdToken {
	given_name: string;
	family_name: string;
	nickname: string;
	name: string;
	picture: string;
	locale: string;
	updated_at: string;
	iss: string;
	sub: string;
	aud: string;
	iat: number;
	exp: number;
	claims: {
		[key: string]: string;
	};
	[key: string]: unknown;
}

export interface Auth0Info {
	auth0Domain: string;
	auth0ClientId: string;
	audience: string;
}

const LOCAL_BASE_URL_DEFAULT = "http://localhost:3333";
const STAGING_BASE_URL_DEFAULT = "https://app-staging.moment.dev";
const DESKTOP_APP_SCHEME = "moment-app://-";

const currentBaseUrl =
	typeof window === "undefined"
		? undefined
		: `${window.location.protocol}//${window.location.host}`;

export const isDesktopAppMode = () => {
	return process.env.MOMENT_DESKTOP_APP === "true";
};

export const isServiceWorkerDisabled = () => {
	return process.env.DISABLE_SERVICE_WORKER === "true";
};

export const getWebBaseURL = (env?: MomentAppEnv): string => {
	if (isDesktopAppMode()) {
		return DESKTOP_APP_SCHEME;
	}

	// On local, force to local urls (instead of passed env)
	if (currentBaseUrl === LOCAL_BASE_URL_DEFAULT) {
		return LOCAL_BASE_URL_DEFAULT;
	}

	switch (env) {
		case "staging":
			// Allow current urls for vercel deploys, otherwise use web-staging
			return currentBaseUrl ?? STAGING_BASE_URL_DEFAULT;
		case "production":
			return "https://docs.moment.dev";
		case "local":
		case "testing":
		default:
			return currentBaseUrl ?? LOCAL_BASE_URL_DEFAULT;
	}
};

const getLoginRedirectURL = (env: MomentAppEnv): string => {
	return `${getWebBaseURL(env)}/login`;
};

const getLogoutRedirectURL = (env: MomentAppEnv): string => {
	return `${getWebBaseURL(env)}/logout`;
};

const getUserHMACURL = (env: MomentAppEnv): string => {
	return `${getWebBaseURL(env)}/api/v1/user/hmac`;
};

export const getAuthURLs = (env: MomentAppEnv) => {
	const loginRedirectUri = getLoginRedirectURL(env);
	const logoutRedirectUri = getLogoutRedirectURL(env);
	const userHmacUrl = getUserHMACURL(env);

	return {
		loginRedirectUri,
		logoutRedirectUri,
		userHash: userHmacUrl,
	};
};
