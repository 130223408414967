export { useCreateDocumentMutation } from "./useCreateDocumentMutation";
export { useDeleteDocumentCacheMutation } from "./useDeleteDocumentCacheMutation";
export { useDeleteDocumentMutation } from "./useDeleteDocumentMutation";
export { useDocumentQuery } from "./useDocumentQuery";
export { documentsListQuery, useDocumentsListQuery } from "./useDocumentsListQuery";
export {
	remoteDocumentsListQuery,
	useRemoteDocumentsListQuery,
} from "./useRemoteDocumentsListQuery";
export { useRenameDocumentMutation } from "./useRenameDocumentMutation";
export { useToggleDocumentPinnedMutation } from "./useToggleDocumentPinnedMutation";
export { useUpdateDocumentMutation } from "./useUpdateDocumentMutation";
export { useUpsertDocumentCacheMutation } from "./useUpsertDocumentCacheMutation";
export { useUpsertDocumentWorkspacesCacheMutation } from "./useUpsertDocumentWorkspacesCacheMutation";
export { useRevalidateDocumentMutation } from "./useRevalidateDocumentMutation";

export * from "./types";

export const documentQueryKeys = {
	base: () => ["documents"],
	list: {
		all: () => [...documentQueryKeys.base(), "list"],

		cache: () => [...documentQueryKeys.list.all(), "cache"],
		remote: (workspaceName: string) => [
			...documentQueryKeys.list.all(),
			"remote",
			workspaceName,
		],
		disk: () => [...documentQueryKeys.list.all(), "disk"],
	},

	cache: {
		base: () => [...documentQueryKeys.base(), "cache"],
		upsert: () => [...documentQueryKeys.cache.base(), "upsert"],
		delete: () => [...documentQueryKeys.cache.base(), "delete"],
	},

	document: {
		base: (workspaceName: string, documentId: string) => [
			...documentQueryKeys.base(),
			workspaceName,
			documentId,
		],

		content: (workspaceName: string, documentId: string) => [
			...documentQueryKeys.document.base(workspaceName, documentId),
		],

		remoteRepoExists: (workspaceName: string, documentId: string) => [
			...documentQueryKeys.document.base(workspaceName, documentId),
			"remote-repo-exists",
		],

		revalidate: () => [...documentQueryKeys.base(), "revalidate"],
	},

	create: () => [...documentQueryKeys.base(), "create"],
	update: () => [...documentQueryKeys.base(), "update"],
	delete: () => [...documentQueryKeys.base(), "delete"],
	rename: () => [...documentQueryKeys.base(), "rename"],
};
