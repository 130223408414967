import { type Auth0ContextInterface, type User as Auth0User, useAuth0 } from "@auth0/auth0-react";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { useDebounce } from "react-use";
import { create } from "zustand";

import { setUserProperty } from "@moment/logging";

import { documentQueryKeys } from "~/data/documents";
import { workspaceQueryKeys } from "~/data/workspaces";
import { type User, auth0UserIdToGiteaUserId } from "~/models/user";

import { isDesktopAppMode } from "./interop/common";
import { getLoginAction } from "./loginAction";
import { getLogoutAction } from "./logoutAction";
import { clearRedirectStore } from "./redirectStore";

/**
 * Custom hook provider for all auth-related functionality.
 */

/**
 * Context interface for the auth information.
 *
 * This context is very similar to the Auth0Context that that the Auth0 library provides,
 * with some additional overridden properties to meet our implementation. With the
 * exception of `user`, all overridden properties should be functionally identical
 * so that the Auth0 documentation is still usable.
 *
 * Available properties:

 * State booleans (e.g. yes/no):
 * * isAuthenticated
 * * isAuthenticatedOrLocal
 * * isLoading
 * * isLocalApp

 * User data:
 * * user
 * * _Auth0User <-- use this one only if truly necessary

 * Token promises (consider adding anything needed from these as well-named getters):
 * * getAccessTokenSilently
 * * getIdTokenClaims

 * Auth workflow funcs. you shouldn't need these if you aren't implementing auth code.
 * * error
 * * getAccessTokenWithPopup
 * * loginWithRedirect
 * * loginWithPopup
 * * logout
 * * handleRedirectCallback
*/
export interface AuthContext
	extends Omit<
		Auth0ContextInterface,
		"user" | "loginWithRedirect" | "logout" | "getAccessTokenSilently"
	> {
	/**
	 * The Moment definition of a User. If for some reason you need the Auth0
	 * view of a user, it can be found as _Auth0User.
	 */
	user: User | undefined;

	/**
	 * Request an up-to-date, valid access token. This is called "silently"
	 * as in the access token request process itself is completely invisible
	 * to the user (there are visible ways to perform it as well).
	 *
	 * For more information on how this function works, view the documentation
	 * for the auth0-react library.
	 *
	 * @param {boolean} [opts.promptForLogin=false] - use true in order to automatically
	 * launch a login prompt if the user's authentication appears to be out of date.
	 * The mechanics that trigger this can be opaque, and confusing.
	 * A user may still be successfully authenticated unable to receive
	 * a new valid authentication token if their refresh token is invalid.
	 * This flag is therefore kind of aggressive and fundamentally disruptive
	 * to the user, so use it carefully.
	 * @param {string} opts.redirectPath - if promptForLogin is true, also pass
	 * this value to send the user to a specific page after successful login. Typically
	 * this means using `router.asPath` in order to redirect the user back to the current
	 * page. If this is unset, the user will be sent to `/docs` after login.
	 */
	getAccessTokenSilently: (opts?: {
		promptForLogin?: boolean;
		redirectPath?: string;
	}) => Promise<string | undefined>;

	isAuthenticatedOrLocal: boolean;
	isLocalApp: boolean;

	loginWithRedirect: (opts: { redirectPath?: string }) => Promise<void>;
	logoutWithRedirect: (redirectPath?: string) => Promise<void>;

	_Auth0User?: Auth0User;
}

const isLocalApp = isDesktopAppMode();

/**
 * This state is used to track the auth state of the user. It is using Zustand so that the state is shared between
 * references to the `useAuth` hook.
 */
const useKnownAuthState = create<{
	knownIsAuthenticated: boolean;
	setKnownIsAuthenticated: (isAuthenticated: boolean) => void;
}>((set) => ({
	knownIsAuthenticated: false,
	setKnownIsAuthenticated: (isAuthenticated: boolean) =>
		set({ knownIsAuthenticated: isAuthenticated }),
}));

/**
 * Use the Auth0 library to get the auth state and actions.
 *
 * @returns The auth context.
 */
export const useAuth = (): AuthContext => {
	const queryClient = useQueryClient();

	const {
		getAccessTokenSilently,
		isAuthenticated,
		loginWithRedirect,
		logout,
		user,
		...moreAuth0
	} = useAuth0();

	const isAuthenticatedOrLocal = useMemo(() => isAuthenticated || isLocalApp, [isAuthenticated]);
	const mappedUser = useMemo(() => mapUser(user), [user]);

	const { knownIsAuthenticated, setKnownIsAuthenticated } = useKnownAuthState();

	/*
	 * We debounce the setting of the known auth state to avoid spamming the callbacks. This state changes frequently as the
	 * auth0 library checks auth state
	 */
	useDebounce(
		() => {
			const hasChanged = knownIsAuthenticated !== isAuthenticated;

			if (!hasChanged) {
				return;
			}

			setKnownIsAuthenticated(isAuthenticated);
			clearRedirectStore();

			if (isAuthenticated) {
				if (mappedUser) {
					setUserProperty("email", mappedUser.email);
					setUserProperty("isMoment", mappedUser.isMomentEmployee);
					setUserProperty("id", mappedUser.id);
					setUserProperty("name", mappedUser.name);
				}
			}
		},
		100,
		[isAuthenticated, knownIsAuthenticated, mappedUser, setKnownIsAuthenticated]
	);

	const loginAction = useCallback(
		(opts: { redirectPath?: string }) => {
			const loginAction = getLoginAction(loginWithRedirect, {
				isLocalApp,
			});

			return loginAction(opts.redirectPath ?? "/docs");
		},
		[loginWithRedirect]
	);

	const logoutAction = useCallback(
		(redirectPath?: string) => {
			const logoutAction = getLogoutAction(logout, {
				isLocalApp,
			});

			void Promise.all([
				queryClient.invalidateQueries({
					queryKey: workspaceQueryKeys.list.all(),
				}),
				queryClient.invalidateQueries({
					queryKey: documentQueryKeys.list.all(),
				}),
			]);

			return logoutAction(redirectPath ?? "/docs");
		},
		[logout, queryClient]
	);

	const getAccessToken = useCallback(
		(
			opts: {
				promptForLogin?: boolean;
				redirectPath?: string;
			} = {}
		) => {
			return getAccessTokenSilently().catch((e) => {
				if (e.error === `invalid_grant` && opts.promptForLogin) {
					console.log(`Login was invalid, redirecting to Login page`);
					void loginAction({ redirectPath: opts.redirectPath });
				}
				return Promise.reject(e);
			});
		},
		[getAccessTokenSilently, loginAction]
	);

	return {
		getAccessTokenSilently: getAccessToken,

		isAuthenticated,
		isAuthenticatedOrLocal,
		isLocalApp,

		loginWithRedirect: loginAction,
		logoutWithRedirect: logoutAction,

		user: mappedUser,
		_Auth0User: user,

		...moreAuth0,
	};
};

// Maps the Auth0User to our existing User interface.
const mapUser = (user: Auth0User | undefined) => {
	if (!user) {
		return undefined;
	}

	const id = user.sub;
	const name = user.name;
	const email = user.email;

	const giteaUserId = user.sub ? auth0UserIdToGiteaUserId(user.sub) : "";
	const nickname = user.nickname || "";
	const givenName = user.given_name || "";
	const familyName = user.family_name || "";
	const profilePicture = user.picture || "";
	const updatedAt = user.updated_at || "";
	const isMomentEmployee = user["http://api.moment.dev/isMoment"] || false;
	const publicEmail = user["http://api.moment.dev/public_email"] || email;

	const organizationID = "";
	const alternateIDs = null;

	if (!(id && name && email)) {
		console.log(`Auth0 returned a user but expected properties are missing`, {
			id,
			name,
			email,
		});
		return undefined;
	}

	const mappedUser: User = {
		id,
		name,
		email,
		publicEmail,
		giteaUserId,
		nickname,
		givenName,
		familyName,
		profilePicture,
		updatedAt,
		organizationID,
		alternateIDs,
		isMomentEmployee,
	};

	return mappedUser;
};

export const isTokenError = (e) => {
	return (
		e instanceof Error &&
		(("message" in e && e.message === "missing_auth_token") ||
			("error" in e && (e.error === "missing_refresh_token" || e.error === "invalid_grant")))
	);
};
