import { useMutation, useQueryClient } from "@tanstack/react-query";
import sql from "sql-template-tag";

import { type GitDiffSummary, gitQueryKeys } from ".";
import { getDb } from "../sql";

export const useGitRepoDiffSummaryCacheMutation = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: gitQueryKeys.repo.diff.summary.update(),

		mutationFn: async ({
			documentId,
			diffSummary,
		}: {
			documentId: string;
			diffSummary: GitDiffSummary;
		}) => {
			const db = await getDb();

			const query = sql`
				INSERT INTO documents_diff_summary
					(documentId, changed, insertions, deletions)
				VALUES
					(${documentId}, ${diffSummary.changed}, ${diffSummary.insertions}, ${diffSummary.deletions})
				ON CONFLICT (documentId) DO UPDATE SET
					changed = ${diffSummary.changed},
					insertions = ${diffSummary.insertions},
					deletions = ${diffSummary.deletions}
			`;

			await db?.execute(query.sql, query.values);
		},

		/*
		 * Update the query cache when the mutation succeeds
		 */
		onSuccess: (_, { documentId, diffSummary }) => {
			void queryClient.setQueryData(
				gitQueryKeys.repo.diff.summary.cache(documentId),
				diffSummary
			);
		},
	});
};
