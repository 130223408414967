import { type Attrs, type Mark, type MarkSpec } from "prosemirror-model";

// :: MarkSpec A link. Has `href` and `title` attributes. `title`
// defaults to the empty string. Rendered and parsed as an `<a>`
// element.

export const link: MarkSpec = {
	attrs: {
		href: {},
		title: { default: null },
	},
	inclusive: false,
	parseDOM: [
		{
			tag: "a[href]",
			getAttrs(node: string | HTMLElement) {
				if (!(node instanceof HTMLElement)) {
					return false;
				}

				const attrs: Attrs = {
					href: node.getAttribute("href"),
					title: node.getAttribute("title"),
				};

				return attrs;
			},
		},
		{
			tag: "span[data-href]",
			getAttrs(node: string | HTMLElement) {
				if (!(node instanceof HTMLElement)) {
					return false;
				}

				const attrs: Attrs = {
					href: node.dataset["href"],
					title: node.dataset["title"],
				};

				return attrs;
			},
		},
	],
	toDOM(mark: Mark) {
		/*
		 * External links should open in a new window and have rel set
		 */
		if (!mark.attrs["href"].startsWith("/d/")) {
			const attrs = {
				...mark.attrs,
				// open links in new window by default
				target: "_blank",
				// prevent access to the originating window
				rel: "noopener noreferrer",
			};
			return ["a", attrs as Attrs, 0];
		}

		return ["span", { "data-href": mark.attrs["href"], "data-title": mark.attrs["title"] }, 0];
	},
};
