import { useCallback, useMemo } from "react";
import { useTimeoutFn } from "react-use";
import { create } from "zustand";

import { makeToast } from "@moment/design-system/Toast";

import { useAuth } from "~/auth/useAuth";
import { useDocsList } from "~/components/Docs/useDocsList";
import { useRevalidateDocumentMutation } from "~/data/documents";
import { useCreateRemoteDocumentRepoMutation } from "~/data/documents/useCreateRemoteDocumentRepoMutation";
import { useRemoteDocumentRepoExistsQuery } from "~/data/documents/useRemoteDocumentRepoExists";
import {
	useCommitToRepoMutation,
	useGitRemotesQuery,
	useGitRepoStatusQuery,
	usePushRepoMutation,
} from "~/data/git";
import {
	GIT_PUBLISHING_BRANCH_NAME,
	GIT_PUBLISHING_REMOTE_NAME,
	useAddGitPublishingRemoteMutation,
} from "~/data/git/useAddGitPublishingRemoteMutation";
import { useAddUpstreamBranchMutation } from "~/data/git/useAddUpstreamBranchMutation";
import { useGitListBranchesQuery } from "~/data/git/useGitListBranchesQuery";
import {
	ALL_USERS,
	useCanPublishQuery,
	useIsPublishedQuery,
	useRevokeShareMutation,
	useShareMutation,
} from "~/data/permissions";
import { useRouteParams } from "~/data/route";
import { getDocsApiUrl, getDocsWebsite } from "~/utils/docsapi";

export type SyncStatus = "status-pending" | "syncing" | "synced";
export type PublishStatus = "status-pending" | "publishing" | "unpublishing" | "published";

export type SyncMode = "publish-if-unpublished" | "try-sync";

export type PlanStatus =
	| "status-pending"
	| "unachievable-no-steps"
	| "unachievable-not-on-synced-branch"
	| "unachievable-not-authenticated"
	| "unachievable-unauthorized"
	| "unachievable-behind-remote"
	| "achievable";

const actions = [
	"create-publish-repo",
	"initialize-git-repo",
	"commit-initial-changes",
	"commit-outstanding-changes",
	"create-remote",
	"set-tracking-branch",
	"push-to-remote",
	"publish-document",
] as const;

export type PlannedOutcome = null | "commit" | "push" | "publish";

export type SyncAction = (typeof actions)[number];

const actionsOrder: { [key in SyncAction]: number } = {
	"create-publish-repo": 0,
	"initialize-git-repo": 1,
	"commit-initial-changes": 2,
	"commit-outstanding-changes": 3,
	"create-remote": 4,
	"set-tracking-branch": 5,
	"push-to-remote": 6,
	"publish-document": 7,
} as const;

const useSyncStatus = (
	workspaceToPublishFrom: string,
	documentIdToPublish: string,
	mode: SyncMode
): {
	planStatus: PlanStatus;
	actions: SyncAction[];
	plannedOutcome: PlannedOutcome;
	branch: string | undefined;
	ahead: number;
	behind: number;
} => {
	const workspaceName = workspaceToPublishFrom;
	const documentId = documentIdToPublish;
	const { isAuthenticated } = useAuth();
	const publishPerms = useCanPublishQuery({ workspaceName, documentId });
	const remoteRepoExists = useRemoteDocumentRepoExistsQuery({ workspaceName, documentId });
	const { data: gitRepoStatus } = useGitRepoStatusQuery({ workspaceName, documentId });
	const isOnPublishBranch =
		gitRepoStatus?.isRepo && gitRepoStatus.branch === GIT_PUBLISHING_BRANCH_NAME;
	const { data: remotes } = useGitRemotesQuery({ workspaceName, documentId });
	const documentRemoteSet = useMemo(
		() =>
			remotes?.find(
				(remote) =>
					remote.name === GIT_PUBLISHING_REMOTE_NAME &&
					remote.url.startsWith(getDocsApiUrl())
			) !== undefined,
		[remotes]
	);
	const { data: trackingBranches } = useGitListBranchesQuery({
		workspaceName,
		documentId,
	});
	const trackingBranchSet = useMemo(
		() =>
			!!trackingBranches?.find((branch) => branch.name === GIT_PUBLISHING_BRANCH_NAME)
				?.upstream,
		[trackingBranches]
	);
	const published = useIsPublishedQuery({ workspaceName, documentId });

	const branch =
		gitRepoStatus?.isRepo && gitRepoStatus?.branch ? gitRepoStatus.branch : undefined;
	const ahead = gitRepoStatus?.isRepo && gitRepoStatus?.ahead ? gitRepoStatus?.ahead : 0;
	const behind = gitRepoStatus?.isRepo && gitRepoStatus?.behind ? gitRepoStatus?.behind : 0;
	const repoClean = gitRepoStatus?.isRepo && gitRepoStatus?.isClean;
	const repoAhead = ahead > 0;

	const [actions, plannedOutcome] = useMemo(() => {
		const actions = new Set<SyncAction>();
		let plannedOutcome: PlannedOutcome = null;

		const shouldAutoPublish = mode === "publish-if-unpublished";
		const isSyncable = !!remoteRepoExists.data;
		const isPublished = published.data == true;
		const shouldAutoPush = mode === "try-sync" && isOnPublishBranch && isSyncable;

		//
		// Attempt to add the actions to the action set in roughly the order they'd be executed.
		//

		if (shouldAutoPublish && !remoteRepoExists.data) {
			actions.add("create-publish-repo");
		}

		!gitRepoStatus?.isRepo && actions.add("initialize-git-repo");
		!gitRepoStatus?.isRepo && actions.add("commit-initial-changes");
		if (!gitRepoStatus?.isRepo && plannedOutcome) {
			plannedOutcome = "commit";
		}

		!repoClean && actions.add("commit-outstanding-changes");
		if (!repoClean) {
			plannedOutcome = "commit";
		}

		if (shouldAutoPush || shouldAutoPublish || isSyncable || isPublished) {
			(!gitRepoStatus?.isRepo || !documentRemoteSet) && actions.add("create-remote");
			(!gitRepoStatus?.isRepo || !documentRemoteSet || !trackingBranchSet) &&
				actions.add("set-tracking-branch");

			if (
				!remoteRepoExists.data ||
				!gitRepoStatus?.isRepo ||
				!documentRemoteSet ||
				!trackingBranchSet ||
				!repoClean ||
				repoAhead
			) {
				plannedOutcome = "push"; // This plan results in a push.
				actions.add("push-to-remote");
			}
		}

		if (shouldAutoPublish && !isPublished) {
			plannedOutcome = "publish"; // This plan results in a publish.
			actions.add("publish-document");
		}

		return [
			[...actions].sort((a, b) => actionsOrder[a] - actionsOrder[b]),
			plannedOutcome,
		] as const;
	}, [
		documentRemoteSet,
		gitRepoStatus?.isRepo,
		isOnPublishBranch,
		mode,
		published.data,
		remoteRepoExists.data,
		repoAhead,
		repoClean,
		trackingBranchSet,
	]);

	let planStatus: PlanStatus;
	if (actions.length === 0) {
		planStatus = "unachievable-no-steps";
	} else if (!isOnPublishBranch) {
		planStatus = "unachievable-not-on-synced-branch";
	} else if (!isAuthenticated) {
		planStatus = "unachievable-not-authenticated";
	} else if (!publishPerms.isSuccess) {
		planStatus = "unachievable-unauthorized";
	} else if (gitRepoStatus.behind > 0) {
		planStatus = "unachievable-behind-remote";
	} else if (published.isPending) {
		planStatus = "status-pending";
	} else {
		planStatus = "achievable";
	}

	return useMemo(
		() => ({ planStatus, actions, plannedOutcome, branch, ahead, behind }),
		[planStatus, actions, plannedOutcome, branch, ahead, behind]
	);
};

interface PublishState {
	publishStatusMessage: string | null;
	isPublishing: boolean;
	setPublishStatusMessage: (message: string | null) => void;
	setIsPublishing: (isPublishing: boolean) => void;
	provisioningPublishUrl: boolean;
	setProvisioningPublishUrl: (provisioningPublishUrl: boolean) => void;
}

const _usePublishStore = create<PublishState>((set) => ({
	publishStatusMessage: null,
	isPublishing: false,
	setPublishStatusMessage: (message) => set({ publishStatusMessage: message }),
	setIsPublishing: (isPublishing) => set({ isPublishing }),
	provisioningPublishUrl: false,
	setProvisioningPublishUrl: (provisioningPublishUrl) => set({ provisioningPublishUrl }),
}));

const usePublishStore = () => {
	const { setProvisioningPublishUrl, ...store } = _usePublishStore();

	// This hilarious bit of logic is because it can take SpiceDB many seconds to converge the
	// write. If we let people click the button too quickly, they'll get a 404 error.
	const [, _cancelProvisioningTimeout, _resetProvisioningTimeout] = useTimeoutFn(() => {
		setProvisioningPublishUrl(false);
	}, 15000);
	const cancelProvisioningTimeout = useCallback(() => {
		setProvisioningPublishUrl(false);
		_cancelProvisioningTimeout();
	}, [_cancelProvisioningTimeout, setProvisioningPublishUrl]);
	const resetProvisioningTimeout = useCallback(() => {
		setProvisioningPublishUrl(true);
		_resetProvisioningTimeout();
	}, [setProvisioningPublishUrl, _resetProvisioningTimeout]);

	return useMemo(
		() => ({ ...store, cancelProvisioningTimeout, resetProvisioningTimeout }),
		[cancelProvisioningTimeout, resetProvisioningTimeout, store]
	);
};

const useSyncAction = (
	workspaceToPublishFrom: string,
	documentIdToPublish: string,
	mode: SyncMode
) => {
	const workspaceName = workspaceToPublishFrom;
	const documentId = documentIdToPublish;

	const { planStatus, actions, plannedOutcome, branch, ahead, behind } = useSyncStatus(
		workspaceName,
		documentId,
		mode
	);

	const { mutateAsync: createRemoteDocument } = useCreateRemoteDocumentRepoMutation();
	const { mutateAsync: addGitPublishingRemote } = useAddGitPublishingRemoteMutation();
	const { mutateAsync: addUpstreamBranch } = useAddUpstreamBranchMutation();
	const { mutateAsync: commitToRepo } = useCommitToRepoMutation();
	const { mutateAsync: pushRepo } = usePushRepoMutation();
	const { mutateAsync: publishDocument } = useShareMutation();
	const { mutateAsync: revokeShare, isPending: isRevoking } = useRevokeShareMutation();
	const { mutateAsync: revalidateCdnCache } = useRevalidateDocumentMutation();

	const published = useIsPublishedQuery({ workspaceName, documentId });
	const { isShared, shareLink: publishUrl } = useMemo(() => {
		const isShared = published.isSuccess && published.data;

		if (isShared) {
			return { isShared, shareLink: `${getDocsWebsite()}/d/${workspaceName}/${documentId}` };
		} else {
			return { isShared, shareLink: "" };
		}
	}, [published.isSuccess, published.data, workspaceName, documentId]);

	const {
		publishStatusMessage,
		isPublishing,
		setPublishStatusMessage,
		setIsPublishing,
		provisioningPublishUrl,
		cancelProvisioningTimeout,
		resetProvisioningTimeout,
	} = usePublishStore();

	const sync = useCallback(
		async (commitMessage: string) => {
			if (
				(planStatus !== "achievable" && planStatus !== "unachievable-not-authenticated") ||
				isPublishing
			) {
				return;
			}

			setIsPublishing(true);
			cancelProvisioningTimeout();
			try {
				for (const action of actions) {
					switch (action) {
						case "create-publish-repo":
							setPublishStatusMessage("Creating remote repository...");
							await createRemoteDocument({ workspaceName, documentId });
							break;
						case "initialize-git-repo":
							// FIXME
							// await initializeGitRepo({ workspaceName, documentId });
							throw new Error("Not implemented");
						case "commit-initial-changes":
							setPublishStatusMessage("Committing changes...");
							await commitToRepo({
								documentId,
								message: "🎉 Initial commit!",
							});
							break;
						case "commit-outstanding-changes":
							setPublishStatusMessage("Committing changes...");
							await commitToRepo({
								documentId,
								message: commitMessage,
							});
							break;
						case "create-remote":
							setPublishStatusMessage("Creating remote...");
							await addGitPublishingRemote({ workspaceName, documentId });
							break;
						case "set-tracking-branch":
							setPublishStatusMessage("Setting tracking branch...");
							await addUpstreamBranch({
								documentId,
								localBranch: GIT_PUBLISHING_BRANCH_NAME,
								remote: GIT_PUBLISHING_REMOTE_NAME,
								upstreamBranch: GIT_PUBLISHING_BRANCH_NAME,
							});
							break;
						case "push-to-remote":
							setPublishStatusMessage("Pushing commits...");
							await pushRepo({
								documentId,
								remote: GIT_PUBLISHING_REMOTE_NAME,
								localBranch: GIT_PUBLISHING_BRANCH_NAME,
							});
							break;
						case "publish-document":
							setPublishStatusMessage("Publishing...");
							await publishDocument({ workspaceName, documentId, user: ALL_USERS });
							resetProvisioningTimeout();
							break;
					}
				}
			} catch (e) {
				const rawMessage = e?.["message"];
				const message = rawMessage
					? `Failed to publish document: ${rawMessage}`
					: "Failed to publish document: unknown error";
				makeToast({
					variant: "error",
					message,
				});
				console.error(e);
				throw e;
			} finally {
				setIsPublishing(false);
				setPublishStatusMessage(null);
			}
		},
		[
			planStatus,
			isPublishing,
			setIsPublishing,
			cancelProvisioningTimeout,
			actions,
			setPublishStatusMessage,
			createRemoteDocument,
			workspaceName,
			documentId,
			commitToRepo,
			addGitPublishingRemote,
			addUpstreamBranch,
			pushRepo,
			publishDocument,
			resetProvisioningTimeout,
		]
	);

	const unpublish = useCallback(async () => {
		cancelProvisioningTimeout();
		await revokeShare({ workspaceName, documentId, user: ALL_USERS });
		await revalidateCdnCache({ workspaceName, documentId });
	}, [cancelProvisioningTimeout, revokeShare, revalidateCdnCache, workspaceName, documentId]);

	let publishStatus: PublishStatus = "status-pending";
	if (isRevoking) {
		publishStatus = "unpublishing";
	} else if (isPublishing) {
		publishStatus = "publishing";
	} else if (isShared) {
		publishStatus = "published";
	}

	let syncStatus: SyncStatus = "status-pending";
	if (isPublishing) {
		syncStatus = "syncing";
	} else if (isShared) {
		syncStatus = "synced";
	}

	return useMemo(
		() => ({
			publishStatus,
			syncStatus,
			planStatus,
			plan: actions,
			plannedOutcome,
			sync,
			unpublish,
			publishStatusMessage,
			publishUrl,
			provisioningPublishUrl,
			branch,
			ahead,
			behind,
		}),
		[
			publishStatus,
			syncStatus,
			planStatus,
			actions,
			plannedOutcome,
			sync,
			unpublish,
			publishStatusMessage,
			publishUrl,
			provisioningPublishUrl,
			branch,
			ahead,
			behind,
		]
	);
};

export interface PublishIfUnpublishedSyncState {
	sync: (commitMessage: string) => Promise<void>;
	syncStatus: PublishStatus;
	syncStatusMessage: string | null;

	publishUrl: string;
	provisioningPublishUrl: boolean;
	unpublish: () => Promise<void>;

	plan: SyncAction[];
	plannedOutcome: PlannedOutcome;
	planStatus: PlanStatus;

	branch: string | undefined;
	ahead: number;
	behind: number;
}

export interface TryPushSyncState {
	sync: (commitMessage: string) => Promise<void>;
	syncStatus: SyncStatus;
	syncStatusMessage: string | null;

	plan: SyncAction[];
	plannedOutcome: PlannedOutcome;
	planStatus: PlanStatus;

	branch: string | undefined;
	ahead: number;
	behind: number;
}

export const useSyncDocument = <
	T extends SyncMode,
	U extends T extends "publish-if-unpublished"
		? PublishIfUnpublishedSyncState
		: T extends "try-sync"
			? TryPushSyncState
			: never,
>(args: {
	mode: T;
}): U => {
	const { documentId } = useRouteParams();
	const {
		documents: { data: documents },
	} = useDocsList({ facet: null });

	const auth = useAuth();

	// This is disgusting
	const workspaceName =
		documents?.find((d) => d.id === documentId)?.workspaces[0]?.name ??
		auth.user?.nickname ??
		"";

	// Defaulting to "" is very stupid, but `publishStatus` will say the doc is not publishable if
	// it isn't publishable.
	const {
		publishStatus,
		syncStatus,
		planStatus,
		plan,
		plannedOutcome,
		sync,
		unpublish,
		publishStatusMessage,
		publishUrl,
		provisioningPublishUrl,
		branch,
		ahead,
		behind,
	} = useSyncAction(workspaceName, documentId, args.mode);

	return useMemo<U>(() => {
		if (args.mode === "publish-if-unpublished") {
			const state: PublishIfUnpublishedSyncState = {
				sync,
				syncStatus: publishStatus,
				syncStatusMessage: publishStatusMessage,

				publishUrl,
				provisioningPublishUrl,
				unpublish,

				plan,
				plannedOutcome,
				planStatus,

				branch,
				ahead,
				behind,
			};
			return state as U;
		}

		const state: TryPushSyncState = {
			sync,
			syncStatus,
			syncStatusMessage: publishStatusMessage,

			plan,
			plannedOutcome,
			planStatus,

			branch,
			ahead,
			behind,
		};

		return state as U;
	}, [
		args.mode,
		sync,
		syncStatus,
		publishStatusMessage,
		plan,
		plannedOutcome,
		planStatus,
		branch,
		ahead,
		behind,
		publishStatus,
		publishUrl,
		provisioningPublishUrl,
		unpublish,
	]);
};
