import { useMutation } from "@tanstack/react-query";

import { getDocsWebsite } from "~/utils/docsapi";

import { documentQueryKeys } from ".";

/**
 * Revalidate a document's published pages on Vercel's CDN.
 */
export const useRevalidateDocumentMutation = () => {
	const mutation = useMutation({
		mutationKey: documentQueryKeys.document.revalidate(),
		mutationFn: async ({
			workspaceName,
			documentId,
		}: {
			workspaceName: string;
			documentId: string;
		}) => {
			const res = await fetch(
				`${getDocsWebsite()}/api/revalidate/${workspaceName}/${documentId}`
			);
			if (!res.ok) {
				throw new Error("Failed to revalidate document");
			}
		},
	});

	return mutation;
};
