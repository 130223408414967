import { useMutation, useQueryClient } from "@tanstack/react-query";
import { invoke } from "@tauri-apps/api/core";

import { gitQueryKeys } from ".";
import { getDocumentDirPath } from "../filesystem";

export const useAddGitRemoteMutation = () => {
	const queryClient = useQueryClient();
	const mutation = useMutation({
		mutationFn: async (args: { documentId: string; remote: string; url: string }) => {
			const { documentId, remote, url } = args;
			const documentPath = await getDocumentDirPath(documentId);
			return await invoke("add_remote", { path: documentPath, remote, url });
		},

		onSuccess: (_, args) =>
			queryClient.invalidateQueries({
				queryKey: gitQueryKeys.repo.remotes.list(args.documentId),
			}),
	});

	return mutation;
};
