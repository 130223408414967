import { type PayloadAction, createEntityAdapter, createSlice } from "@reduxjs/toolkit";

import type { EditorState, Transaction } from "@moment/api-collab/prosemirror-state";

import { cellCompilationTriggeredThunk } from "./thunks";
import type { Document } from "./types";

const editorStateAdapter = createEntityAdapter<Document>({});

/**
 * The slice responsible for managing the state of the editors.
 */
export const editorsSlice = createSlice({
	name: "editors",
	initialState: editorStateAdapter.getInitialState(),
	reducers: {
		/**
		 * Populates the editor with the given document ID and editor state.
		 */
		editorLoaded: (
			state,
			action: PayloadAction<{
				pageId: string;
				editorState: EditorState;
				lastAccessed: Date;
			}>
		) => {
			const { pageId, editorState, lastAccessed } = action.payload;

			editorStateAdapter.setOne(state, {
				id: pageId,
				editorState,
				compiledCells: {},
				schemaVersion: "v1alpha1",
				lastAccessed,
			});

			return state;
		},

		/*
		 * Updates the editor state with the given transaction.
		 */
		transactionDispatched: (
			state,
			action: PayloadAction<{ pageId: string; transaction: Transaction; timestamp: Date }>
		) => {
			const { pageId, transaction, timestamp } = action.payload;

			const editorState = state.entities[pageId]?.editorState;

			if (!editorState) {
				return;
			}

			editorStateAdapter.updateOne(state, {
				id: pageId,
				changes: {
					editorState: editorState.apply(transaction),
					lastAccessed: timestamp,
				},
			});

			return state;
		},
	},

	extraReducers: (builder) => {
		builder.addCase(cellCompilationTriggeredThunk.fulfilled, (state, action) => {
			if (!action.payload) {
				return;
			}
			const { pageId, compiledCells } = action.payload;

			const page = state.entities[pageId];
			if (!page) {
				return;
			}

			editorStateAdapter.updateOne(state, {
				id: pageId,
				changes: {
					compiledCells,
				},
			});
		});
	},
});

export const { editorLoaded, transactionDispatched } = editorsSlice.actions;

export const editors = editorsSlice.reducer;

export const { selectById } = editorStateAdapter.getSelectors();
