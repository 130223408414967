import { z } from "zod";

export const GitDiffHunkSchema = z.object({
	aLineStart: z.number(),
	aNumLines: z.number(),
	bLineStart: z.number(),
	bNumLines: z.number().optional(),
	lines: z.array(
		z.object({ type: z.enum(["insertion", "deletion", "context"]), content: z.string() })
	),
});

export type GitDiffHunk = z.infer<typeof GitDiffHunkSchema>;

export const GitDiffFileSchema = z.object({
	type: z.enum(["added", "removed", "renamed", "modified"]),
	aPath: z.string(),
	bPath: z.string(),
	hunks: z.array(GitDiffHunkSchema),
});

export type GitDiffFile = z.infer<typeof GitDiffFileSchema>;

export const GitDiffSchema = z.object({
	parsedFiles: z.array(GitDiffFileSchema),
});

export type GitDiff = z.infer<typeof GitDiffSchema>;

export const GitShowHeaderSchema = z.object({
	prettyCommitSha: z.string(),
	authorName: z.string(),
	authorEmail: z.string(),
	prettyDate: z.string().datetime(),
	timeSinceCommit: z.string(),
	commitMessage: z.string(),
});

export type GitShowHeader = z.infer<typeof GitShowHeaderSchema>;

export const GitShowHeaderBackendSchema = z.object({});

export type GitShowHeaderBackend = z.infer<typeof GitShowHeaderSchema>;

export const GitShowSchema = z.object({
	originalDiff: z.string(),
	header: GitShowHeaderSchema,
	contents: z.array(z.string()),
	parsedFiles: z.array(GitDiffFileSchema),
});

export type GitShowQuery = z.infer<typeof GitShowSchema>;

export const GitShowBackendSchema = z.object({
	original_diff: z.string(),
	header: z.object({
		commit_sha: z.string(),
		parents: z.array(z.string()),
		author: z.object({
			name: z.string(),
			email: z.string(),
		}),
		committer: z.object({
			name: z.string(),
			email: z.string(),
		}),
		commit_time: z.string().datetime(),
		commit_message: z.string(),
	}),
	contents: z.array(z.string()),
	parsed_files: z.array(GitDiffFileSchema),
});

export type GitShowBackendQuery = z.infer<typeof GitShowBackendSchema>;

export const GitDiffSummarySchema = z.object({
	changed: z.number(),
	insertions: z.number(),
	deletions: z.number(),
});

export type GitDiffSummary = z.infer<typeof GitDiffSummarySchema>;

export const ConfigValuesSchema = z.record(
	z.string().or(z.number()).nullable(),
	z.string().or(z.array(z.string())).nullable()
);
export type ConfigValues = z.infer<typeof ConfigValuesSchema>;

export const ConfigListSummarySchema = z.object({
	all: ConfigValuesSchema.readonly(),
	files: z.array(z.string()),
	values: z.record(z.string().or(z.number()), ConfigValuesSchema),
});
export type ConfigListSummary = z.infer<typeof ConfigListSummarySchema>;

export const GitRemotesSchema = z.array(
	z.object({
		name: z.string(),
		url: z.string().url(),
	})
);
export type GitRemotes = z.infer<typeof GitRemotesSchema>;

export const GitRepoHistorySchema = z.object({
	commits: z.array(
		z.object({
			hash: z.string(),
			author: z.string(),
			date: z.string().datetime(),
			message: z.string(),
		})
	),
});

export type GitRepoHistory = z.infer<typeof GitRepoHistorySchema>;
