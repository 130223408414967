import { useQuery } from "@tanstack/react-query";
import { invoke } from "@tauri-apps/api/core";
import { formatDistanceToNowStrict, parseISO } from "date-fns";
import { isEmpty } from "lodash";

import { type GitShowBackendQuery, type GitShowHeader, type GitShowQuery, gitQueryKeys } from ".";
import { getDocumentDirPath } from "../filesystem";
import { LOCAL_WORKSPACE_NAME } from "../workspaces";

export const useGitShowQuery = (args: {
	workspaceName: string;
	documentId: string;
	commitHash?: string;
}) => {
	const { workspaceName, documentId, commitHash = "" } = args;

	const query = useQuery({
		staleTime: 1000 * 60 * 5, // 5 minutes
		enabled: workspaceName === LOCAL_WORKSPACE_NAME && !isEmpty(documentId),
		queryKey: [...gitQueryKeys.all(), "show", commitHash],
		queryFn: async () => {
			const path = await getDocumentDirPath(documentId);

			const show = await invoke<GitShowBackendQuery>("show", { path, commitHash });

			const commit_time = parseISO(show.header.commit_time);

			const header = {
				prettyCommitSha: show.header.commit_sha,
				authorName: show.header.author.name,
				authorEmail: show.header.author.email,
				prettyDate: show.header.commit_time,
				timeSinceCommit: formatDistanceToNowStrict(commit_time),
				commitMessage: show.header.commit_message,
			} as GitShowHeader;

			const showData = {
				header,
				originalDiff: show.original_diff,
				contents: show.contents,
				parsedFiles: show.parsed_files,
			} as GitShowQuery;
			console.log("got show", showData);
			return showData;
		},
	});

	return query;
};
