export { useArchiveRepoMutation } from "./useArchiveRepoMutation";
export { useCreateRepoMutation } from "./useCreateRepoMutation";
export { useDeleteRepoMutation } from "./useDeleteRepoMutation";
export const giteaQueryKeys = {
	base: () => ["gitea"],

	repo: {
		base: () => [...giteaQueryKeys.base(), "repo"],

		create: () => [...giteaQueryKeys.repo.base(), "create"],
		archive: () => [...giteaQueryKeys.repo.base(), "archive"],
		delete: () => [...giteaQueryKeys.repo.base(), "delete"],
	},
};
