import { documentQueryKeys } from "../documents";

export { useCreatePageMutation } from "./useCreatePageMutation";
export { useRenamePageMutation } from "./useRenamePageMutation";
export { useReorderPagesMutation } from "./useReorderPagesMutation";
export { useTogglePageArchivedMutation } from "./useTogglePageArchivedMutation";

export const pageQueryKeys = {
	base: () => [...documentQueryKeys.base(), "pages"],
	update: () => [...pageQueryKeys.base(), "update"],

	list: (workspaceName: string, documentId: string) => [
		...pageQueryKeys.base(),
		workspaceName,
		documentId,
		"list",
	],

	document: {
		base: (workspaceName: string, documentId: string) => [
			...documentQueryKeys.document.base(workspaceName, documentId),
			"pages",
		],

		list: (workspaceName: string, documentId: string) => [
			...pageQueryKeys.document.base(workspaceName, documentId),
			"list",
		],

		content: (workspaceName: string, documentId: string, pageId: string) => [
			...pageQueryKeys.document.base(workspaceName, documentId),
			pageId,
			"content",
		],

		author: (workspaceName: string, documentId: string, pageId: string) => [
			...pageQueryKeys.document.base(workspaceName, documentId),
			pageId,
			"author",
		],

		media: (workspaceName: string, documentId: string, pageId: string, file: string) => [
			...pageQueryKeys.document.base(workspaceName, documentId),
			pageId,
			"media",
			file,
		],
	},
};
