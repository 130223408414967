import { type ObjectProperty } from "@babel/types";

import { type FunctionBodyCell } from "~/utils/function-body-cell";

import { AstPattern } from "../ast-pattern";
import { objectExpressionPattern } from "../ast-pattern/patterns";
import { getMemberCallExpressionPattern } from "./patternUtil";

export type GetServiceDetailArgMatch = Pick<
	ObjectProperty,
	"type" | "value" | "extra" | "start" | "end" | "loc" | "key" | "value"
>[];

export type GetServiceDetailArgMatches = {
	[key: string]: GetServiceDetailArgMatch;
};

export type GetServiceDetailReturnArgMap = Record<
	"name",
	{
		value?: string;
		locations?: GetServiceDetailArgMatch;
	}
>;

export type GetServiceDetailReturnArgMatches = {
	[key: string]: GetServiceDetailReturnArgMap;
};

/**
 * Returns service detail matches for a given cell
 *
 * @param cell The cell to match

 * @returns matches in the following form
 * {
 *		moment-eng_atlas_atlas: {
 *			name: { value: "atlas", locations: [nodes]}
 *		},
 *		moment-eng_moment_moment: {
 *			name: { value: "moment", locations: [nodes]}
 *		}
 *	}
 */
export const matchGetServiceDetailCallPattern = async (cell: FunctionBodyCell | null) => {
	const matches: GetServiceDetailReturnArgMatches = {};

	// return if cell body is undefined
	if (!cell?.body) {
		return matches;
	}

	const getServiceDetailCallArgs: GetServiceDetailArgMatch[] = (
		await AstPattern.match(
			getMemberCallExpressionPattern(
				"Moment.serviceDetails.get",
				objectExpressionPattern(AstPattern.any())
			),
			cell.body
		)
	).flatMap((match) => [(match as { properties: ObjectProperty[] }).properties]);

	for (const arg of getServiceDetailCallArgs) {
		const argMap: GetServiceDetailReturnArgMap = { name: {} };

		for (const prop of arg) {
			// Skip args without valid key/value pairs
			if (!("name" in prop.key) || !("value" in prop.value)) {
				continue;
			}

			const existingMap = argMap[prop.key.name];
			if (existingMap?.locations) {
				argMap[prop.key.name] = {
					value: prop.value.value,
					locations: [...existingMap.locations, prop],
				};
			} else {
				argMap[prop.key.name] = {
					value: prop.value.value,
					locations: [prop],
				};
			}
		}

		const name = argMap.name.value;

		if (!name) {
			continue;
		}
		const id = name;

		const existingMatch = matches[id];
		if (existingMatch) {
			["owner", "repo", "name"].forEach((k) => {
				const existingMatchValue = existingMatch[k];
				const argMapValue = argMap[k];
				if (existingMatchValue && argMapValue) {
					existingMatchValue.locations = [
						...(existingMatchValue.locations || []),
						...(argMapValue.locations || []),
					];
				}
			});
		} else {
			matches[id] = argMap;
		}
	}

	return matches;
};
