import { useMutation } from "@tanstack/react-query";
import { useRouter } from "next/router";

import { useAuth } from "~/auth/useAuth";
import { giteaClient } from "~/utils/gitea";

import { giteaQueryKeys } from ".";

export const useDeleteRepoMutation = () => {
	const { getAccessTokenSilently, isAuthenticated } = useAuth();
	const router = useRouter();

	return useMutation({
		mutationKey: giteaQueryKeys.repo.delete(),
		mutationFn: async ({
			workspaceName,
			documentId,
		}: {
			workspaceName: string;
			documentId: string;
		}) => {
			const token = await getAccessTokenSilently({
				promptForLogin: true,
				redirectPath: router.asPath,
			});
			if (!isAuthenticated || !token) {
				return;
			}

			const gitea = giteaClient(token);

			const { data } = await gitea.repos.repoDelete(workspaceName, documentId);

			return data;
		},
	});
};
