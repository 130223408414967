import { cva } from "class-variance-authority";
import { type ComponentProps, forwardRef, useImperativeHandle, useRef } from "react";

import { cn } from "../utils";

export interface InputProps extends Omit<ComponentProps<"input">, "size"> {
	type?: "text" | "password"; // TODO: Add more input types.
	size?: "md" | "lg";
	elevation?: "flat" | "raised";
	color?: "brand" | "negative";
	startAddon?: React.ReactNode;
	endAddon?: React.ReactNode;
}

export const variants = cva(
	[
		"flex",
		"w-full",

		"text-sm",
		// "font-normal",

		"bg-primary",

		"border",

		"outline-1",
		"-outline-offset-1",
		"focus-within:outline",
		"focus-within:outline-2",
		"focus-within:-outline-offset-2",

		"file:border-0",
		"file:bg-transparent",
		"file:text-sm",
		"file:font-medium",
		"file:text-foreground",
		"disabled:cursor-not-allowed",
		"disabled:opacity-50",
	],
	{
		variants: {
			size: {
				md: ["h-8", "px-2", "py-2", "rounded-md"],
				lg: ["h-10", "px-3", "py-2", "rounded-lg"],
			},
			color: {
				brand: [
					"placeholder:text-tertiary",

					"focus-within:outline-palette-brand-9",

					"text-primary",
					"[&>.addon]:text-secondary",

					"border-palette-gray-7",
					"hover:border-palette-gray-8",
					"focus:border-palette-brand-8",
					"active:border-palette-brand-7",
				],
				negative: [
					"placeholder:text-palette-negative-10",

					"focus-within:outline-palette-negative-9",

					"text-palette-negative-11",
					"[&>.addon]:text-palette-negative-9",

					"selection:bg-palette-negative-9",

					"border-palette-negative-7",
					"hover:border-palette-negative-8",
					"focus:border-palette-negative-8",
					"active:border-palette-negative-7",
				],
			},
			elevation: {
				raised: "shadow-sm hover:shadow",
				flat: "shadow-none",
			},
		},
		defaultVariants: {
			color: "brand",
			size: "md",
			elevation: "flat",
		},
	}
);

const Input = forwardRef<HTMLInputElement, InputProps>(
	({ className, type, size, elevation, color, startAddon, endAddon, ...props }, ref) => {
		const innerRef = useRef<HTMLInputElement>(null);
		useImperativeHandle(ref, () => innerRef.current!);

		function focusInputOnMouseDown(e: React.MouseEvent<HTMLDivElement>) {
			if (e.target === innerRef.current) {
				return;
			}
			e.preventDefault();
			e.stopPropagation();
			innerRef.current?.focus();
		}

		return (
			// eslint-disable-next-line jsx-a11y/no-static-element-interactions
			<div
				data-disabled={props.disabled}
				className={cn(
					"flex",
					"gap-0.5",
					"items-center",
					"rounded-md",
					"px-3",
					"transition-none",
					"data-[disabled=true]:opacity-70",
					"data-[disabled=true]:cursor-not-allowed",
					variants({ size, elevation, color }),
					className
				)}
				onMouseDown={focusInputOnMouseDown}
			>
				<div className={cn("addon", "shrink-0", "select-none")}>{startAddon}</div>
				<input
					type={type}
					className={cn(
						"outline-none min-w-0 grow bg-transparent disabled:cursor-not-allowed"
					)}
					ref={innerRef}
					{...props}
				/>
				<div className={cn("addon", "shrink-0", "select-none")}>{endAddon}</div>
			</div>
		);
	}
);
Input.displayName = "Input";

export { Input };
