import { InputRule, wrappingInputRule } from "prosemirror-inputrules";
import { type NodeType } from "prosemirror-model";

import { BULLET_CHARS, schema } from "@moment/api-collab/prosemirror-schema";

/**
 * Given a to-do node type, returns an input rule that turns a checkbox ([ ]) at the start of a textblock into a
 * to-do item.
 */
export const toDoRules = function (nodeType: NodeType): InputRule[] {
	// MATCHES
	// `[] `
	// `[ ] `
	// `[x] `
	// `[X] `
	// `- [] ` (for any BULLET_CHARS, not just '-')
	// `- [ ] `
	// `- [x] `
	// `- [X] `

	const exp = new RegExp(`(?:[${BULLET_CHARS.join("|")}] )?\\[(\\s|x|X)?\\]\\s$`);

	return [
		wrappingInputRule(exp, nodeType, (match) => {
			return {
				// If match contains an x, set it to completed, otherwise set it to to do
				status: match[1]?.match(new RegExp(`^(?:[${BULLET_CHARS.join("|")}] )?[xX]$`))
					? "completed"
					: "to do",
				indent: 0,
			};
		}),
		new InputRule(exp, (state, match, start, end) => {
			const $start = state.doc.resolve(start);
			const grandParent = $start.node(-1);
			if (grandParent.type !== schema.nodes.list_item) {
				return null;
			}
			const tr = state.tr;
			tr.delete(start, end);
			const $mappedStart = tr.doc.resolve(start);
			return tr.replaceWith(
				$mappedStart.before(-1),
				$mappedStart.after(-1),
				nodeType.create(
					{
						// If match contains an x, set it to completed, otherwise set it to to do
						status: match[1]?.match(
							new RegExp(`^(?:[${BULLET_CHARS.join("|")}] )?[xX]$`)
						)
							? "completed"
							: "to do",
						indent: 0,
					},
					$mappedStart.node(-1).content
				)
			);
		}),
	];
};
