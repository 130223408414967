import { type Command, NodeSelection } from "prosemirror-state";

import { schema } from "@moment/api-collab/prosemirror-schema";

export const ignoreIfComputeCellSelected: Command = (state, dispatch) => {
	// Can't do anything if there's no dispatch function.
	if (!dispatch) {
		return false;
	}

	const sel = state.selection;
	const anchorNode = sel.$anchor.node();

	// Ignore if the selection is a `NodeSelection` of a `compute_cell`.
	if (sel instanceof NodeSelection && sel.node.type === schema.nodes.compute_cell) {
		return true;
	}

	// Ignore if selection is (somehow) a 0-width selection that contains a `compute_cell`.
	if (sel.anchor === sel.head && anchorNode.type === schema.nodes.compute_cell) {
		return true;
	}

	return false;
};
