import { useMutation } from "@tanstack/react-query";

import type { PageMetadata } from "@moment/api-collab/api-types";
import { generateShortUuid } from "@moment/api-collab/id";

import { useLogger } from "~/components/canvas/hooks/useLogger";
import { create, getPageDirPath, getPageFilePath, mkdir } from "~/data/filesystem";

import { useUpdateDocumentMutation } from "../documents";
import { LOCAL_WORKSPACE_NAME } from "../workspaces";

export const useCreatePageMutation = () => {
	const logger = useLogger("useCreatePageMutation");

	const { updateAsync: updateDocument } = useUpdateDocumentMutation();

	return useMutation({
		mutationFn: async (args: { documentId: string; pageMetadata?: Partial<PageMetadata> }) => {
			const { documentId, pageMetadata } = args;

			const newPageId = pageMetadata?.id ?? generateShortUuid();

			const newPage: PageMetadata = Object.assign(
				{
					id: newPageId,
					parentPageID: null,
					slug: newPageId,
					title: "",
					archived: false,
				},
				pageMetadata
			);

			// Create the directory
			await mkdir(await getPageDirPath(documentId, newPageId));

			// Create the page file
			await create(await getPageFilePath(documentId, newPageId));

			await updateDocument(
				{
					workspaceName: LOCAL_WORKSPACE_NAME,
					documentId,
				},
				(document) => {
					if (!document) {
						return document;
					}

					document.pagesMetadata.push(newPage);
					return document;
				}
			);

			return newPage;
		},
		onError: (error, variables) => {
			logger.error("Error creating page", { error, variables });
		},
	});
};
