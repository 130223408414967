import { useQueries } from "@tanstack/react-query";
import { uniqBy } from "lodash";
import { useAsyncRetry, useDebounce, useDeepCompareEffect } from "react-use";

import { useAuth } from "~/auth/useAuth";
import {
	documentsListQuery,
	remoteDocumentsListQuery,
	useUpsertDocumentWorkspacesCacheMutation,
} from "~/data/documents";
import { LOCAL_WORKSPACE_NAME, useWorkspaceListQuery } from "~/data/workspaces";

export const useDocsList = ({ facet }: { facet: string | null }) => {
	const { isAuthenticated, getAccessTokenSilently } = useAuth();

	const { data: workspaces } = useWorkspaceListQuery();
	const { mutateAsync: upsertDocumentWorkspacesCache } =
		useUpsertDocumentWorkspacesCacheMutation();

	const { value: accessToken, retry: refreshAccessToken } = useAsyncRetry(
		async () => await getAccessTokenSilently()
	);

	/*
	 * When the user logs in, we need to refresh the access token
	 */
	useDebounce(
		() => {
			if (isAuthenticated) {
				refreshAccessToken();
			}
		},
		100,
		[isAuthenticated]
	);

	const documents = useQueries({
		queries: [
			// Local workspace
			...(facet === null || facet === LOCAL_WORKSPACE_NAME
				? [documentsListQuery().getOptions()]
				: []),

			// Remote workspaces
			...(workspaces
				?.filter(
					(w) => facet !== LOCAL_WORKSPACE_NAME && (facet === null || w.name === facet)
				)
				?.map((workspace) =>
					remoteDocumentsListQuery(
						isAuthenticated,
						accessToken,
						workspace.name
					).getOptions()
				) ?? []),
		],
		combine: (results) => {
			const baseData = results
				.map((r) => r.data)
				.flat()
				.filter((r) => r !== undefined);

			const filteredData =
				facet === LOCAL_WORKSPACE_NAME
					? baseData.filter((document) => document.workspaces.length === 0)
					: baseData;

			const data = uniqBy(
				filteredData.sort((a, b) => b!.updatedAt.getTime() - a!.updatedAt.getTime()),
				"id"
			);

			return {
				isPending: results.every((r) => r.isPending), // Only show loading state if all queries are pending
				isError: results.every((r) => r.isError), // Only show error state if all queries error out
				isDisabled: facet !== null && facet !== LOCAL_WORKSPACE_NAME && !isAuthenticated,
				refetch: async () => await Promise.all(results.map((r) => r.refetch())),
				data,
			};
		},
	});

	// Upsert the document workspaces cache
	useDeepCompareEffect(() => {
		if (documents.data) {
			void Promise.all(
				documents.data.map((document) => {
					const workspace = document.workspaces[0];

					if (!workspace) {
						return;
					}

					return upsertDocumentWorkspacesCache({
						documentId: document.id,
						workspaceId: workspace.id,
						action: "add",
					});
				})
			);
		}
	}, [documents.data]);

	return {
		documents: {
			...documents,
			refetch: async () => {
				refreshAccessToken();
				await documents.refetch();
			},
		},
		workspaces,
	};
};
