import { useQuery } from "@tanstack/react-query";
import { invoke } from "@tauri-apps/api/core";
import { isEmpty } from "lodash";
import { z } from "zod";

import { gitQueryKeys } from ".";
import { getDocumentDirPath } from "../filesystem";
import { useRouteParams } from "../route";

export const branchInfoSchema = z.object({
	name: z.string(),
	upstream: z.string().nullable(),
});

type BranchInfo = z.infer<typeof branchInfoSchema>;

export const useGitListBranchesQuery = (args?: {
	workspaceName: string;
	documentId: string;
	retry?: boolean | number;
}) => {
	const routeParams = useRouteParams();

	const {
		workspaceName = routeParams.workspaceName,
		documentId = routeParams.documentId,
		retry = true,
	} = args ?? {};

	const query = useQuery<BranchInfo[]>({
		queryKey: gitQueryKeys.repo.branches.list(documentId),

		queryFn: async () => {
			const path = await getDocumentDirPath(documentId);
			return await invoke("list_local_branches", { path });
		},
		retry,

		enabled: !isEmpty(workspaceName) && !isEmpty(documentId),
	});

	return query;
};
