import { type AppState, type RedirectLoginOptions } from "@auth0/auth0-react";
import { invoke } from "@tauri-apps/api/core";
import { once } from "@tauri-apps/api/event";
import { openUrl as openExternalBrowser } from "@tauri-apps/plugin-opener";

import { attemptSaveRedirect, deleteRedirectEntry, getRedirectEntry } from "./redirectStore";

type Auth0LoginWithRedirect = (options?: RedirectLoginOptions<AppState>) => Promise<void>;
type ExternalAction = (redirectId?: string) => Promise<void>;
type PopupAction = (appState: AppState) => Promise<void>;

// Use a popup window, intended for desktop app. This performs the
// authentication in context of the desktop app, instead of in a
// separate browser. During the process we will not hide the previous
// page; if the user closes the popup then they can immediately resume viewing public pages.
const getPopupAction =
	(loginWithRedirect: Auth0LoginWithRedirect, externalAction: ExternalAction): PopupAction =>
	async (appState: AppState) => {
		await loginWithRedirect({
			appState,
			authorizationParams: { prompt: "select_account" },
			async openUrl(url: string) {
				console.log(`navigating to auth0 login in popup browser window, url=${url}`);
				// This listens for the request to use the external brower instead. We listen
				// for only 5 minutes. This has to be registered in the front end so that we
				// can restart the login process with new parameters.
				const unlistenForExternal = await once("use-external-browser-auth", (_event) => {
					void externalAction(appState["redirectId"]);
				});

				setTimeout(
					() => {
						void unlistenForExternal();
					},
					1000 * 60 * 5
				);

				// open the login window
				await invoke("run_auth_window", { authorize: url });
			},
		});
	};

const getWrappedExternalAction = (loginWithRedirect: Auth0LoginWithRedirect): ExternalAction => {
	return async (redirectId?: string) => {
		const state = redirectId ? { redirectId } : {};
		await loginWithRedirect({
			appState: state,
			authorizationParams: { prompt: "login" },
			async openUrl(url: string) {
				console.log(`navigating to auth0 login in an external browser window, url=${url}`);
				void openExternalBrowser(url);
			},
		});
	};
};

// Use normal in-browser tab redirect, intended for website.
// This will redirect the tab to the Auth0 auth portal.
// Users can use their browser navigation to return
// if they just want to view public pages.
const getRedirectAction =
	(loginWithRedirect: Auth0LoginWithRedirect) => async (appState: AppState) => {
		console.log(`navigating to auth0 login in current browser tab`);
		await loginWithRedirect({
			appState,
			authorizationParams: { prompt: "select_account" },
		});
	};

// Login will stash the redirect information as redirectId in the "state" query value.
// In the callback, that same "state" value will be provided as the argument.
// If state (redirectId) is not provided, or does not retrieve a redirectPath,
// then login will redirect to landing page.
export const getLoginAction = (
	loginWithRedirect: Auth0LoginWithRedirect,
	opts: {
		isLocalApp: boolean;
	}
) => {
	const { isLocalApp } = opts;

	const action = async (redirectPath: string) => {
		redirectPath = redirectPath ?? "/docs";
		const redirectEntry = attemptSaveRedirect({ redirectPath });
		const state: AppState = redirectEntry ? { redirectId: redirectEntry.id } : {};

		if (isLocalApp) {
			const externalAction = getWrappedExternalAction(loginWithRedirect);
			const performPopupLogin = getPopupAction(loginWithRedirect, externalAction);
			try {
				await performPopupLogin(state);
			} catch (err) {
				window.location.replace("/docs?postlogin=true");
				throw err;
			}
		} else {
			const performRedirectLogin = getRedirectAction(loginWithRedirect);
			await performRedirectLogin(state);
		}
	};

	return action;
};

// Exported login callback implementation.
// This only occurs when the login is *successful*, automatically by the auth0 library.
export const onLoginSuccess = (redirectId: string): string => {
	if (!redirectId) {
		return "/docs";
	}

	const { redirectPath } = getRedirectEntry(redirectId) || {};
	deleteRedirectEntry(redirectId);
	return redirectPath ?? "/docs";
};
