import { useQuery } from "@tanstack/react-query";
import { invoke } from "@tauri-apps/api/core";
import { first, isEmpty } from "lodash";
import sql from "sql-template-tag";

import { type GitDiffSummary, gitQueryKeys, useGitRepoDiffSummaryCacheMutation } from ".";
import { getDocumentDirPath } from "../filesystem";
import { useRouteParams } from "../route";
import { getDb } from "../sql";
import { LOCAL_WORKSPACE_NAME } from "../workspaces";

export const useGitDiffSummaryQuery = (args?: { workspaceName: string; documentId: string }) => {
	const routeParams = useRouteParams();

	const { workspaceName = routeParams.workspaceName, documentId = routeParams.documentId } =
		args ?? {};

	const { mutate: updateCacheDiffSummary } = useGitRepoDiffSummaryCacheMutation();

	const query = useQuery({
		queryKey: gitQueryKeys.repo.diff.summary.cache(documentId),

		queryFn: async () => {
			const db = await getDb();

			const query = sql`
				SELECT * FROM documents_diff_summary
				WHERE documentId = ${documentId}
				LIMIT 1
			`;

			const result = await db?.select<GitDiffSummary[]>(query.sql, query.values);

			if (!result || result.length === 0) {
				return undefined;
			}

			return first(result);
		},
	});

	useQuery({
		queryKey: gitQueryKeys.repo.diff.summary.disk(documentId),

		queryFn: async () => {
			const dir = await getDocumentDirPath(documentId);

			try {
				const diffSummary = await invoke<GitDiffSummary>("diff_summary", { path: dir });

				void updateCacheDiffSummary({
					documentId,
					diffSummary,
				});

				return diffSummary;
			} catch (e) {
				console.error("useGitDiffSummaryQuery", "error getting diff summary", e);
				throw e;
			}
		},

		/*
		 * Refetch every 5 minutes
		 */
		staleTime: 1000 * 60 * 5,

		/*
		 * Only fetch the diff summary if the document is in the local workspace
		 */
		enabled: workspaceName === LOCAL_WORKSPACE_NAME && !isEmpty(documentId),
	});

	return query;
};
