import { useMutation, useQueryClient } from "@tanstack/react-query";
import sql from "sql-template-tag";
import { useDebouncedCallback } from "use-debounce";

import { getDb } from "../sql";
import { workspaceQueryKeys } from "../workspaces";

export const useUpsertWorkspaceCacheMutation = () => {
	const queryClient = useQueryClient();

	// This query will likely be called multiple times in a row, so let's debounce the invalidation
	const invalidateCache = useDebouncedCallback(async () => {
		void queryClient.invalidateQueries({ queryKey: workspaceQueryKeys.list.cache() });
	}, 100);

	return useMutation({
		mutationKey: workspaceQueryKeys.cache.upsert(),
		mutationFn: async ({ host, slug, name }: { host: string; slug: string; name: string }) => {
			const db = await getDb();

			const id = `${host}/${slug}`;

			const query = sql`
				INSERT INTO workspaces (id, host, slug, name) VALUES (${id}, ${host}, ${slug}, ${name})
				ON CONFLICT (id) DO UPDATE SET name = ${name}
			`;

			await db?.execute(query.sql, query.values);

			return {
				id,
				host,
				slug,
				name,
			};
		},
		onSuccess: () => {
			void invalidateCache();
		},
	});
};
