import { useQuery } from "@tanstack/react-query";
import { invoke } from "@tauri-apps/api/core";
import { first, isEmpty } from "lodash";
import { useMemo } from "react";
import sql from "sql-template-tag";

import { getDocumentDirPath } from "~/data/filesystem";

import { gitQueryKeys, useGitRepoStatusCacheMutation } from ".";
import { useRouteParams } from "../route";
import { getDb } from "../sql";
import { LOCAL_WORKSPACE_NAME } from "../workspaces";

export interface FileStatusResult {
	/** Original location of the file, when the file has been moved */
	from?: string;

	/** Path of the file */
	path: string;

	/** First digit of the status code of the file, e.g. 'M' = modified.
		Represents the status of the index if no merge conflicts, otherwise represents
		status of one side of the merge. */
	index: string;

	/** Second digit of the status code of the file. Represents status of the working directory
		if no merge conflicts, otherwise represents status of other side of a merge.
		See https://git-scm.com/docs/git-status#_short_format for full documentation of possible
		values and their meanings. */
	working_dir: string;
}

export type GitRepoStatus =
	| {
			isRepo: true;
			isClean: boolean;
			ahead: number;
			behind: number;
			branch: string;
			commit: string;
			staged: string[];
			files: {
				/** Original location of the file, when the file has been moved */
				from?: string;

				/** Path of the file */
				path: string;

				/** First digit of the status code of the file, e.g. 'M' = modified.
		 Represents the status of the index if no merge conflicts, otherwise represents
		 status of one side of the merge. */
				index: string;

				/** Second digit of the status code of the file. Represents status of the working directory
		 if no merge conflicts, otherwise represents status of other side of a merge.
		 See https://git-scm.com/docs/git-status#_short_format for full documentation of possible
		 values and their meanings. */
				working_dir: string;
			}[];
	  }
	| {
			isRepo: false;
	  };

// Yes this is gross, but I am doing this to avoid having to change the API
type RustGitRepoStatus = {
	ahead: number;
	behind: number;
	branch: string;
	commit: string;
	files: {
		from?: string;
		path?: string;
		index?: string;
		working_dir?: string;
	}[];

	is_clean: boolean;
	is_repo: boolean;
	staged: string[];
};

/**
 * Fetches the git status of a document from the cache and from disk
 * and updates the cache with the new status from disk
 */
export const useGitRepoStatusQuery = (args?: { workspaceName: string; documentId: string }) => {
	const routeParams = useRouteParams();

	const { workspaceName = routeParams.workspaceName, documentId = routeParams.documentId } =
		args ?? {};

	const { mutateAsync: updateCacheStatus } = useGitRepoStatusCacheMutation();

	/*
	 * Only fetch the status if the document is in the local workspace (aka on disk)
	 */
	const enabled = useMemo(
		() => workspaceName === LOCAL_WORKSPACE_NAME && !isEmpty(documentId),
		[workspaceName, documentId]
	);

	const query = useQuery<GitRepoStatus | undefined>({
		queryKey: gitQueryKeys.repo.status.cache(documentId),

		queryFn: async () => {
			const db = await getDb();

			const query = sql`
				SELECT * FROM documents_status
				WHERE documentId = ${documentId}
				LIMIT 1
			`;

			const result = await db?.select<GitRepoStatus[]>(query.sql, query.values);

			if (!result || result.length === 0) {
				return undefined;
			}

			return first(result);
		},

		/*
		 * We only want to fetch the status if the document is in the local workspace (aka on disk)
		 */
		enabled,
	});

	/*
	 * Fetch the status from git on disk and update the cache
	 */
	useQuery({
		queryKey: gitQueryKeys.repo.status.disk(documentId),

		queryFn: async () => {
			const dir = await getDocumentDirPath(documentId);

			try {
				const rawStatus: RustGitRepoStatus = await invoke("status", { path: dir });

				const status: GitRepoStatus = {
					isRepo: rawStatus.is_repo,
					isClean: rawStatus.is_clean,
					ahead: rawStatus.ahead,
					behind: rawStatus.behind,
					branch: rawStatus.branch,
					commit: rawStatus.commit,
					staged: rawStatus.staged,
					files: rawStatus.files.map((file) => ({
						from: file.from,
						path: file.path || "",
						index: file.index || "",
						working_dir: file.working_dir || "",
					})),
				};

				void updateCacheStatus({
					documentId,
					status,
				});

				return status;
			} catch (e) {
				console.error("Failed to fetch git status", e);
				return Promise.reject(e);
			}
		},

		/*
		 * Re-fetch every 5 minutes
		 */
		staleTime: 1000 * 60 * 5,

		enabled,
	});

	return query;
};
