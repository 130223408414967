import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useLogger } from "~/components/canvas/hooks/useLogger";

import { documentQueryKeys, useUpdateDocumentMutation } from ".";
import { workspaceQueryKeys } from "../workspaces";

export const useRenameDocumentMutation = () => {
	const logger = useLogger("useRenameDocumentMutation");

	const queryClient = useQueryClient();

	const { updateAsync: updateDocument } = useUpdateDocumentMutation();

	return useMutation({
		mutationKey: documentQueryKeys.rename(),
		mutationFn: async (args: { workspaceName: string; documentId: string; title: string }) => {
			const { workspaceName, documentId, title } = args;

			return await Promise.all([
				// Update the document
				updateDocument({ workspaceName, documentId }, (document) => {
					if (!document) {
						logger.error("Document not found");
						return;
					}

					document.title = title;

					return document;
				}),
			]);
		},
		async onSuccess(_data, variables) {
			await Promise.all([
				queryClient.invalidateQueries({
					queryKey: workspaceQueryKeys.list.all(),
				}),

				queryClient.invalidateQueries({
					queryKey: documentQueryKeys.document.content(
						variables.workspaceName,
						variables.documentId
					),
				}),
			]);
		},
	});
};
