import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useAuth } from "~/auth/useAuth";
import type { User } from "~/models/user";
import { createDocsApiFetch } from "~/utils/docsapi";

import { ALL_USERS, type DocPermRequest, type Email, isEmail, permissionsQueryKeys } from ".";
import { useRouteParams } from "../route";

const baseShareMutation = (type: "grant" | "revoke") => () => {
	const queryClient = useQueryClient();

	const { getAccessTokenSilently, isAuthenticated } = useAuth();
	const router = useRouteParams();

	return useMutation({
		mutationKey: permissionsQueryKeys.share(),
		mutationFn: async ({
			workspaceName,
			documentId,
			user,
		}: {
			workspaceName: string;
			documentId: string;
			user: User | Email | typeof ALL_USERS;
		}) => {
			const token = await getAccessTokenSilently({
				promptForLogin: true,
				redirectPath: router.path,
			});
			if (!isAuthenticated || !token) {
				return;
			}

			// if granting public permissions, we use a special query parameter to avoid errors
			const allUsersFlag = user === ALL_USERS ? `?all=true` : "";

			let body: DocPermRequest;
			if (user === ALL_USERS) {
				body = {
					userType: "AllUsers",
					relation: "reader",
					value: "",
				};
			} else if (isEmail(user)) {
				body = {
					userType: "Email",
					relation: "reader",
					value: user.value,
				};
			} else {
				body = {
					userType: "Username",
					relation: "reader",
					value: user.giteaUserId,
				};
			}

			const client = createDocsApiFetch(token);
			return await client(`/permissions/${workspaceName}/${documentId}${allUsersFlag}`, {
				method: type === "grant" ? "POST" : "DELETE",
				body: JSON.stringify(body),
			});
		},

		// Authzed seems to typically need ~15 seconds to reflect the change in new reads.
		// Rather than slow down the whole system, we can assume that the success response
		// means that it will eventually be correct and immediately update in memory.
		// After a generous 30 seconds, trigger the query invalidation to be sure.

		async onSuccess(_data, variables) {
			queryClient.setQueryData(
				permissionsQueryKeys.isPublished(variables.workspaceName, variables.documentId),
				type === "grant"
			);

			setTimeout(() => {
				void queryClient.invalidateQueries({
					queryKey: permissionsQueryKeys.isPublished(
						variables.workspaceName,
						variables.documentId
					),
				});
			}, 30000);
		},
	});
};

export const useShareMutation = baseShareMutation("grant");
export const useRevokeShareMutation = baseShareMutation("revoke");
