import { type AppState, Auth0Provider, type User } from "@auth0/auth0-react";
import { useQueryClient } from "@tanstack/react-query";
import { useRouter } from "next/router";
import { type FC, type PropsWithChildren, useCallback } from "react";

import { documentQueryKeys } from "~/data/documents";
import { workspaceQueryKeys } from "~/data/workspaces";

import { getAuthConfigs } from "./authconfig";
import { getAuthURLs } from "./interop/common";
import { onLoginSuccess } from "./loginAction";

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
	const queryClient = useQueryClient();
	const router = useRouter();

	const authConfig = getAuthConfigs(process.env.AUTH_ENV);
	const { loginRedirectUri } = getAuthURLs(process.env.APP_ENV);

	const onRedirectCallback = useCallback(
		(appState?: AppState, _user?: User) => {
			if (appState) {
				const returnTo = onLoginSuccess(appState["redirectId"]);
				if (returnTo) {
					void router.replace({ query: {}, pathname: returnTo }, undefined, {
						shallow: true,
					});
				}
			}

			void Promise.all([
				queryClient.invalidateQueries({
					queryKey: workspaceQueryKeys.list.all(),
				}),
				queryClient.invalidateQueries({
					queryKey: documentQueryKeys.list.all(),
				}),
			]);
		},
		[queryClient, router]
	);

	return (
		<Auth0Provider
			domain={authConfig.auth0Domain}
			clientId={authConfig.auth0ClientId}
			authorizationParams={{ redirect_uri: loginRedirectUri, audience: authConfig.audience }}
			onRedirectCallback={onRedirectCallback}
			cacheLocation={"localstorage"}
			useRefreshTokens
		>
			{children}
		</Auth0Provider>
	);
};
