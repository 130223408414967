import { useMutation, useQueryClient } from "@tanstack/react-query";
import { invoke } from "@tauri-apps/api/core";

import { gitQueryKeys } from ".";
import { getDocumentDirPath } from "../filesystem";

export const useUpdateGitRepoIndexMutation = () => {
	const queryClient = useQueryClient();

	const mutation = useMutation({
		mutationFn: async (args: {
			workspaceName: string;
			documentId: string;
			paths: string[];
			operation: "add" | "reset";
		}) => {
			const { documentId, paths, operation } = args;

			const path = await getDocumentDirPath(documentId);

			await invoke("stage", {
				path,
				files: paths,
				operation,
			});
		},
		onSettled: async (data, error, variables) => {
			console.log("stage completed", data, error, variables);
		},
		onSuccess: async () => {
			await queryClient.invalidateQueries({ queryKey: [...gitQueryKeys.all()] });
		},
	});

	return mutation;
};
