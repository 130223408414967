import { useMutation, useQueryClient } from "@tanstack/react-query";
import { invoke } from "@tauri-apps/api/core";

import { useAuth } from "~/auth/useAuth";

import { gitQueryKeys } from ".";
import { useRevalidateDocumentMutation } from "../documents";
import { getDocumentDirPath } from "../filesystem";

/**
 * Pushes a document to a remote repository and revalidates the cache on Vercel's CDN.
 */
export const usePushRepoMutation = () => {
	const { getAccessTokenSilently, user } = useAuth();
	const queryClient = useQueryClient();

	const { mutateAsync: revalidateDocument } = useRevalidateDocumentMutation();

	const mutation = useMutation({
		mutationFn: async (args: { documentId: string; remote: string; localBranch: string }) => {
			const { documentId, localBranch, remote } = args;
			const documentPath = await getDocumentDirPath(documentId);

			const token = await getAccessTokenSilently();

			return await invoke("push", {
				path: documentPath,
				remote,
				branch: localBranch,
				token,
			});
		},

		onSuccess: async (_, { documentId }) => {
			await queryClient.invalidateQueries({ queryKey: gitQueryKeys.all() });

			const workspaceName = user?.nickname;

			if (workspaceName) {
				await revalidateDocument({
					workspaceName,
					documentId,
				}).catch((error) => {
					// If this fails, nothing necessarily breaks. The CDN just won't update immediately.
					// Also this is temporary.

					console.error(error);
				});
			}
		},
	});

	return mutation;
};
