import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useRouter } from "next/router";

import { useSyncDocument } from "~/components/canvas/properties-pane/publish-tab/usePublish";
import { getDocumentDirPath, remove } from "~/data/filesystem";

import { type DocumentListItem, documentQueryKeys, useDeleteDocumentCacheMutation } from ".";
import { useDeleteRepoMutation } from "../gitea";

export const useDeleteDocumentMutation = () => {
	const queryClient = useQueryClient();
	const router = useRouter();

	const { mutateAsync: deleteDocumentCache } = useDeleteDocumentCacheMutation();

	const { mutateAsync: deleteRepo } = useDeleteRepoMutation();
	const { syncStatus, unpublish } = useSyncDocument({ mode: "publish-if-unpublished" });

	const mutation = useMutation({
		mutationKey: documentQueryKeys.delete(),
		mutationFn: async (args: { documentId: string }) => {
			const localDocuments = queryClient.getQueryData<DocumentListItem[]>(
				documentQueryKeys.list.cache()
			);

			const document = localDocuments?.find((doc) => doc.id === args.documentId);
			const documentWorkspaces = document?.workspaces;

			/*
			 * Delete the repo on Gitea for all workspaces it exists in
			 */
			await Promise.allSettled(
				documentWorkspaces?.map(async (workspace) => {
					try {
						if (syncStatus === "published") {
							await unpublish();
						}
					} catch (error) {
						// Totally advisory, this is ok to fail.
					}

					try {
						return await deleteRepo({
							workspaceName: workspace.name,
							documentId: args.documentId,
						});
					} catch (error) {
						console.error("Failed to delete repo from Gitea", {
							workspaceName: workspace.name,
							documentId: args.documentId,
							error,
						});
					}
				}) ?? []
			);

			/*
			 * Remove the document from the local filesystem
			 */
			await remove(await getDocumentDirPath(args.documentId)).catch((error) => {
				console.error("Failed to remove document from local filesystem", {
					documentId: args.documentId,
					error,
				});
			});

			/*
			 * Remove the document from the cache database and update the cache
			 */
			await deleteDocumentCache({ id: args.documentId }).catch((error) => {
				console.error("Failed to delete document from cache database", {
					documentId: args.documentId,
					error,
				});
			});
		},
		async onSuccess() {
			await Promise.allSettled([
				/*
				 * Redirect to the document list
				 */
				router.push("/docs"),
			]);
		},
	});

	return mutation;
};
