/* eslint-disable react/jsx-max-depth */
import { Analytics as VercelAnalytics } from "@vercel/analytics/react";
import type { AppProps } from "next/app";
import type { FC, PropsWithChildren } from "react";
import { Provider as ReduxProvider } from "react-redux";
import SuperJSON from "superjson";

import { ThemeProvider } from "@moment/design-system/ThemeProvider";

import { AuthProvider } from "~/auth/AuthProvider";
import { isDesktopAppMode } from "~/auth/interop/common";
import { TrackPageView } from "~/components/analytics/TrackPageView";
import { ConfirmModalWrapper } from "~/components/common/confirm-modal/ConfirmModalContext";
import { TopBarProvider } from "~/components/topbar/TopBarContext";
import { store } from "~/store/store";

import { PropertiesPaneProvider } from "./components/canvas/easel/properties-pane/PropertiesPaneProvider";
import { ConfigProvider } from "./components/config/ConfigProvider";
import { OutdatedAppModalWrapper } from "./components/outdated-app/OutdatedAppModalContext";
import { QueryProvider } from "./data/QueryProvider";

export const Providers: FC<
	PropsWithChildren<{
		pageProps?: AppProps["pageProps"];
	}>
> = ({ children, pageProps }) => {
	return (
		<ReduxProvider store={store}>
			<QueryProvider
				dehydratedState={
					pageProps?.dehydratedState
						? SuperJSON.parse(pageProps.dehydratedState)
						: undefined
				}
			>
				<AuthProvider>
					{!isDesktopAppMode() && <VercelAnalytics />}
					<TrackPageView />
					{/* ThemeProvider must be above PersistGate to render on the server */}
					<ThemeProvider>
						<ConfigProvider>
							<TopBarProvider>
								<PropertiesPaneProvider>
									<ConfirmModalWrapper>
										<OutdatedAppModalWrapper>
											{children}
										</OutdatedAppModalWrapper>
									</ConfirmModalWrapper>
								</PropertiesPaneProvider>
							</TopBarProvider>
						</ConfigProvider>
					</ThemeProvider>
				</AuthProvider>
			</QueryProvider>
		</ReduxProvider>
	);
};
