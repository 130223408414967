import Database from "@tauri-apps/plugin-sql";

import { getDbBaseDir, getMomentDbPath, mkdir } from "~/data/filesystem";

const getDbPath = async () => (typeof window !== "undefined" ? await getMomentDbPath() : undefined);

export const getDb = async (): Promise<Database | undefined> => {
	if (global.db) {
		return global.db;
	}

	const dbPath = await getDbPath();

	if (dbPath) {
		const dbDir = await getDbBaseDir();
		await mkdir(dbDir, { recursive: true });

		const db = await Database.load(`sqlite:${dbPath}`);

		// Set the db in the global object so it can be reused
		global.db = db;

		return db;
	}

	return undefined;
};
