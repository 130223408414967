import { skipToken, useQuery } from "@tanstack/react-query";
import { isEmpty } from "lodash";
import YAML from "yaml";

import { type DocumentMetadata, DocumentMetadataSchema } from "@moment/api-collab/api-types";

import { useAuth } from "~/auth/useAuth";
import { getDocumentFilePath, readYamlFile } from "~/data/filesystem";
import { useRouteParams } from "~/data/route";
import { getGiteaApiUrl } from "~/utils/gitea";

import { documentQueryKeys } from ".";
import { LOCAL_WORKSPACE_NAME } from "../workspaces";

export class DocumentNotFoundError extends Error {
	constructor(workspaceName: string, documentId: string) {
		super(`Document with ID ${documentId} not found in workspace ${workspaceName}`);
		this.name = "DocumentNotFoundError";
	}
}

export const queryDocument = async (
	workspaceName: string,
	documentId: string,
	authToken?: string
): Promise<DocumentMetadata> => {
	const headers = new Headers();
	headers.set("Accept", "application/json");

	if (authToken) {
		headers.set("Authorization", `Bearer ${authToken}`);
	}

	const response = await fetch(
		`${getGiteaApiUrl()}/api/v1/repos/${workspaceName}/${documentId}/raw/moment.yml?ref=main`,
		{
			headers,
		}
	);

	if (response.status === 404) {
		console.error("Failed to fetch public document metadata", {
			workspaceName,
			documentId,
			response,
		});

		throw new DocumentNotFoundError(workspaceName, documentId);
	} else if (!response.ok) {
		console.error("Failed to fetch public document metadata", {
			workspaceName,
			documentId,
			response,
		});
		throw new Error("Failed to fetch public document metadata");
	}
	const text = await response.text();
	const metadata = YAML.parse(text);

	return DocumentMetadataSchema.parse(metadata);
};

const getDocumentMetadata = async (documentId: string) => {
	try {
		return await readYamlFile<DocumentMetadata>(await getDocumentFilePath(documentId));
	} catch (error) {
		return undefined;
	}
};

export const useDocumentQuery = (args?: {
	workspaceName: string;
	documentId: string;
	retry?: boolean | number;
}) => {
	const routeParams = useRouteParams();
	const { getAccessTokenSilently } = useAuth();

	const {
		workspaceName = routeParams.workspaceName,
		documentId = routeParams.documentId,
		retry = false,
	} = args ?? {};

	const query = useQuery<DocumentMetadata>({
		queryKey: documentQueryKeys.document.content(workspaceName, documentId),

		/*
		 * If the workspace name or document ID is not set, we skip the query. This happens regularly when Next.js
		 * is still working out all the route params.
		 */
		queryFn:
			isEmpty(workspaceName) || isEmpty(documentId)
				? skipToken
				: async () => {
						let document: DocumentMetadata | undefined = undefined;

						/*
						 * If the document is in the local workspace, we can read it directly from the file system
						 * Otherwise, we need to fetch it from the server
						 */
						if (workspaceName === LOCAL_WORKSPACE_NAME) {
							document = await getDocumentMetadata(documentId);
						} else {
							// We need the access token if the user is logged in to fetch "private" documents
							const accessToken = await getAccessTokenSilently();

							document = await queryDocument(workspaceName, documentId, accessToken);
						}

						if (isEmpty(document)) {
							throw new Error("Failed to fetch document metadata");
						}

						return DocumentMetadataSchema.parse(document);
					},

		retry,
	});

	return query;
};
