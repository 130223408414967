export { useWorkspaceQuery } from "./useWorkspaceQuery";
export { useWorkspaceListQuery } from "./useWorkspaceListQuery";
export { useUpsertWorkspaceCacheMutation } from "./useUpsertWorkspaceCacheMutation";

export * from "./constants";
export * from "./types";

export const workspaceQueryKeys = {
	all: () => ["workspaces"],

	list: {
		all: () => [...workspaceQueryKeys.all(), "list"],

		cache: () => [...workspaceQueryKeys.list.all(), "cache"],
		remote: () => [...workspaceQueryKeys.list.all(), "remote"],
	},

	update: () => [...workspaceQueryKeys.all(), "update"],

	cache: {
		base: () => [...workspaceQueryKeys.all(), "cache"],

		upsert: () => [...workspaceQueryKeys.cache.base(), "upsert"],
	},
};
