import { useMutation } from "@tanstack/react-query";
import { invoke } from "@tauri-apps/api/core";

import { useAuth } from "~/auth/useAuth";
import { getDocumentDirPath } from "~/data/filesystem";

import { gitQueryKeys } from ".";

export const useGitFetchMutation = () => {
	const { getAccessTokenSilently } = useAuth();

	const mutation = useMutation({
		mutationKey: gitQueryKeys.clone(),
		mutationFn: async (args: { documentId: string; remote?: string; branch?: string }) => {
			const { documentId, remote = "origin", branch = "main" } = args;

			// TODO(tauri): replace with middleware
			const token = await getAccessTokenSilently();

			const path = await getDocumentDirPath(documentId);

			const fetchResult = await invoke("fetch", { path, remote, branch, token });
			console.log("got fetch result", fetchResult);

			return { path };
		},
		onSuccess: async ({ path }, { documentId }) => {
			console.log("fetch success", path, documentId);
		},
		onError: (error) => {
			console.error("fetch error", { error });
		},
	});

	return mutation;
};
