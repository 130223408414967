export { useCanPublishQuery } from "./useCanPublishQuery";
export { useCanWriteQuery } from "./useCanWriteQuery";
export { useIsPublishedQuery } from "./useIsPublishedQuery";
export { useShareMutation, useRevokeShareMutation } from "./useShareMutation";

export const ALL_USERS = "ALL_USERS";
export interface Email {
	type: "Email";
	value: string;
}

export function isEmail(o: unknown): o is Email {
	return !!o && typeof o === "object" && "type" in o && o.type === "Email";
}

export interface DocPermRequest {
	userType: "AllUsers" | "Username" | "Email";
	relation: "reader";
	value: string;
}

export const permissionsQueryKeys = {
	base: ["permissions"],
	canPublish: (workspaceName: string, documentId: string) => [
		"permissions",
		workspaceName,
		documentId,
		"canPublish",
	],
	canWrite: (workspaceName: string, documentId: string) => [workspaceName, documentId, "write"],
	isPublished: (workspaceName: string, documentId: string) => [
		"permissions",
		workspaceName,
		documentId,
		"isPublished",
	],
	share: () => [...permissionsQueryKeys.base, "share"],
};
