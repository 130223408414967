import { useMutation, useQueryClient } from "@tanstack/react-query";
import { invoke } from "@tauri-apps/api/core";

import { type ConfigValues, gitQueryKeys } from ".";
import { getMomentGitConfigPath } from "../filesystem";

export const useGitSetMomentConfigKeyMutation = () => {
	const queryClient = useQueryClient();

	const mutation = useMutation({
		mutationKey: gitQueryKeys.config.moment.set(),
		mutationFn: async (args: { documentId: string; newConfig: ConfigValues }) => {
			const { newConfig } = args;

			const path = await getMomentGitConfigPath();

			const fetchResult = await invoke("set_config", { path, newConfig });
			console.log("set config result", newConfig, fetchResult);

			return { path };
		},
		onSuccess: async ({ path }, { documentId }) => {
			console.log("set config success", path, documentId);
			await queryClient.invalidateQueries({ queryKey: gitQueryKeys.config.all() });
		},
		onError: (error) => {
			console.error("set config error", { error });
		},
	});

	return mutation;
};
