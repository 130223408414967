import { useMutation, useQueryClient } from "@tanstack/react-query";
import sql, { type Sql } from "sql-template-tag";
import { useDebouncedCallback } from "use-debounce";

import { documentQueryKeys } from "~/data/documents";

import { getDb } from "../sql";

export const useUpsertDocumentWorkspacesCacheMutation = () => {
	const queryClient = useQueryClient();

	/*
	 * Invalidate the cached documents list
	 *
	 * This query will likely be called multiple times in a row, so let's debounce the invalidation
	 */
	const invalidateCache = useDebouncedCallback(async () => {
		void queryClient.invalidateQueries({ queryKey: documentQueryKeys.list.cache() });
	}, 100);

	return useMutation({
		mutationKey: documentQueryKeys.cache.upsert(),
		mutationFn: async ({
			documentId,
			workspaceId,
			action,
		}: {
			documentId: string;
			workspaceId: string;
			action: "add" | "remove";
		}) => {
			const db = await getDb();

			let query: Sql;
			switch (action) {
				case "add":
					query = sql`
						INSERT INTO documents_workspaces (documentId, workspaceId) VALUES (${documentId}, ${workspaceId})
						ON CONFLICT (documentId, workspaceId) DO NOTHING
					`;
					break;
				case "remove":
					query = sql`
						DELETE FROM documents_workspaces WHERE documentId = ${documentId} AND workspaceId = ${workspaceId}
					`;
					break;
				default:
					throw new Error(`Invalid action: ${action}`);
			}

			await db?.execute(query.sql, query.values);

			return;
		},
		onSuccess: () => {
			void invalidateCache();
		},
	});
};
