import { useMutation, useQueryClient } from "@tanstack/react-query";
import { produce } from "immer";

import { type DocumentMetadata, DocumentMetadataSchema } from "@moment/api-collab/api-types";

import { useLogger } from "~/components/canvas/hooks/useLogger";
import { exists, getDocumentFilePath, readYamlFile, writeYamlFile } from "~/data/filesystem";

import { documentQueryKeys, useUpsertDocumentCacheMutation } from ".";
import { gitQueryKeys } from "../git";

export const useUpdateDocumentMutation = () => {
	const logger = useLogger("useUpdateDocumentMutation");
	const queryClient = useQueryClient();

	const { mutateAsync: updateDocumentCache } = useUpsertDocumentCacheMutation();

	const mutation = useMutation({
		mutationKey: documentQueryKeys.update(),
		mutationFn: async (args: { documentId: string; document: DocumentMetadata }) => {
			const { documentId, document } = args;
			logger.debug("updating document", { documentId, document });

			// Write document to disk
			await writeYamlFile(await getDocumentFilePath(documentId), document);
			logger.debug("document written to disk", {
				documentId,
				document,
			});

			return document;
		},
		onSuccess: async (document, { documentId }) => {
			await updateDocumentCache({
				id: documentId,
				title: document.title,
				createdAt: document.created,
				updatedAt: new Date(),
			});

			void queryClient.invalidateQueries({ queryKey: gitQueryKeys.all() });
		},
	});

	return {
		...mutation,
		updateAsync: async (
			args: { workspaceName: string; documentId: string },
			updaterFn: (document: DocumentMetadata | undefined) => DocumentMetadata | undefined
		) => {
			const documentFilePath = await getDocumentFilePath(args.documentId);

			const documentFileExists = await exists(documentFilePath);
			let document: DocumentMetadata | undefined;

			if (documentFileExists) {
				const documentFile = await readYamlFile<DocumentMetadata>(documentFilePath);
				const { success, data, error } = DocumentMetadataSchema.safeParse(documentFile);

				if (!success) {
					logger.error("Failed to parse document file", {
						args,
						error,
					});
					return;
				}

				document = data;
			} else {
				document = undefined;
			}

			document = queryClient.setQueryData(
				documentQueryKeys.document.content(args.workspaceName, args.documentId),
				() => produce(document, updaterFn)
			);

			if (!document) {
				logger.error("Document not found");
				return;
			}

			return mutation.mutateAsync({
				...args,
				document,
			});
		},
	};
};
