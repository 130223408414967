import { useMutation, useQueryClient } from "@tanstack/react-query";

import { type DocumentMetadata } from "@moment/api-collab/api-types";
import { generateShortUuid } from "@moment/api-collab/id";
import { makeToast } from "@moment/design-system/Toast";

import { useLogger } from "~/components/canvas/hooks/useLogger";
import { useNavigateToCanvas } from "~/components/navigation/hooks";
import { getDocumentDirPath, mkdir } from "~/data/filesystem";

import { documentQueryKeys, useUpdateDocumentMutation, useUpsertDocumentCacheMutation } from ".";
import { gitQueryKeys } from "../git";
import { useInitGitRepoMutation } from "../git/useInitGitRepo";
import { useCreatePageMutation } from "../pages";
import { LOCAL_WORKSPACE_NAME } from "../workspaces";

export const useCreateDocumentMutation = () => {
	const logger = useLogger("useCreateDocumentMutation");
	const queryClient = useQueryClient();

	const { mutateAsync: initRepo } = useInitGitRepoMutation();
	const { updateAsync: updateDocument } = useUpdateDocumentMutation();
	const { mutateAsync: upsertDocumentCache } = useUpsertDocumentCacheMutation();
	const { mutateAsync: createPage } = useCreatePageMutation();

	const navigateToDocument = useNavigateToCanvas();

	const mutation = useMutation({
		mutationKey: documentQueryKeys.create(),
		mutationFn: async () => {
			const documentId = generateShortUuid();
			const now = new Date();

			const documentPath = await getDocumentDirPath(documentId);

			const document: DocumentMetadata = {
				id: documentId,
				title: "",
				created: now,
				pagesMetadata: [], // Leaving pages array empty to start, we will add a stub page later in this flow
				schemaVersion: 1,
			};

			// Create the directory for the document
			await mkdir(documentPath);

			// Write the document to disk (and cache). This must happen after the workspace is updated.
			await updateDocument(
				{ workspaceName: LOCAL_WORKSPACE_NAME, documentId },
				() => document
			).catch((error) => {
				logger.error("Failed to update doc", {
					error,
					documentId,
					worksapce: LOCAL_WORKSPACE_NAME,
				});
			});

			// Add a page to the document so it will load
			await createPage({ documentId }).catch((error) => {
				logger.error("Failed to create page", { error });
			});

			// Initialize the git repo on disk
			await initRepo({ documentPath }).catch((error) => {
				logger.error("Failed to initialize git repo", { error });
			});

			// Update the cache
			await upsertDocumentCache({
				id: documentId,
				path: documentPath,
				title: "",
				createdAt: now,
				updatedAt: now,
			}).catch((error) => {
				logger.error("Failed to upsert document cache", { error });
			});

			return document;
		},
		async onSuccess(document) {
			void queryClient.invalidateQueries({ queryKey: gitQueryKeys.all() });

			navigateToDocument({
				type: "CanvasById",
				id: document.id,
				workspaceName: LOCAL_WORKSPACE_NAME,
			});
		},
		async onError(error) {
			logger.error("Failed to create document", { error });
			makeToast({
				message: `Failed to create document${error.message ? `: ${error.message}` : ""}`,
				variant: "error",
			});
		},
	});

	return mutation;
};
